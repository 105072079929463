//* MODULES
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { LayoutModule } from "@angular/cdk/layout";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
} from "@angular/fire/compat/analytics";
import { ScullyLibModule } from "@scullyio/ng-lib";
//* LOCAL MODULES
import { FingazRouting } from "./fingaz.r";
import { Components } from "./components/components";
//* NGXS STORE
import { NgxsModule } from "@ngxs/store";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { FingazState } from "@store/fingaz.state";
//* ENV
import { environment } from "../environments/environment";
//* COMPONENTS
import { Fingaz } from "./fingaz.c";
//* ANALYTICS

@NgModule({
  declarations: [Fingaz],
  imports: [
    BrowserModule.withServerTransition({
      appId: "serverApp",
    }),
    BrowserAnimationsModule,
    LayoutModule,
    NgxsModule.forRoot([FingazState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: ["fingazstate"],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: "FINGAZ",
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    FingazRouting,
    Components,
    ScullyLibModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
    }),
  ],
  providers: [ScreenTrackingService],
  bootstrap: [Fingaz],
})
export class FingazModule {}
