<div class="container-xl">
  <div class="csw mt-4 p-4 mx-auto">
    <h1 class="">Terms of Service</h1>
    <p>
      <strong
        >Please read these Terms of Service (“Terms”)
        carefully as they contain important information
        about your legal rights, remedies and obligations.
        By accessing or using the Fingaz Platform, you agree
        to comply with and be bound by these Terms.</strong
      >
    </p>
    <p>
      <strong
        >Please note:
        <a routerLink="./" [fragment]="'sec201910_19'"
          >Section 19</a
        >
        of these Terms contains an arbitration clause and
        class action waiver that applies to all Fingaz
        Members. If your country of residence is the United
        States, this provision applies to all disputes with
        Fingaz. If your country of residence is outside of
        the United States, this provision applies to any
        action you bring against Fingaz in the United
        States. It affects how disputes with Fingaz are
        resolved. By accepting these Terms, you agree to be
        bound by this arbitration clause and class action
        waiver. Please read it carefully.</strong
      >
    </p>
    <p>Last Updated: January 1, 2022</p>
    <p>Thank you for using Fingaz!</p>
    <p>
      These Terms constitute a legally binding agreement
      ("<strong>Agreement</strong>") between you and Fingaz
      (as defined below) governing your access to and use of
      the Fingaz website, including any subdomains thereof,
      and any other websites through which Fingaz makes its
      services available (collectively,
      "<strong>Site</strong>"), our mobile, tablet and other
      smart device applications, and application program
      interfaces (collectively,
      "<strong>Application</strong>") and all associated
      services (collectively, "<strong
        >Fingaz Services</strong
      >"). The Site, Application and Fingaz Services
      together are hereinafter collectively referred to as
      the “<strong>Fingaz Platform</strong>”. Our
      <a routerLink="">Host Guarantee Terms</a>,
      <a routerLink="">Japan Host Insurance Terms</a>,
      <a routerLink="">Guest Refund Policy</a>,
      <a routerLink="">Nondiscrimination Policy</a> and
      other <a routerLink="">Policies</a> applicable to your
      use of the Fingaz Platform are incorporated by
      reference into this Agreement.
    </p>
    <p>
      When these Terms mention “<strong>Fingaz</strong>,”
      “<strong>we</strong>,” “<strong>us</strong>,” or
      “<strong>our</strong>,” it refers to the Fingaz
      company you are contracting with. Your contracting
      entity will generally be determined based on your
      country of residence or establishment.
    </p>
    <ul>
      <li>
        If your country of residence or establishment is the
        United States, you are contracting with Fingaz,
        Inc., 888 Brannan Street, 4th Floor, San Francisco,
        CA 94103, United States.
      </li>
      <li>
        If your country of residence or establishment is
        outside of the United States, the People’s Republic
        of China (which for purposes of these Terms does not
        include Hong Kong, Macau and Taiwan) (hereinafter
        “<strong>China</strong>”), Japan and the European
        Economic Area, you are contracting with Fingaz
        Ireland UC (“<strong>Fingaz Ireland</strong>”), The
        Watermarque Building, South Lotts Road, Ringsend,
        Dublin 4, Ireland.
      </li>
      <li>
        If your country of residence or establishment is in
        the European Economic Area or the United Kingdom,
        you are contracting with Fingaz Ireland UC (“<strong
          >Fingaz Ireland</strong
        >”), The Watermarque Building, South Lotts Road,
        Ringsend, Dublin 4, Ireland and your Agreement with
        Fingaz is subject to the
        <a routerLink=""
          >Terms of Service for European Users</a
        >.
      </li>
      <li>
        If your country of residence or establishment is
        China, you are contracting with Fingaz Internet
        (Beijing) Co., Ltd. (“<strong>Fingaz China</strong
        >”) except where you book a Host Service (as defined
        below) or when you create a Listing located outside
        of China, in which case you are contracting with
        Fingaz Ireland for that transaction.
      </li>
      <li>
        If your country of residence or establishment is
        Japan, you are contracting with Fingaz Global
        Services Limited ("<strong>Fingaz GSL</strong>"),
        25-28 North Wall Quay, Dublin 1, D01 H104,
        Ireland,<strong> </strong>except where you book a
        Host Service (as defined below) or when you create a
        Listing located outside of Japan, in which case you
        are contracting with Fingaz Ireland for that
        transaction.
      </li>
    </ul>
    <p>
      If you change your country of residence or
      establishment, the Fingaz company you contract with
      will be determined by your new country of residence or
      establishment as specified above, from the date on
      which your country of residence changes.
    </p>
    <p>
      Our collection and use of personal information in
      connection with your access to and use of the Fingaz
      Platform is described in our
      <a routerLink="">Privacy Policy</a>.
    </p>
    <p>
      Any and all payment processing services through or in
      connection with your use of the Fingaz Platform
      ("<strong>Payment Services</strong>") are provided to
      you by one or more Fingaz Payments entities
      (individually and collectively, as appropriate,
      "<strong>Fingaz Payments</strong>") as set out in the
      <a routerLink="">Payments Terms of Service</a>
      ("<strong>Payments Terms</strong>").
    </p>
    <p>
      Hosts alone are responsible for identifying,
      understanding, and complying with all laws, rules and
      regulations that apply to their Listings and Host
      Services (as defined below). For example, some cities
      have laws that restrict their ability to host paying
      guests for short periods or provide certain Host
      Services. In many cities, Hosts may have to register,
      get a permit or obtain a license before providing
      certain Host Services (such as preparing food, serving
      alcohol for sale, guiding tours or operating a
      vehicle). Host are alone responsible for identifying
      and obtaining any required licenses, permits, or
      registrations for any Host Services they offer.
      Certain types of Host Services may be prohibited
      altogether. Penalties may include fines or other
      enforcement. We provide some information in our
      <a routerLink="">Help Center</a> to help you identify
      some of the obligations that apply to you. If you have
      questions about how local laws apply to your
      Listing(s) and Host Service(s) on Fingaz, you should
      always seek legal guidance.
    </p>
    <h4><a id="sec201910_0"></a>Table of Contents</h4>
    <ol>
      <li>
        <a routerLink="./" [fragment]="'sec201910_1'"
          >Scope of Fingaz Services</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_2'"
          >Eligibility, Using the Fingaz Platform, Member
          Verification</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_3'"
          >Modification of these Terms</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_4'"
          >Account Registration</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_5'"
          >Content</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_6'"
          >Service Fees</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_7'"
          >Terms specific for Hosts</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_8'"
          >Terms specific for Guests</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_9'"
          >Booking Modifications, Cancellations and Refunds,
          Resolution Center</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_10'"
          >Ratings and Reviews</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_11'"
          >Damage to Accommodations, Disputes between
          Members</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_12'"
          >Rounding off, Currency conversion</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_13'"
          >Taxes</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_14'"
          >Prohibited Activities</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_15'"
          >Term and Termination, Suspension and other
          Measures</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_16'"
          >Disclaimers</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_17'"
          >Liability</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_18'"
          >Indemnification</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_19'"
          >Dispute Resolution</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_20'"
          >Feedback</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_21'"
          >Applicable Law and Jurisdiction</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_22'"
          >General Provisions</a
        >
      </li>
    </ol>
    <h4>
      <a id="sec201910_1"></a>1. Scope of Fingaz Services
    </h4>
    <p>
      1.1 The Fingaz Platform is an online marketplace that
      enables registered users (“<strong>Members</strong>”)
      and certain third parties who offer services (Members
      and third parties who offer services are
      “<strong>Hosts</strong>” and the services they offer
      are “<strong>Host Services</strong>”) to publish such
      Host Services on the Fingaz Platform
      (“<strong>Listings</strong>”) and to communicate and
      transact directly with Members that are seeking to
      book such Host Services (Members using Host Services
      are “<strong>Guests</strong>”). Host Services may
      include the offering of vacation or other properties
      for use ("<strong>Accommodations</strong>"), single or
      multi-day activities in various categories
      (“<strong>Experiences</strong>”), access to unique
      events and locations (“<strong>Events</strong>”), and
      a variety of other travel and non-travel related
      services.
    </p>
    <p>
      1.2 As the provider of the Fingaz Platform, Fingaz
      does not own, create, sell, resell, provide, control,
      manage, offer, deliver, or supply any Listings or Host
      Services, nor is Fingaz an organiser or retailer of
      travel packages under Directive (EU) 2015/2302. Hosts
      alone are responsible for their Listings and Host
      Services. When Members make or accept a booking, they
      are entering into a contract directly with each other.
      Fingaz is not and does not become a party to or other
      participant in any contractual relationship between
      Members, nor is Fingaz a real estate broker or
      insurer. Fingaz is not acting as an agent in any
      capacity for any Member, except as specified in the
      <a routerLink="">Payments Terms</a>.
    </p>
    <p>
      1.3 While we may help facilitate the resolution of
      disputes, Fingaz has no control over and does not
      guarantee (i) the existence, quality, safety,
      suitability, or legality of any Listings or Host
      Services, (ii) the truth or accuracy of any Listing
      descriptions, Ratings, Reviews, or other Member
      Content (as defined below), or (iii) the performance
      or conduct of any Member or third party. Fingaz does
      not endorse any Member, Listing or Host Services. Any
      references to a Member being "verified" (or similar
      language) only indicate that the Member has completed
      a relevant verification or identification process and
      nothing else. Any such description is not an
      endorsement, certification or guarantee by Fingaz
      about any Member, including of the Member's identity
      or background or whether the Member is trustworthy,
      safe or suitable. You should always exercise due
      diligence and care when deciding whether to stay in an
      Accommodation, participate in an Experience or Event
      or use other Host Services, accept a booking request
      from a Guest, or communicate and interact with other
      Members, whether online or in person. Verified Images
      (as defined below) are intended only to indicate a
      photographic representation of a Listing at the time
      the photograph was taken, and are therefore not an
      endorsement by Fingaz of any Host or Listing.
    </p>
    <p>
      1.4 If you choose to use the Fingaz Platform as a Host
      or Co-Host (as defined below), your relationship with
      Fingaz is limited to being an independent, third-party
      contractor, and not an employee, agent, joint venturer
      or partner of Fingaz for any reason, and you act
      exclusively on your own behalf and for your own
      benefit, and not on behalf, or for the benefit, of
      Fingaz. Fingaz does not, and shall not be deemed to,
      direct or control you generally or in your performance
      under these Terms specifically, including in
      connection with your provision of the Host Services.
      You acknowledge and agree that you have complete
      discretion whether to list Host Services or otherwise
      engage in other business or employment activities.
    </p>
    <p>
      1.5 To promote the Fingaz Platform and to increase the
      exposure of Listings to potential Guests, Listings and
      other Member Content may be displayed on other
      websites, in applications, within emails, and in
      online and offline advertisements. To assist Members
      who speak different languages, Listings and other
      Member Content may be translated, in whole or in part,
      into other languages. Fingaz cannot guarantee the
      accuracy or quality of such translations and Members
      are responsible for reviewing and verifying the
      accuracy of such translations. The Fingaz Platform may
      contain translations powered by Google. Google
      disclaims all warranties related to the translations,
      express or implied, including any warranties of
      accuracy, reliability, and any implied warranties for
      merchantability, fitness for a particular purpose and
      non-infringement.
    </p>
    <p>
      1.6 The Fingaz Platform may contain links to
      third-party websites or resources (“<strong
        >Third-Party Services</strong
      >”). Such Third-Party Services may be subject to
      different terms and conditions and privacy practices.
      Fingaz is not responsible or liable for the
      availability or accuracy of such Third-Party Services,
      or the content, products, or services available from
      such Third-Party Services. Links to such Third-Party
      Services are not an endorsement by Fingaz of such
      Third-Party Services.
    </p>
    <p>
      1.7 Due to the nature of the Internet, Fingaz cannot
      guarantee the continuous and uninterrupted
      availability and accessibility of the Fingaz Platform.
      Fingaz may restrict the availability of the Fingaz
      Platform or certain areas or features thereof, if this
      is necessary in view of capacity limits, the security
      or integrity of our servers, or to carry out
      maintenance measures that ensure the proper or
      improved functioning of the Fingaz Platform. Fingaz
      may improve, enhance and modify the Fingaz Platform
      and introduce new Fingaz Services from time to time.
    </p>
    <h4>
      <a id="sec201910_2"></a>2. Eligibility, Using the
      Fingaz Platform, Member Verification
    </h4>
    <p>
      2.1 In order to access and use the Fingaz Platform or
      register an Fingaz Account you must be an individual
      at least 18 years old or a duly organized, validly
      existing business, organization or other legal entity
      in good standing under the laws of the country you are
      established and able to enter into legally binding
      contracts.
    </p>
    <p>
      2.2 You will comply with any applicable export control
      laws in your local jurisdiction. You also represent
      and warrant that (i) neither you nor your Host
      Service(s) are located or take place in a country that
      is subject to a U.S. Government embargo, or that has
      been designated by the U.S. Government as a "terrorist
      supporting" country, and (ii) you are not listed on
      any U.S. Government list of prohibited or restricted
      parties.
    </p>
    <p>
      2.3 Fingaz may make access to and use of the Fingaz
      Platform, or certain areas or features of the Fingaz
      Platform, subject to certain conditions or
      requirements, such as completing a verification
      process, meeting specific quality or eligibility
      criteria, meeting Ratings or Reviews thresholds, or a
      Member’s booking and cancellation history.
    </p>
    <p>
      2.4 User verification on the Internet is difficult and
      we do not assume any responsibility for the
      confirmation of any Member’s identity. Notwithstanding
      the above, for transparency and fraud prevention
      purposes, and as permitted by applicable laws, we may,
      but have no obligation to (i) ask Members to provide a
      form of government identification or other information
      or undertake additional checks designed to help verify
      the identities or backgrounds of Members, (ii) screen
      Members against third party databases or other sources
      and request reports from service providers, and (iii)
      where we have sufficient information to identify a
      Member, obtain reports from public records of criminal
      convictions or sex offender registrations or an
      equivalent version of background or registered sex
      offender checks in your local jurisdiction (if
      available).
    </p>
    <p>
      2.5 The access to or use of certain areas and features
      of the Fingaz Platform may be subject to separate
      policies, standards or guidelines, or may require that
      you accept additional terms and conditions, before you
      can access the relevant areas or features of the
      Fingaz Platform. If there is a conflict between these
      Terms and terms and conditions applicable to a
      specific area or feature of the Fingaz Platform, the
      latter terms and conditions will take precedence with
      respect to your access to or use of that area or
      feature, unless specified otherwise in the latter
      terms and conditions.
    </p>
    <p>
      2.6 If you access or download the Application from the
      Apple App Store, you agree to
      <a
        href="https://www.apple.com/legal/internet-services/itunes/appstore/dev/stdeula/"
        >Apple’s Licensed Application End User License
        Agreement</a
      >. Some areas of the Fingaz Platform implement Google
      Maps/Earth mapping services, including Google Maps
      API(s). Your use of Google Maps/Earth is subject to
      the
      <a href="https://www.google.com/help/terms_maps.html"
        >Google Maps/Google Earth Additional Terms of
        Service</a
      >.
    </p>
    <h4>
      <a id="sec201910_3"></a>3. Modification of these Terms
    </h4>
    <p>
      Fingaz reserves the right to modify these Terms at any
      time in accordance with this provision. If we make
      changes to these Terms, we will post the revised Terms
      on the Fingaz Platform and update the “Last Updated”
      date at the top of these Terms. We will also provide
      you with notice of the modifications by email at least
      thirty (30) days before the date they become
      effective. If you disagree with the revised Terms, you
      may terminate this Agreement with immediate effect. We
      will inform you about your right to terminate the
      Agreement in the notification email. If you do not
      terminate your Agreement before the date the revised
      Terms become effective, your continued access to or
      use of the Fingaz Platform will constitute acceptance
      of the revised Terms.
    </p>
    <h4><a id="sec201910_4"></a>4. Account Registration</h4>
    <p>
      4.1 You must register an account ("<strong
        >Fingaz Account</strong
      >") to access and use certain features of the Fingaz
      Platform, such as publishing or booking a Listing. If
      you are registering an Fingaz Account for a business,
      organization or other legal entity, you represent and
      warrant that you have the authority to legally bind
      that entity and grant us all permissions and licenses
      provided in these Terms.
    </p>
    <p>
      4.2 You can register an Fingaz Account using an email
      address and creating a password, or through your
      account with certain third-party social networking
      services, such as Facebook or Google ("<strong
        >SNS Account</strong
      >"). You have the ability to disable the connection
      between your Fingaz Account and your SNS Account at
      any time, by accessing the "Settings" section of the
      Fingaz Platform.
    </p>
    <p>
      4.3 You must provide accurate, current and complete
      information during the registration process and keep
      your Fingaz Account and public Fingaz Account profile
      page information up-to-date at all times.
    </p>
    <p>
      4.4 You may not register more than one (1) Fingaz
      Account unless Fingaz authorizes you to do so. You may
      not assign or otherwise transfer your Fingaz Account
      to another party.
    </p>
    <p>
      4.5 You are responsible for maintaining the
      confidentiality and security of your Fingaz Account
      credentials and may not disclose your credentials to
      any third party. You must immediately notify Fingaz if
      you know or have any reason to suspect that your
      credentials have been lost, stolen, misappropriated,
      or otherwise compromised or in case of any actual or
      suspected unauthorized use of your Fingaz Account. You
      are liable for any and all activities conducted
      through your Fingaz Account, unless such activities
      are not authorized by you and you are not otherwise
      negligent (such as failing to report the unauthorized
      use or loss of your credentials).
    </p>
    <p>
      4.6 Fingaz may enable features that allow you to
      authorize other Members or certain third parties to
      take certain actions that affect your Fingaz Account.
      For example, we may enable Members to link their
      Fingaz Accounts to businesses and take actions for
      those businesses, we may enable eligible Members or
      certain third parties to book Listings on behalf of
      other Members, or we may enable Hosts to add other
      Members as Co-Hosts (as defined below) to help manage
      their Listings. These features do not require that you
      share your credentials with any other person. No third
      party is authorized by Fingaz to ask for your
      credentials, and you shall not request the credentials
      of another Member.
    </p>
    <h4><a id="sec201910_5"></a>5. Content</h4>
    <p>
      5.1 Fingaz may, at its sole discretion, enable Members
      to (i) create, upload, post, send, receive and store
      content, such as text, photos, audio, video, or other
      materials and information on or through the Fingaz
      Platform ("<strong>Member Content</strong>"); and (ii)
      access and view Member Content and any content that
      Fingaz itself makes available on or through the Fingaz
      Platform, including proprietary Fingaz content and any
      content licensed or authorized for use by or through
      Fingaz from a third party ("<strong
        >Fingaz Content</strong
      >" and together with Member Content, "<strong
        >Collective Content</strong
      >").
    </p>
    <p>
      5.2 The Fingaz Platform, Fingaz Content, and Member
      Content may in its entirety or in part be protected by
      copyright, trademark, and/or other laws of the United
      States and other countries. You acknowledge and agree
      that the Fingaz Platform and Fingaz Content, including
      all associated intellectual property rights, are the
      exclusive property of Fingaz and/or its licensors or
      authorizing third-parties. You will not remove, alter
      or obscure any copyright, trademark, service mark or
      other proprietary rights notices incorporated in or
      accompanying the Fingaz Platform, Fingaz Content or
      Member Content. All trademarks, service marks, logos,
      trade names, and any other source identifiers of
      Fingaz used on or in connection with the Fingaz
      Platform and Fingaz Content are trademarks or
      registered trademarks of Fingaz in the United States
      and abroad. Trademarks, service marks, logos, trade
      names and any other proprietary designations of third
      parties used on or in connection with the Fingaz
      Platform, Fingaz Content, and/or Collective Content
      are used for identification purposes only and may be
      the property of their respective owners.
    </p>
    <p>
      5.3 You will not use, copy, adapt, modify, prepare
      derivative works of, distribute, license, sell,
      transfer, publicly display, publicly perform,
      transmit, broadcast or otherwise exploit the Fingaz
      Platform or Collective Content, except to the extent
      you are the legal owner of certain Member Content or
      as expressly permitted in these Terms. No licenses or
      rights are granted to you by implication or otherwise
      under any intellectual property rights owned or
      controlled by Fingaz or its licensors, except for the
      licenses and rights expressly granted in these Terms.
    </p>
    <p>
      5.4 Subject to your compliance with these Terms,
      Fingaz grants you a limited, non-exclusive,
      non-sublicensable, revocable, non-transferable license
      to (i) download and use the Application on your
      personal device(s); and (ii) access and view any
      Collective Content made available on or through the
      Fingaz Platform and accessible to you, solely for your
      personal and non-commercial use.
    </p>
    <p>
      5.5 By creating, uploading, posting, sending,
      receiving, storing, or otherwise making available any
      Member Content on or through the Fingaz Platform, you
      grant to Fingaz a non-exclusive, worldwide,
      royalty-free, irrevocable, perpetual (or for the term
      of the protection), sub-licensable and transferable
      license to such Member Content to access, use, store,
      copy, modify, prepare derivative works of, distribute,
      publish, transmit, stream, broadcast, and otherwise
      exploit in any manner such Member Content to provide
      and/or promote the Fingaz Platform, in any media or
      platform. Insofar as Member Content (including
      Verified Images) includes personal information, such
      Member Content will only be used for these purposes if
      such use complies with applicable data protection laws
      in accordance with our
      <a routerLink="">Privacy Policy</a>. Unless you
      provide specific consent, Fingaz does not claim any
      ownership rights in any Member Content and nothing in
      these Terms will be deemed to restrict any rights that
      you may have to use or exploit your Member Content.
    </p>
    <p>
      5.6 Fingaz may offer Hosts the option of having
      professional photographers take photographs of their
      Host Services, which are made available by the
      photographer to Hosts to include in their Listings
      with or without a watermark or tag bearing the words
      "Fingaz.app Verified Photo" or similar wording
      ("<strong>Verified Images</strong>"). You are
      responsible for ensuring that your Host Service is
      accurately represented in the Verified Images and you
      will stop using the Verified Images on or through the
      Fingaz Platform if they no longer accurately represent
      your Listing, if you stop hosting the Host Service
      featured, or if your Fingaz Account is terminated or
      suspended for any reason. You acknowledge and agree
      that Fingaz shall have the right to use any Verified
      Images in accordance with Section 5.5 for advertising,
      marketing and/or any other business purposes in any
      media or platform, whether in relation to your Listing
      or otherwise, without further notice or compensation
      to you. Where Fingaz is not the exclusive owner of
      Verified Images, by using such Verified Images on or
      through the Fingaz Platform, you grant to Fingaz an
      exclusive, worldwide, royalty-free, irrevocable,
      perpetual (or for the term of the protection),
      sub-licensable and transferable license to use such
      Verified Images for advertising, marketing and/or any
      other business purposes in any media or platform,
      whether in relation to your Listing or otherwise,
      without further notice or compensation to you. Fingaz
      in turn grants you a limited, non-exclusive,
      non-sublicensable, revocable, non-transferable license
      to use Verified Images outside of the Fingaz Platform
      solely for your personal and non-commercial use.
    </p>
    <p>
      5.7 You are solely responsible for all Member Content
      that you make available on or through the Fingaz
      Platform. Accordingly, you represent and warrant that:
      (i) you either are the sole and exclusive owner of all
      Member Content that you make available on or through
      the Fingaz Platform or you have all rights, licenses,
      consents and releases that are necessary to grant to
      Fingaz the rights in and to such Member Content, as
      contemplated under these Terms; and (ii) neither the
      Member Content nor your posting, uploading,
      publication, submission or transmittal of the Member
      Content or Fingaz's use of the Member Content (or any
      portion thereof) as contemplated under these Terms
      will infringe, misappropriate or violate a third
      party's patent, copyright, trademark, trade secret,
      moral rights or other proprietary or intellectual
      property rights, or rights of publicity or privacy, or
      result in the violation of any applicable law or
      regulation.
    </p>
    <p>
      5.8 You will not post, upload, publish, submit or
      transmit any Member Content that: (i) is fraudulent,
      false, misleading (directly or by omission or failure
      to update information) or deceptive; (ii) is
      defamatory, libelous, obscene, pornographic, vulgar or
      offensive; (iii) promotes discrimination, bigotry,
      racism, hatred, harassment or harm against any
      individual or group; (iv) is violent or threatening or
      promotes violence or actions that are threatening to
      any other person or animal; (v) promotes illegal or
      harmful activities or substances; or (vi) violates
      Fingaz’s
      <a routerLink="">Content Policy</a> or any other
      Fingaz policy. Fingaz may, without prior notice,
      remove or disable access to any Member Content that
      Fingaz finds to be in violation of applicable law,
      these Terms or Fingaz’s then-current
      <a routerLink="">Policies</a> or
      <a routerLink="">Standards</a>, or otherwise may be
      harmful or objectionable to Fingaz, its Members, third
      parties, or property.
    </p>
    <p>
      5.9 Fingaz respects copyright law and expects its
      Members to do the same. If you believe that any
      content on the Fingaz Platform infringes copyrights
      you own, please notify us in accordance with our
      <a routerLink="">Copyright Policy</a>.
    </p>
    <h4><a id="sec201910_6"></a>6. Service Fees</h4>
    <p>
      6.1 Fingaz may charge fees to Hosts ("<strong
        >Host Fees</strong
      >") and/or Guests ("<strong>Guest Fees</strong>")
      (collectively, "<strong>Service Fees</strong>") in
      consideration for the use of the Fingaz Platform. More
      information about when Service Fees apply and how they
      are calculated can be found on our
      <a routerLink="">Service Fees page</a>.
    </p>
    <p>
      6.2 Any applicable Service Fees (including any
      applicable Taxes) will be displayed to a Host or Guest
      prior to publishing or booking a Listing. Fingaz
      reserves the right to change the Service Fees at any
      time, and will provide Members adequate notice of any
      fee changes before they become effective. Such fee
      changes will not affect any bookings made prior to the
      effective date of the fee change.
    </p>
    <p>
      6.3 You are responsible for paying any Service Fees
      that you owe to Fingaz. The applicable Service Fees
      (including any applicable Taxes) are collected by
      Fingaz Payments. Fingaz Payments will deduct any Host
      Fees from the Listing Fee before remitting the payout
      to the Host. Any Guest Fees are included in the Total
      Fees collected by Fingaz Payments. Except as otherwise
      provided on the Fingaz Platform, Service Fees are
      non-refundable.
    </p>
    <h4>
      <a id="sec201910_7"></a>7. Terms specific for Hosts
    </h4>
    <h5>7.1 Terms applicable to all Listings</h5>
    <p>
      7.1.1 When creating a Listing through the Fingaz
      Platform you must (i) provide complete and accurate
      information about your Host Service (such as listing
      description, location, and calendar availability),
      (ii) disclose any deficiencies, restrictions (such as
      house rules) and requirements that apply (such as any
      minimum age, proficiency or fitness requirements for
      an Experience) and (iii) provide any other pertinent
      information requested by Fingaz. You are responsible
      for keeping your Listing information (including
      calendar availability) up-to-date at all times.
    </p>
    <p>
      7.1.2 You are solely responsible for setting a price
      (including any Taxes if applicable, or charges such as
      cleaning fees) for your Listing (“<strong
        >Listing Fee</strong
      >”). Once a Guest requests a booking of your Listing,
      you may not request that the Guest pays a higher price
      than in the booking request.
    </p>
    <p>
      7.1.3 Any terms and conditions included in your
      Listing, in particular in relation to cancellations,
      must not conflict with these Terms or the relevant
      cancellation policy for your Listing.
    </p>
    <p>
      7.1.4 Fingaz may enable certain Hosts to participate
      in its “<strong>Open Homes Program</strong>.” The Open
      Homes Program enables Hosts to provide Listings to
      certain Guests, such as refugees or evacuees, for
      free. You acknowledge that if you choose to
      participate in the Open Homes Program, your ability to
      restrict your Listing to certain Guests, such as
      Guests with previous positive Reviews, may be limited.
    </p>
    <p>
      7.1.5 Pictures, animations or videos (collectively,
      "<strong>Images</strong>") used in your Listings must
      accurately reflect the quality and condition of your
      Host Services. Fingaz reserves the right to require
      that Listings have a minimum number of Images of a
      certain format, size and resolution.
    </p>
    <p>
      7.1.6 The placement and ranking of Listings in search
      results on the Fingaz Platform may vary and depend on
      a variety of factors, such as Guest search parameters
      and preferences, Host requirements, price and calendar
      availability, number and quality of Images, customer
      service and cancellation history, Reviews and Ratings,
      type of Host Service, and/or ease of booking. More
      information about the factors that determine how your
      Listing appears in search results can be found on our
      <a routerLink="">help center</a>.
    </p>
    <p>
      7.1.7 When you accept or have pre-approved a booking
      request by a Guest, you are entering into a legally
      binding agreement with the Guest and are required to
      provide your Host Service(s) to the Guest as described
      in your Listing when the booking request is made. You
      also agree to pay the applicable Host Fee and any
      applicable Taxes.
    </p>
    <p>
      7.1.8 Fingaz recommends that Hosts obtain appropriate
      insurance for their Host Services. Please review any
      respective insurance policy carefully, and in
      particular make sure that you are familiar with and
      understand any exclusions to, and any deductibles that
      may apply for, such insurance policy, including, but
      not limited to, whether or not your insurance policy
      will cover the actions or inactions of Guests (and the
      individuals the Guest has booked for, if applicable)
      while staying at your Accommodation or participating
      in your Experience, Event or other Host Service.
    </p>
    <h5>7.2 Listing Accommodations</h5>
    <p>
      7.2.1 Unless expressly allowed by Fingaz, you may not
      list more than one Accommodation per Listing.
    </p>
    <p>
      7.2.2 If you choose to require a security deposit for
      your Accommodation, you must specify this in your
      Listing ("<strong>Security Deposit</strong>"). Hosts
      are not allowed to ask for a Security Deposit (i)
      after a booking has been confirmed or (ii) outside of
      the Fingaz Platform.
    </p>
    <p>
      7.2.3 You represent and warrant that any Listing you
      post and the booking of, or a Guest's stay at, an
      Accommodation will (i) not breach any agreements you
      have entered into with any third parties, such as
      homeowners association, condominium, or other
      agreements, and (ii) comply with all applicable laws
      (such as zoning laws), Tax requirements, and other
      rules and regulations (including having all required
      permits, licenses and registrations). As a Host, you
      are responsible for your own acts and omissions and
      are also responsible for the acts and omissions of any
      individuals who reside at or are otherwise present at
      the Accommodation at your request or invitation,
      excluding the Guest and any individuals the Guest
      invites to the Accommodation.
    </p>
    <h5>
      7.3 Listing Experiences, Events and other Host
      Services
    </h5>
    <p>
      Hosts who list Experiences, Events and Host Services
      other than Accommodations agree to and are subject to
      the
      <a routerLink=""
        >Additional Terms for Experience Hosts</a
      >.
    </p>
    <h5>7.4 Co-Hosts</h5>
    <p>
      7.4.1 Fingaz may enable Hosts to authorize other
      Members (“<strong>Co-Hosts</strong>”) to administer
      the Host’s Listing(s), and to bind the Host and take
      certain actions in relation to the Listing(s) as
      permitted by the Host, such as accepting booking
      requests, messaging and welcoming Guests, and updating
      the Listing Fee and calendar availability
      (collectively, “<strong>Co-Host Services</strong>”).
      Any agreement formed between Host and Co-Host may not
      conflict with these Terms, the
      <a routerLink="">Payments Terms</a>, or any other
      <a routerLink="">Policies</a> applicable to your Host
      Service(s). Co-Hosts may only act in an individual
      capacity and not on behalf of a company or other
      organization, unless expressly authorized by Fingaz.
      Fingaz reserves the right, in our sole discretion, to
      limit the number of Co-Hosts a Host may invite for
      each Listing and to limit the number of Listings a
      Co-Host may manage.
    </p>
    <p>
      7.4.2 Hosts should exercise due diligence and care
      when deciding who to add as a Co-Host to their
      Listing(s). Hosts remain solely responsible and liable
      for any and all Listings and Member Content published
      on the Fingaz Platform, including any Listing created
      by a Co-Host on their behalf. Further, Hosts remain
      responsible and liable for their own acts and
      omissions, including, but not limited to, conduct that
      causes harm or damage to the Co-Host(s). Co-Hosts
      remain responsible and liable for their own acts and
      omissions when engaging in their roles and
      responsibilities as a Co-Host, including, but not
      limited to, conduct that causes harm or damage to the
      Host. In addition, both Host and Co-Host are jointly
      responsible and severally liable for third party
      claims, including Guest claims, arising from the acts
      and omissions of the other person as related to
      hosting activities, communications with Guests, and
      the provision of any Co-Host Services.
    </p>
    <p>
      7.4.3 Unless agreed otherwise by Host and Co-Host,
      Host and Co-Host may terminate the Co-Host agreement
      at any time. In addition, both Host and Co-Host
      acknowledge that their Co-hosting relationship will
      terminate in the event that Fingaz (i) terminates the
      Co-Host service or (ii) terminates either party’s
      participation in the Co-Host service. When the Co-Host
      agreement is terminated, the Host will remain
      responsible for all of the Co-Host’s actions prior to
      the termination, including the responsibility to
      fulfill any pending or future bookings initiated prior
      to the termination. When a Member is removed as a
      Co-Host, that Member will no longer have access to any
      Host or Guest information related to the applicable
      Host’s Listing(s).
    </p>
    <p>
      7.4.4 As a Co-Host, you will not be reviewed by
      Guests, meaning that your Co-Host activities will not
      affect your Reviews or Ratings for other Listings for
      which you are a Host. Instead, the Host of such
      Listing(s) will be reviewed by Guests (including
      potentially on the basis of the Co-Host’s conduct and
      performance). Hosts acknowledge that Reviews and
      Ratings from Guests for their Listing(s) may be
      impacted by a Co-Host’s conduct and performance.
    </p>
    <h4>
      <a id="sec201910_8"></a>8. Terms specific for Guests
    </h4>
    <h5>8.1 Terms applicable to all bookings</h5>
    <p>
      8.1.1 Subject to meeting any requirements (such as
      completing any verification processes) set by Fingaz
      and/or the Host, you can book a Listing available on
      the Fingaz Platform by following the respective
      booking process. All applicable fees, including the
      Listing Fee, Security Deposit (if applicable), Guest
      Fee and any applicable Taxes (collectively, “<strong
        >Total Fees</strong
      >”) will be presented to you prior to booking a
      Listing. You agree to pay the Total Fees for any
      booking requested in connection with your Fingaz
      Account.
    </p>
    <p>
      8.1.2 Upon receipt of a booking confirmation from
      Fingaz, a legally binding agreement is formed between
      you and your Host, subject to any additional terms and
      conditions of the Host that apply, including in
      particular the applicable cancellation policy and any
      rules and restrictions specified in the Listing.
      Fingaz Payments will collect the Total Fees at the
      time of the booking request or upon the Host’s
      confirmation pursuant to the
      <a routerLink="">Payments Terms</a>. For certain
      bookings, Guests may be required to pay or have the
      option to pay in multiple installments.
    </p>
    <p>
      8.1.3 If you book a Host Service on behalf of
      additional guests, you are required to ensure that
      every additional guest meets any requirements set by
      the Host, and is made aware of and agrees to these
      Terms and any terms and conditions, rules and
      restrictions set by the Host. If you are booking for
      an additional guest who is a minor, you represent and
      warrant that you are legally authorized to act on
      behalf of the minor. Minors may only participate in an
      Experience, Event or other Host Service if accompanied
      by an adult who is responsible for them.
    </p>
    <p>
      8.1.4 Fingaz may enable a Guest who is booking a
      Listing on behalf of one or more additional guests
      (the “<strong>Organizer</strong>”) to split the
      payment of the Total Fees for an eligible booking on a
      pro-rata basis between the Organizer and at least one
      other additional guest (each a
      “<strong>Co-Payer</strong>”) (the “<strong
        >Group Payment Service</strong
      >”). In order to participate in the Group Payment
      Service, each Co-Payer must have or register an Fingaz
      Account prior to making a payment. All payments via
      the Group Payment Service are handled by Fingaz
      Payments and are subject to the
      <a routerLink="">Group Payment Terms of Service</a>.
    </p>
    <h5>8.2 Booking Accommodations</h5>
    <p>
      8.2.1 You understand that a confirmed booking of an
      Accommodation (“<strong>Accommodation Booking</strong
      >”) is a limited license granted to you by the Host to
      enter, occupy and use the Accommodation for the
      duration of your stay, during which time the Host
      (only where and to the extent permitted by applicable
      law) retains the right to re-enter the Accommodation,
      in accordance with your agreement with the Host.
    </p>
    <p>
      8.2.2 You agree to leave the Accommodation no later
      than the checkout time that the Host specifies in the
      Listing or such other time as mutually agreed upon
      between you and the Host. If you stay past the agreed
      upon checkout time without the Host's consent
      (“<strong>Overstay</strong>”), you no longer have a
      license to stay in the Accommodation and the Host is
      entitled to make you leave in a manner consistent with
      applicable law. In addition, you agree to pay, if
      requested by the Host, for each twenty-four (24) hour
      period (or any portion thereof) that you Overstay, an
      additional nightly fee of up to two (2) times the
      average nightly Listing Fee originally paid by you to
      cover the inconvenience suffered by the Host, plus all
      applicable Guest Fees, Taxes, and any legal expenses
      incurred by the Host to make you leave (collectively,
      "<strong>Overstay Fees</strong>"). Overstay Fees for
      late checkouts on the checkout date that do not impact
      upcoming bookings may be limited to the additional
      costs incurred by the Host as a result of such
      Overstay. If you Overstay at an Accommodation, you
      authorize Fingaz (via Fingaz Payments) to charge you
      to collect Overstay Fees. A Security Deposit, if
      required by a Host, may be applied to any Overstay
      Fees due for a Guest’s Overstay.
    </p>
    <h5>
      8.3 Booking Experiences, Events and other Host
      Services
    </h5>
    <p>
      8.3.1 You should carefully review the description of
      any Experience, Event or other Host Service you intend
      to book to ensure you (and any additional guests you
      are booking for) meet any minimum age, proficiency,
      fitness or other requirements which the Host has
      specified in their Listing. At your sole discretion
      you may want to inform the Host of any medical or
      physical conditions, or other circumstances that may
      impact your and any additional guest’s ability to
      participate in any Experience, Event or other Host
      Service. In addition, certain laws, like the minimum
      legal drinking age in the location of the Experience,
      Event or other Host Service, may also apply. You are
      responsible for identifying, understanding, and
      complying with all laws, rules and regulations that
      apply to your participation in an Experience, Event or
      other Host Service.
    </p>
    <p>
      8.3.2 Before and during an Experience, Event or other
      Host Service you must at all times adhere to the
      Hosts’ instructions.
    </p>
    <p>
      8.3.3 You may not bring any additional individuals to
      an Experience, Event or other Host Service unless such
      an individual was added by you as an additional guest
      during the booking process on the Fingaz Platform.
    </p>
    <p><strong>8.4 Fingaz Travel Credits</strong></p>
    <p>
      Fingaz Travel Credits may be redeemed for eligible
      bookings via the Fingaz Platform as specified in the
      terms and conditions provided with the Travel Credit.
      You may only redeem Fingaz Travel Credits after the
      Fingaz Travel Credits are reflected in your Fingaz
      Account.
    </p>
    <h4>
      <a id="sec201910_9"></a>9. Booking Modifications,
      Cancellations and Refunds, Resolution Center
    </h4>
    <p>
      9.1 Hosts and Guests are responsible for any
      modifications to a booking that they make via the
      Fingaz Platform or direct Fingaz customer service to
      make ("<strong>Booking Modifications</strong>"), and
      agree to pay any additional Listing Fees, Host Fees or
      Guest Fees and/or Taxes associated with such Booking
      Modifications.
    </p>
    <p>
      9.2 Guests can cancel a confirmed booking at any time
      pursuant to the Listing’s
      <a routerLink="">cancellation policy</a> set by the
      Host, and Fingaz Payments will refund the amount of
      the Total Fees due to the Guest in accordance with
      such cancellation policy. Unless
      <a routerLink="">extenuating circumstances</a>
      exist, any portion of the Total Fees due to the Host
      under the applicable cancellation policy will be
      remitted to the Host by Fingaz Payments pursuant to
      the <a routerLink="">Payments Terms</a>.
    </p>
    <p>
      9.3 If a Host cancels a confirmed booking, the Guest
      will receive a full refund of the Total Fees for such
      booking. In some instances, Fingaz may allow the Guest
      to apply the refund to a new booking, in which case
      Fingaz Payments will credit the amount against the
      Guest’s subsequent booking at the Guest’s direction.
      Further, Fingaz may publish an automated review on the
      Listing cancelled by the Host indicating that a
      booking was cancelled. In addition, Fingaz may (i)
      keep the calendar for the Listing unavailable or
      blocked for the dates of the cancelled booking, and/or
      (ii) impose a
      <a routerLink="">cancellation fee</a>, unless the Host
      has a valid reason for cancelling the booking pursuant
      to
      <a routerLink=""
        >Fingaz’s Extenuating Circumstances Policy</a
      >
      or has legitimate concerns about the Guest’s behavior.
    </p>
    <p>
      9.4 For Experiences, Events and other Host Services,
      if weather poses a safety risk to Guests, or if it
      prevents a Host from carrying out a Host Service that
      takes place primarily outdoors, Hosts may cancel the
      Host Service. Hosts may also cancel the Host Service
      if other conditions exist that would prevent the Host
      from offering the Host Service safely.
    </p>
    <p>
      9.5 In certain circumstances, Fingaz may decide, in
      its sole discretion, that it is necessary to cancel a
      pending or confirmed booking and initiate
      corresponding refunds and payouts. This may be for
      reasons set forth in
      <a routerLink=""
        >Fingaz's Extenuating Circumstances Policy</a
      >
      or (i) where Fingaz believes in good faith, while
      taking the legitimate interests of both parties into
      account, this is necessary to avoid significant harm
      to Fingaz, other Members, third parties or property,
      or (ii) for any of the reasons set out in these Terms.
    </p>
    <p>
      9.6 If a Guest who books an Accommodation suffers a
      Travel Issue as defined in the
      <a routerLink="">Guest Refund Policy</a>, Fingaz may
      determine, in its sole discretion, to refund the Guest
      part or all of the Total Fees in accordance with the
      Guest Refund Policy. If a Guest who books an
      Experience, Event or other Host Service suffers a
      Travel Issue as defined in the
      <a routerLink="">Experiences Guest Refund Policy</a>,
      Fingaz may determine, in its sole discretion, to
      refund the Guest part or all of the Total Fees in
      accordance with the Experiences Guest Refund Policy.
    </p>
    <p>
      9.7 If a Guest or Fingaz cancels a confirmed booking,
      and the Guest receives a refund in accordance with the
      <a routerLink="">Guest Refund Policy</a>,
      <a routerLink="">Experiences Guest Refund Policy</a>,
      <a routerLink="">Extenuating Circumstances Policy</a>,
      or the applicable cancellation policy set by the Host
      and mentioned in the Listing, after the Host has
      already been paid, Fingaz Payments will be entitled to
      recover the amount of any such refund from the Host,
      including by subtracting such refund amount out from
      any future Payouts due to the Host.
    </p>
    <p>
      9.8 Except as otherwise set out in these Terms,
      Members may use the Resolution Center to send or
      request money for refunds, additional Host Services or
      Damage Claims related to bookings. You agree to pay
      all amounts sent through the Resolution Center in
      connection with your Fingaz Account, and Fingaz
      Payments will handle all such payments.
    </p>
    <h4>
      <a id="sec201910_10"></a>10. Ratings and Reviews
    </h4>
    <p>
      10.1 Within a certain timeframe after completing a
      booking, Guests and Hosts can leave a public review
      (“<strong>Review</strong>”) and submit a star rating
      (“<strong>Rating</strong>”) about each other. Ratings
      or Reviews reflect the opinions of individual Members
      and do not reflect the opinion of Fingaz. Ratings and
      Reviews are not verified by Fingaz for accuracy and
      may be incorrect or misleading.
    </p>
    <p>
      10.2 Ratings and Reviews by Guests and Hosts must be
      accurate and may not contain any offensive or
      defamatory language. Ratings and Reviews are subject
      to Section 5 and must comply with Fingaz’s
      <a routerLink="">Content Policy</a> and
      <a routerLink="">Extortion Policy</a>.
    </p>
    <p>
      10.3 Members are prohibited from manipulating the
      Ratings and Reviews system in any manner, such as
      instructing a third party to write a positive or
      negative Review about another Member.
    </p>
    <p>
      10.4 Ratings and Reviews are part of a Member’s public
      profile and may also be surfaced elsewhere on the
      Fingaz Platform (such as the Listing page) together
      with other relevant information such as number of
      bookings, number of cancellations, average response
      time and other information.
    </p>
    <h4>
      <a id="sec201910_11"></a>11. Damage to Accommodations,
      Disputes between Members
    </h4>
    <p>
      11.1 As a Guest, you are responsible for leaving the
      Accommodation (including any personal or other
      property located at the Accommodation) in the
      condition it was in when you arrived. You are
      responsible for your own acts and omissions and are
      also responsible for the acts and omissions of any
      individuals whom you invite to, or otherwise provide
      access to, the Accommodation, excluding the Host (and
      the individuals the Host invites to the Accommodation,
      if applicable).
    </p>
    <p>
      11.2 If a Host claims and provides evidence that you
      as a Guest have damaged an Accommodation or any
      personal or other property at an Accommodation
      ("<strong>Damage Claim</strong>"), the Host can seek
      payment from you through the Resolution Center. If a
      Host escalates a Damage Claim to Fingaz, you will be
      given an opportunity to respond. If you agree to pay
      the Host, or Fingaz determines in its sole discretion
      that you are responsible for the Damage Claim, Fingaz
      via Fingaz Payments will, after the end of your stay,
      collect any such sums from you and/or against the
      Security Deposit (if applicable) required to cover the
      Damage Claim pursuant to the
      <a routerLink="">Payments Terms</a>. Fingaz also
      reserves the right to otherwise collect payment from
      you and pursue any remedies available to Fingaz in
      this regard in situations in which you are responsible
      for a Damage Claim, including, but not limited to, in
      relation to any payment requests made by Hosts under
      the <a routerLink="">Fingaz Host Guarantee</a> or
      <a routerLink="">Japan Host Insurance</a>.
    </p>
    <p>
      11.3 Members agree to cooperate with and assist Fingaz
      in good faith, and to provide Fingaz with such
      information and take such actions as may be reasonably
      requested by Fingaz, in connection with any Damage
      Claims or other complaints or claims made by Members
      relating to (i) Accommodations or any personal or
      other property located at an Accommodation (including,
      without limitation, payment requests made under the
      Fingaz Host Guarantee or Japan Host Insurance), (ii)
      Experiences, (iii) Co-Host agreements, or (iv) a Group
      Payment Booking. A Member shall, upon Fingaz's
      reasonable request and at no cost to the Member,
      participate in mediation or a similar resolution
      process with another Member, which process will be
      conducted by Fingaz or a third party selected by
      Fingaz or its insurer, with respect to losses for
      which a Member is requesting payment from Fingaz
      (including but not limited to payments under the
      Fingaz Host Guarantee or payment under Japan Host
      Insurance).
    </p>
    <p>
      11.4 If you are a Guest or a Co-Host, you understand
      and agree that Fingaz may make a claim under your
      homeowner's, renter's or other insurance policy
      related to any damage or loss that you may have
      caused, or been responsible for, to any personal or
      other property (including an Accommodation) of the
      Host (including without limitation amounts paid by
      Fingaz under the Fingaz Host Guarantee or amounts paid
      under Japan Host Insurance). You agree to cooperate
      with and assist Fingaz in good faith, and to provide
      Fingaz with such information as may be reasonably
      requested by Fingaz, to make a claim under your
      homeowner's, renter's or other insurance policy,
      including, but not limited to, executing documents and
      taking such further acts as Fingaz may reasonably
      request to assist Fingaz in accomplishing the
      foregoing.
    </p>
    <h4><a id="sec201910_12"></a>12. Rounding off</h4>
    <p>
      Fingaz generally supports payment amounts that are
      payable from or to Guests or Hosts to the smallest
      unit supported by a given currency (i.e., U.S. cents,
      Euro cents or other supported currencies). Where
      Fingaz’s third-party payment services provider does
      not support payments in the smaller unit supported by
      a given currency, Fingaz may, in its sole discretion,
      round up or round down the displayed amounts that are
      payable from or to Guests or Hosts to the nearest
      whole functional base unit in which the currency is
      denominated (i.e. to the nearest dollar, Euro or other
      supported currency); for example, Fingaz may round up
      an amount of $101.50 to $102.00, and round down an
      amount of $101.49 to $101.00.
    </p>
    <h4><a id="sec201910_13"></a>13. Taxes</h4>
    <p>
      13.1 As a Host you are solely responsible for
      determining your obligations to report, collect, remit
      or include in your Listing Fees any applicable VAT or
      other indirect sales taxes, occupancy tax, tourist or
      other visitor taxes or income taxes
      ("<strong>Taxes</strong>").
    </p>
    <p>
      13.2 Tax regulations may require us to collect
      appropriate Tax information from Hosts, or to withhold
      Taxes from payouts to Hosts, or both. If a Host fails
      to provide us with the required documentation under
      applicable law (e.g., a tax number) that we determine
      to be sufficient to alleviate our obligation (if any)
      to withhold Taxes from payouts to you, we reserve the
      right to withhold payouts up to the tax-relevant
      amount as required by law, until resolution.
    </p>
    <p>
      13.3 You understand that any appropriate governmental
      agency, department and/or authority ("<strong
        >Tax Authority</strong
      >") where your Accommodation is located may require
      Taxes to be collected from Guests or Hosts on Listing
      Fees, and to be remitted to the respective Tax
      Authority. The laws in jurisdictions may vary, but
      these Taxes may be required to be collected and
      remitted as a percentage of the Listing Fees set by
      Hosts, a set amount per day, or other variations, and
      are sometimes called "transient occupancy taxes,"
      "hotel taxes," "lodging taxes," "city taxes," "room
      taxes" or "tourist taxes" ("<strong
        >Occupancy Taxes</strong
      >").
    </p>
    <p>
      13.4 In certain jurisdictions, Fingaz may decide in
      its sole discretion to facilitate collection and
      remittance of Occupancy Taxes from or on behalf of
      Guests or Hosts, in accordance with these Terms
      ("<strong>Collection and Remittance</strong>") if such
      jurisdiction asserts Fingaz or Hosts have an Occupancy
      Tax collection and remittance obligation. In any
      jurisdiction in which we decide to facilitate direct
      Collection and Remittance, you hereby instruct and
      authorize Fingaz (via Fingaz Payments) to collect
      Occupancy Taxes from Guests on the Host's behalf at
      the time Listing Fees are collected, and to remit such
      Occupancy Taxes to the Tax Authority. In other
      jurisdictions Fingaz may decide in its sole discretion
      to collect Occupancy Taxes and remit such Taxes to
      eligible and qualifying Hosts, based on tax
      information supplied by the Host, for ultimate
      reporting and remittance by such Host to the Tax
      Authority (“<strong>Pass-Through Tax Feature</strong
      >”). Such Hosts using the Pass-Through Tax Feature
      will be solely responsible for informing Fingaz about
      the correct Occupancy Tax amount to be collected from
      the Guest in accordance with applicable law and
      directly remitting the Occupancy Taxes to the relevant
      Tax Authority. Fingaz does not assume any liability
      for the failure of a participating Host to comply with
      any applicable tax reporting or remittance
      obligations. The amount of Occupancy Taxes, if any,
      collected and remitted by Fingaz will be visible to
      and separately stated to both Guests and Hosts on
      their respective transaction documents. Where Fingaz
      is facilitating Collection and Remittance, Hosts are
      not permitted to collect any Occupancy Taxes being
      collected by Fingaz relating to their Accommodations
      in that jurisdiction.
    </p>
    <p>
      13.5 You agree that any claim or cause of action
      relating to Fingaz's facilitation of Collection and
      Remittance of Occupancy Taxes shall not extend to any
      supplier or vendor that may be used by Fingaz in
      connection with facilitation of Collection and
      Remittance, if any. Guests and Hosts agree that we may
      seek additional amounts from you in the event that the
      Taxes collected and/or remitted are insufficient to
      fully discharge your obligations to the Tax Authority,
      and agree that your sole remedy for Occupancy Taxes
      collected is a refund of Occupancy Taxes collected by
      Fingaz from the applicable Tax Authority in accordance
      with applicable procedures set by that Tax Authority.
    </p>
    <p>
      13.6 Fingaz reserves the right, with prior notice to
      Hosts, to cease the Collection and Remittance in any
      jurisdiction for any reason at which point Hosts and
      Guests are once again solely responsible and liable
      for the collection and/or remittance of any and all
      Occupancy Taxes that may apply to Accommodations in
      that jurisdiction.
    </p>
    <h4>
      <a id="sec201910_14"></a>14. Prohibited Activities
    </h4>
    <p>
      14.1 You are solely responsible for compliance with
      any and all laws, rules, regulations, and Tax
      obligations that may apply to your use of the Fingaz
      Platform. In connection with your use of the Fingaz
      Platform, you will not and will not assist or enable
      others to:
    </p>
    <ul>
      <li>
        breach or circumvent any applicable laws or
        regulations, agreements with third-parties,
        third-party rights, or our Terms,
        <a routerLink="">Policies</a> or
        <a routerLink="">Standards</a>;
      </li>
      <li>
        use the Fingaz Platform or Collective Content for
        any commercial or other purposes that are not
        expressly permitted by these Terms or in a manner
        that falsely implies Fingaz endorsement, partnership
        or otherwise misleads others as to your affiliation
        with Fingaz;
      </li>
      <li>
        copy, store or otherwise access or use any
        information, including personally identifiable
        information about any other Member, contained on the
        Fingaz Platform in any way that is inconsistent with
        Fingaz’s
        <a routerLink="">Privacy Policy</a> or these Terms
        or that otherwise violates the privacy rights of
        Members or third parties;
      </li>
      <li>
        use the Fingaz Platform in connection with the
        distribution of unsolicited commercial messages
        ("spam");
      </li>
      <li>
        offer, as a Host, any Accommodation that you do not
        yourself own or have permission to make available as
        a residential or other property through the Fingaz
        Platform;
      </li>
      <li>
        unless Fingaz explicitly permits otherwise, book any
        Listing if you will not actually be using the Host
        Services yourself;
      </li>
      <li>
        contact another Member for any purpose other than
        asking a question related to a your own booking,
        Listing, or the Member's use of the Fingaz Platform,
        including, but not limited to, recruiting or
        otherwise soliciting any Member to join third-party
        services, applications or websites, without our
        prior written approval;
      </li>
      <li>
        use the Fingaz Platform to request, make or accept a
        booking independent of the Fingaz Platform, to
        circumvent any Service Fees or for any other reason;
      </li>
      <li>
        request, accept or make any payment for Listing Fees
        outside of the Fingaz Platform or Fingaz Payments.
        If you do so, you acknowledge and agree that you:
        (i) would be in breach of these Terms; (ii) accept
        all risks and responsibility for such payment, and
        (iii) hold Fingaz harmless from any liability for
        such payment;
      </li>
      <li>
        discriminate against or harass anyone on the basis
        of race, national origin, religion, gender, gender
        identity, physical or mental disability, medical
        condition, marital status, age or sexual
        orientation, or otherwise engage in any violent,
        harmful, abusive or disruptive behavior;
      </li>
      <li>
        misuse or abuse any Listings or services associated
        with the Fingaz Open Homes program as determined by
        Fingaz in its sole discretion.
      </li>
      <li>
        use, display, mirror or frame the Fingaz Platform or
        Collective Content, or any individual element within
        the Fingaz Platform, Fingaz's name, any Fingaz
        trademark, logo or other proprietary information, or
        the layout and design of any page or form contained
        on a page in the Fingaz Platform, without Fingaz's
        express written consent;
      </li>
      <li>
        dilute, tarnish or otherwise harm the Fingaz brand
        in any way, including through unauthorized use of
        Collective Content, registering and/or using Fingaz
        or derivative terms in domain names, trade names,
        trademarks or other source identifiers, or
        registering and/or using domains names, trade names,
        trademarks or other source identifiers that closely
        imitate or are confusingly similar to Fingaz
        domains, trademarks, taglines, promotional campaigns
        or Collective Content;
      </li>
      <li>
        use any robots, spider, crawler, scraper or other
        automated means or processes to access, collect data
        or other content from or otherwise interact with the
        Fingaz Platform for any purpose;
      </li>
      <li>
        avoid, bypass, remove, deactivate, impair,
        descramble, or otherwise attempt to circumvent any
        technological measure implemented by Fingaz or any
        of Fingaz's providers or any other third party to
        protect the Fingaz Platform;
      </li>
      <li>
        attempt to decipher, decompile, disassemble or
        reverse engineer any of the software used to provide
        the Fingaz Platform;
      </li>
      <li>
        take any action that damages or adversely affects,
        or could damage or adversely affect the performance
        or proper functioning of the Fingaz Platform;
      </li>
      <li>
        export, re-export, import, or transfer the
        Application except as authorized by United States
        law, the export control laws of your jurisdiction,
        and any other applicable laws; or
      </li>
      <li>
        violate or infringe anyone else’s rights or
        otherwise cause harm to anyone.
      </li>
    </ul>
    <p>
      14.2 You acknowledge that Fingaz has no obligation to
      monitor the access to or use of the Fingaz Platform by
      any Member or to review, disable access to, or edit
      any Member Content, but has the right to do so to (i)
      operate, secure and improve the Fingaz Platform
      (including without limitation for fraud prevention,
      risk assessment, investigation and customer support
      purposes); (ii) ensure Members’ compliance with these
      Terms; (iii) comply with applicable law or the order
      or requirement of a court, law enforcement or other
      administrative agency or governmental body; (iv)
      respond to Member Content that it determines is
      harmful or objectionable; or (v) as otherwise set
      forth in these Terms. Members agree to cooperate with
      and assist Fingaz in good faith, and to provide Fingaz
      with such information and take such actions as may be
      reasonably requested by Fingaz with respect to any
      investigation undertaken by Fingaz or a representative
      of Fingaz regarding the use or abuse of the Fingaz
      Platform.
    </p>
    <p>
      14.3 If you feel that any Member you interact with,
      whether online or in person, is acting or has acted
      inappropriately, including but not limited to anyone
      who (i) engages in offensive, violent or sexually
      inappropriate behavior, (ii) you suspect of stealing
      from you, or (iii) engages in any other disturbing
      conduct, you should immediately report such person to
      the appropriate authorities and then to Fingaz by
      contacting us with your police station and report
      number (if available). You agree that any report you
      make will not obligate us to take any action (beyond
      that required by law, if any).
    </p>
    <h4>
      <a id="sec201910_15"></a>15. Term and Termination,
      Suspension and other Measures
    </h4>
    <p>
      15.1 This Agreement shall be effective for a 30-day
      term, at the end of which it will automatically and
      continuously renew for subsequent 30-day terms until
      such time when you or Fingaz terminate the Agreement
      in accordance with this provision.
    </p>
    <p>
      15.2 You may terminate this Agreement at any time by
      sending us an email. If you cancel your Fingaz Account
      as a Host, any confirmed booking(s) will be
      automatically cancelled and your Guests will receive a
      full refund. If you cancel your Fingaz Account as a
      Guest, any confirmed booking(s) will be automatically
      cancelled and any refund will depend upon the terms of
      the Listing’s cancellation policy.
    </p>
    <p>
      15.3 Without limiting our rights specified below,
      Fingaz may terminate this Agreement for convenience at
      any time by giving you thirty (30) days' notice via
      email to your registered email address.
    </p>
    <p>
      15.4 Fingaz may immediately, without notice, terminate
      this Agreement and/or stop providing access to the
      Fingaz Platform if (i) you have materially breached
      your obligations under these Terms, the
      <a routerLink="">Payments Terms</a>, our
      <a routerLink="">Policies</a> or
      <a routerLink="">Standards</a>, (ii) you have violated
      applicable laws, regulations or third party rights, or
      (iii) Fingaz believes in good faith that such action
      is reasonably necessary to protect the personal safety
      or property of Fingaz, its Members, or third parties
      (for example in the case of fraudulent behavior of a
      Member).
    </p>
    <p>
      15.5 In addition, Fingaz may take any of the following
      measures (i) to comply with applicable law, or the
      order or request of a court, law enforcement or other
      administrative agency or governmental body, or if (ii)
      you have breached these Terms, the
      <a routerLink="">Payments Terms</a>, our
      <a routerLink="">Policies</a> or
      <a routerLink="">Standards</a>, applicable laws,
      regulations, or third party rights, (iii) you have
      provided inaccurate, fraudulent, outdated or
      incomplete information during the Fingaz Account
      registration, Listing process or thereafter, (iv) you
      and/or your Listings or Host Services at any time fail
      to meet any applicable quality or eligibility
      criteria, (v) you have repeatedly received poor
      Ratings or Reviews or Fingaz otherwise becomes aware
      of or has received complaints about your performance
      or conduct, (vi) you have repeatedly cancelled
      confirmed bookings or failed to respond to booking
      requests without a valid reason, or (vii) Fingaz
      believes in good faith that such action is reasonably
      necessary to protect the personal safety or property
      of Fingaz, its Members, or third parties, or to
      prevent fraud or other illegal activity:
    </p>
    <ul>
      <li>
        refuse to surface, delete or delay any Listings,
        Ratings, Reviews, or other Member Content;
      </li>
      <li>cancel any pending or confirmed bookings;</li>
      <li>
        limit your access to or use of the Fingaz Platform;
      </li>
      <li>
        temporarily or permanently revoke any special status
        associated with your Fingaz Account;
      </li>
      <li>
        temporarily or in case of severe or repeated
        offenses permanently suspend your Fingaz Account and
        stop providing access to the Fingaz Platform.
      </li>
    </ul>
    <p>
      In case of non-material breaches and where
      appropriate, you will be given notice of any intended
      measure by Fingaz and an opportunity to resolve the
      issue to Fingaz's reasonable satisfaction.
    </p>
    <p>
      15.6 If we take any of the measures described above
      (i) we may refund your Guests in full for any and all
      confirmed bookings that have been cancelled,
      irrespective of preexisting cancellation policies, and
      (ii) you will not be entitled to any compensation for
      pending or confirmed bookings that were cancelled.
    </p>
    <p>
      15.7 When this Agreement has been terminated, you are
      not entitled to a restoration of your Fingaz Account
      or any of your Member Content. If your access to or
      use of the Fingaz Platform has been limited or your
      Fingaz Account has been suspended or this Agreement
      has been terminated by us, you may not register a new
      Fingaz Account or access and use the Fingaz Platform
      through an Fingaz Account of another Member.
    </p>
    <p>
      15.8 Sections 5 and 16 to 22 of these Terms shall
      survive any termination or expiration of this
      Agreement.
    </p>
    <h4><a id="sec201910_16"></a>16. Disclaimers</h4>
    <p>
      <strong
        >If you choose to use the Fingaz Platform or
        Collective Content, you do so voluntarily and at
        your sole risk. The Fingaz Platform and Collective
        Content is provided “as is”, without warranty of any
        kind, either express or implied.</strong
      >
    </p>
    <p>
      <strong
        >You agree that you have had whatever opportunity
        you deem necessary to investigate the Fingaz
        Services, laws, rules, or regulations that may be
        applicable to your Listings and/or Host Services you
        are receiving and that you are not relying upon any
        statement of law or fact made by Fingaz relating to
        a Listing.</strong
      >
    </p>
    <p>
      <strong
        >If we choose to conduct identity verification or
        background checks on any Member, to the extent
        permitted by applicable law, we disclaim warranties
        of any kind, either express or implied, that such
        checks will identify prior misconduct by a Member or
        guarantee that a Member will not engage in
        misconduct in the future.</strong
      >
    </p>
    <p>
      <strong
        >You agree that some Experiences, Events, other Host
        Services, or the Group Payment Service may carry
        inherent risk, and by participating in such
        services, you choose to assume those risks
        voluntarily. For example, some Host Services may
        carry risk of illness, bodily injury, disability, or
        death, and you freely and willfully assume those
        risks by choosing to participate in those Host
        Services. You assume full responsibility for the
        choices you make before, during and after your
        participation in a Host Service or the Group Payment
        Service. If you are bringing a minor as an
        additional guest, you are solely responsible for the
        supervision of that minor throughout the duration of
        your Host Service and to the maximum extent
        permitted by law, you agree to release and hold
        harmless Fingaz from all liabilities and claims that
        arise in any way from any injury, death, loss or
        harm that occurs to that minor during the Host
        Service or in any way related to your Host
        Service.</strong
      >
    </p>
    <p>
      <strong
        >The foregoing disclaimers apply to the maximum
        extent permitted by law. You may have other
        statutory rights. However, the duration of
        statutorily required warranties, if any, shall be
        limited to the maximum extent permitted by
        law.</strong
      >
    </p>
    <h4><a id="sec201910_17"></a>17. Liability</h4>
    <p>
      <strong
        >You acknowledge and agree that, to the maximum
        extent permitted by law, the entire risk arising out
        of your access to and use of the Fingaz Platform and
        Collective Content, your publishing or booking of
        any Listing via the Fingaz Platform, your stay at
        any Accommodation, participation in any Experience
        or Event or use of any other Host Service,
        participation in the Group Payment Service, or any
        other interaction you have with other Members
        whether in person or online remains with you.
        Neither Fingaz nor any other party involved in
        creating, producing, or delivering the Fingaz
        Platform or Collective Content will be liable for
        any incidental, special, exemplary or consequential
        damages, including lost profits, loss of data or
        loss of goodwill, service interruption, computer
        damage or system failure or the cost of substitute
        products or services, or for any damages for
        personal or bodily injury or emotional distress
        arising out of or in connection with (i) these
        Terms, (ii) from the use of or inability to use the
        Fingaz Platform or Collective Content, (iii) from
        any communications, interactions or meetings with
        other Members or other persons with whom you
        communicate, interact or meet with as a result of
        your use of the Fingaz Platform, or (iv) from your
        publishing or booking of a Listing, including the
        provision or use of a Listing’s Host Services,
        whether based on warranty, contract, tort (including
        negligence), product liability or any other legal
        theory, and whether or not Fingaz has been informed
        of the possibility of such damage, even if a limited
        remedy set forth herein is found to have failed of
        its essential purpose. Except for our obligations to
        pay amounts to applicable Hosts pursuant to these
        Terms or an approved payment request under the
        Fingaz Host Guarantee or Japan Host Insurance, in no
        event will Fingaz’s aggregate liability arising out
        of or in connection with these Terms and your use of
        the Fingaz Platform including, but not limited to,
        from your publishing or booking of any Listings via
        the Fingaz Platform, or from the use of or inability
        to use the Fingaz Platform or Collective Content and
        in connection with any Accommodation, Experiences,
        Event,other Host Service, the Group Payment Service,
        or interactions with any other Members, exceed the
        amounts you have paid or owe for bookings via the
        Fingaz Platform as a Guest in the twelve (12) month
        period prior to the event giving rise to the
        liability, or if you are a Host, the amounts paid by
        Fingaz to you in the twelve (12) month period prior
        to the event giving rise to the liability, or one
        hundred U.S. dollars (US$100), if no such payments
        have been made, as applicable. The limitations of
        damages set forth above are fundamental elements of
        the basis of the bargain between Fingaz and you.
        Some jurisdictions do not allow the exclusion or
        limitation of liability for consequential or
        incidental damages, so the above limitation may not
        apply to you. If you reside outside of the U.S.,
        this does not affect Fingaz’s liability for death or
        personal injury arising from its negligence, nor for
        fraudulent misrepresentation, misrepresentation as
        to a fundamental matter or any other liability which
        cannot be excluded or limited under applicable
        law.</strong
      >
    </p>
    <h4><a id="sec201910_18"></a>18. Indemnification</h4>
    <p>
      To the maximum extent permitted by applicable law, you
      agree to release, defend (at Fingaz’s option),
      indemnify, and hold Fingaz and its affiliates and
      subsidiaries, including but not limited to, Fingaz
      Payments, and their officers, directors, employees and
      agents, harmless from and against any claims,
      liabilities, damages, losses, and expenses, including,
      without limitation, reasonable legal and accounting
      fees, arising out of or in any way connected with (i)
      your breach of these Terms or our Policies or
      Standards, (ii) your improper use of the Fingaz
      Platform or any Fingaz Services, (iii) your
      interaction with any Member, stay at an Accommodation,
      participation in an Experience, Event or other Host
      Service, participation in the Group Payment Service,
      including without limitation any injuries, losses or
      damages (whether compensatory, direct, incidental,
      consequential or otherwise) of any kind arising in
      connection with or as a result of such interaction,
      stay, participation or use, (iv) Fingaz’s Collection
      and Remittance of Occupancy Taxes, or (v) your breach
      of any laws, regulations or third party rights.
    </p>
    <h4>
      <a id="sec201910_19"></a>19. Dispute Resolution and
      Arbitration Agreement
    </h4>
    <p>
      19.1 This Dispute Resolution and Arbitration Agreement
      shall apply if your (i) country of residence or
      establishment is in the United States; or (ii) your
      country of residence or establishment is not in the
      United States, but bring any claim against Fingaz in
      the United States (to the extent not in conflict with
      Section 21).
    </p>
    <p>
      19.2
      <em>Overview of Dispute Resolution Process</em>.
      Fingaz is committed to participating in a
      consumer-friendly dispute resolution process. To that
      end, these Terms provide for a two-part process for
      individuals to whom Section 19.1 applies: (1) an
      informal negotiation directly with Fingaz’s customer
      service team, and (2) a binding arbitration
      administered by the American Arbitration Association
      (“<strong>AAA</strong>”) using its specially designed
      Consumer Arbitration Rules (as modified by this
      Section 19 and except as provided in Section 19.6).
      Specifically, the Consumer Arbitration Rules provide:
    </p>
    <ul>
      <li>
        Claims can be filed with AAA online (<a
          href="https://www.adr.org/"
          >www.adr.org</a
        >);
      </li>
      <li>
        Arbitrators must be neutral and no party may
        unilaterally select an arbitrator;
      </li>
      <li>
        Arbitrators must disclose any bias, interest in the
        result of the arbitration, or relationship with any
        party;
      </li>
      <li>
        Parties retain the right to seek relief in small
        claims court for certain claims, at their option;
      </li>
      <li>
        The initial filing fee for the consumer is capped at
        $200;
      </li>
      <li>
        The consumer gets to elect the hearing location and
        can elect to participate live, by phone, video
        conference, or, for claims under $25,000, by the
        submission of documents;
      </li>
      <li>
        The arbitrator can grant any remedy that the parties
        could have received in court to resolve the party’s
        individual claim.
      </li>
    </ul>
    <p>
      19.3
      <em
        >Pre-Arbitration Dispute Resolution and
        Notification</em
      >. Prior to initiating an arbitration, you and Fingaz
      each agree to notify the other party of the dispute
      and attempt to negotiate an informal resolution to it
      first. We will contact you at the email address you
      have provided to us; you can contact Fingaz’s customer
      service team by emailing us. If after a good faith
      effort to negotiate one of us feels the dispute has
      not and cannot be resolved informally, the party
      intending to pursue arbitration agrees to notify the
      other party via email prior to initiating the
      arbitration. In order to initiate arbitration, a claim
      must be filed with the AAA and the written Demand for
      Arbitration (available at
      <a
        href="https://www.adr.org/sites/default/files/Demand%20for%20Arbitration%20Consumer%20Arbitration%20Rules.pdf"
        >www.adr.org</a
      >) provided to the other party, as specified in the
      AAA Rules.
    </p>
    <p>
      <strong
        >19.4 <em>Agreement to Arbitrate</em>. You and
        Fingaz mutually agree that any dispute, claim or
        controversy arising out of or relating to these
        Terms or the applicability, breach, termination,
        validity, enforcement or interpretation thereof, or
        to the use of the Fingaz Platform, the Host
        Services, the Group Payment Service, or the
        Collective Content (collectively, “Disputes”) will
        be settled by binding individual arbitration (the
        “Arbitration Agreement”). If there is a dispute
        about whether this Arbitration Agreement can be
        enforced or applies to our Dispute, you and Fingaz
        agree that the arbitrator will decide that
        issue.</strong
      >
    </p>
    <p>
      19.5 <em>Exceptions to Arbitration Agreement</em>. You
      and Fingaz each agree that the following claims are
      exceptions to the Arbitration Agreement and will be
      brought in a judicial proceeding in a court of
      competent jurisdiction: (i) Any claim related to
      actual or threatened infringement, misappropriation or
      violation of a party’s copyrights, trademarks, trade
      secrets, patents, or other intellectual property
      rights; (ii) Any claim seeking emergency injunctive
      relief based on exigent circumstances (e.g., imminent
      danger or commission of a crime, hacking,
      cyber-attack).
    </p>
    <p>
      19.6 <em>Arbitration Rules and Governing Law</em>.
      This Arbitration Agreement evidences a transaction in
      interstate commerce and thus the Federal Arbitration
      Act governs the interpretation and enforcement of this
      provision. The arbitration will be administered by AAA
      in accordance with the Consumer Arbitration Rules
      and/or other AAA arbitration rules determined to be
      applicable by the AAA (the “<strong>AAA Rules</strong
      >“) then in effect, except as modified here. The AAA
      Rules are available at
      <a href="https://www.adr.org/">www.adr.org</a> or by
      calling the AAA at 1–800–778–7879.
    </p>
    <p>
      19.7
      <em
        >Modification to AAA Rules - Arbitration
        Hearing/Location</em
      >. In order to make the arbitration most convenient to
      you, Fingaz agrees that any required arbitration
      hearing may be conducted, at your option, (a) in the
      county where you reside; (b) in San Francisco County;
      (c) in any other location to which you and Fingaz both
      agree; (d) via phone or video conference; or (e) for
      any claim or counterclaim under $25,000, by solely the
      submission of documents to the arbitrator.
    </p>
    <p>
      19.8
      <em
        >Modification of AAA Rules - Attorney’s Fees and
        Costs</em
      >. You and Fingaz agree that Fingaz will be
      responsible for payment of the balance of any initial
      filing fee under the AAA Rules in excess of $200 for
      claims of $75,000 or less. You may be entitled to seek
      an award of attorney fees and expenses if you prevail
      in arbitration, to the extent provided under
      applicable law and the AAA rules. Unless the
      arbitrator determines that your claim was frivolous or
      filed for the purpose of harassment, Fingaz agrees it
      will not seek, and hereby waives all rights it may
      have under applicable law or the AAA Rules, to recover
      attorneys’ fees and expenses if it prevails in
      arbitration.
    </p>
    <p>
      19.9 <em>Arbitrator’s Decision</em>. The arbitrator’s
      decision will include the essential findings and
      conclusions upon which the arbitrator based the award.
      Judgment on the arbitration award may be entered in
      any court with proper jurisdiction. The arbitrator may
      award declaratory or injunctive relief only on an
      individual basis and only to the extent necessary to
      provide relief warranted by the claimant’s individual
      claim.
    </p>
    <p>
      <strong
        >19.10 <em>Jury Trial Waiver</em>. You and Fingaz
        acknowledge and agree that we are each waiving the
        right to a trial by jury as to all arbitrable
        Disputes.</strong
      >
    </p>
    <p>
      <strong
        >19.11
        <em
          >No Class Actions or Representative
          Proceedings</em
        >. You and Fingaz acknowledge and agree that, to the
        fullest extent permitted by law, we are each waiving
        the right to participate as a plaintiff or class
        member in any purported class action lawsuit,
        class-wide arbitration, private attorney general
        action, or any other representative proceeding as to
        all Disputes. Further, unless you and Fingaz both
        otherwise agree in writing, the arbitrator may not
        consolidate more than one party’s claims and may not
        otherwise preside over any form of any class or
        representative proceeding. If the “class action
        lawsuit” waiver or the “class-wide arbitration”
        waiver in this Section 19.11 is held unenforceable
        with respect to any Dispute, then the entirety of
        the Arbitration Agreement will be deemed void with
        respect to such Dispute and the Dispute must proceed
        in court. If the “private attorney general action”
        waiver or the “representative proceeding” waiver in
        this Section 19.11 is held unenforceable with
        respect to any Dispute, those waivers may be severed
        from this Arbitration Agreement and you and Fingaz
        agree that any private attorney general claims and
        representative claims in the Dispute will be severed
        and stayed, pending the resolution of any arbitrable
        claims in the Dispute in individual
        arbitration.</strong
      >
    </p>
    <p>
      19.12 <em>Severability</em>. Except as provided in
      Section 19.11, in the event that any portion of this
      Arbitration Agreement is deemed illegal or
      unenforceable, such provision shall be severed and the
      remainder of the Arbitration Agreement shall be given
      full force and effect.
    </p>
    <p>
      19.13 <em>Changes</em>. Notwithstanding the provisions
      of Section 3 (“Modification of these Terms”), if
      Fingaz changes this Section 19 (“Dispute Resolution
      and Arbitration Agreement”) after the date you last
      accepted these Terms (or accepted any subsequent
      changes to these Terms), you may reject any such
      change by sending us written notice (including by
      email) within thirty (30) days of the date such change
      became effective, as indicated in the “Last Updated”
      date above or in the date of Fingaz’s email to you
      notifying you of such change. Rejecting a new change,
      however, does not revoke or alter your prior consent
      to any earlier agreements to arbitrate any Dispute
      between you and Fingaz (or your prior consent to any
      subsequent changes thereto), which will remain in
      effect and enforceable as to any Dispute between you
      and Fingaz.
    </p>
    <p>
      19.14 <em>Survival</em>. Except as provided in Section
      19.12 and subject to Section 15.8, this Section 19
      will survive any termination of these Terms and will
      continue to apply even if you stop using the Fingaz
      Platform or terminate your Fingaz Account.
    </p>
    <h4><a id="sec201910_20"></a>20. Feedback</h4>
    <p>
      We welcome and encourage you to provide feedback,
      comments and suggestions for improvements to the
      Fingaz Platform (“<strong>Feedback</strong>“). You may
      submit Feedback by emailing us, through the
      <a routerLink="">“Contact”</a> section of the Fingaz
      Platform, or by other means of communication. Any
      Feedback you submit to us will be considered
      non-confidential and non-proprietary to you. By
      submitting Feedback to us, you grant us a
      non-exclusive, worldwide, royalty-free, irrevocable,
      sub-licensable, perpetual license to use and publish
      those ideas and materials for any purpose, without
      compensation to you.
    </p>
    <h4>
      <a id="sec201910_21"></a>21. Applicable Law and
      Jurisdiction
    </h4>
    <p>
      21.1 If your country of residence or establishment is
      the United States, these Terms will be interpreted in
      accordance with the laws of the State of California
      and the United States of America, without regard to
      conflict-of-law provisions. Judicial proceedings
      (other than small claims actions) that are excluded
      from the Arbitration Agreement in Section 19 must be
      brought in state or federal court in San Francisco,
      California, unless we both agree to some other
      location. You and we both consent to venue and
      personal jurisdiction in San Francisco, California.
    </p>
    <p>
      21.2 If your country of residence or establishment is
      China these Terms will be interpreted in accordance
      with the laws of China (“<strong>China Laws</strong
      >”). Any dispute arising from or in connection with
      this Agreement shall be submitted to the China
      International Economic and Trade Arbitration
      Commission (CIETAC) for arbitration in Beijing which
      shall be conducted in accordance with CIETAC’s
      arbitration rules in effect at the time of applying
      for arbitration, provided that this section shall not
      be construed to limit any rights which Fingaz may have
      to apply to any court of competent jurisdiction for an
      order requiring you to perform or be prohibited from
      performing certain acts and other provisional relief
      permitted under China Laws or any other laws that may
      apply to you. The arbitration proceedings shall be
      conducted in English. The arbitral award rendered is
      final and binding upon both parties.
    </p>
    <p>
      21.3 If your country of residence or establishment is
      outside of the United States and China, these Terms
      will be interpreted in accordance with Irish law. The
      application of the United Nations Convention on
      Contracts for the International Sale of Goods (CISG)
      is excluded. The choice of law does not impact your
      rights as a consumer according to the consumer
      protection regulations of your country of residence.
      If you are acting as a consumer, you agree to submit
      to the non-exclusive jurisdiction of the Irish courts.
      Judicial proceedings that you are able to bring
      against us arising from or in connection with these
      Terms may only be brought in a court located in
      Ireland or a court with jurisdiction in your place of
      residence. If Fingaz wishes to enforce any of its
      rights against you as a consumer, we may do so only in
      the courts of the jurisdiction in which you are a
      resident. If you are acting as a business, you agree
      to submit to the exclusive jurisdiction of the Irish
      courts.
    </p>
    <h4><a id="sec201910_22"></a>22. General Provisions</h4>
    <p>
      22.1 Except as they may be supplemented by additional
      terms and conditions, policies, guidelines or
      standards, these Terms constitute the entire Agreement
      between Fingaz and you pertaining to the subject
      matter hereof, and supersede any and all prior oral or
      written understandings or agreements between Fingaz
      and you in relation to the access to and use of the
      Fingaz Platform.
    </p>
    <p>
      22.2 No joint venture, partnership, employment, or
      agency relationship exists between you and Fingaz as a
      result of this Agreement or your use of the Fingaz
      Platform.
    </p>
    <p>
      22.3 These Terms do not and are not intended to confer
      any rights or remedies upon any person other than the
      parties.
    </p>
    <p>
      22.4 If any provision of these Terms is held to be
      invalid or unenforceable, such provision will be
      struck and will not affect the validity and
      enforceability of the remaining provisions.
    </p>
    <p>
      22.5 Fingaz’s failure to enforce any right or
      provision in these Terms will not constitute a waiver
      of such right or provision unless acknowledged and
      agreed to by us in writing. Except as expressly set
      forth in these Terms, the exercise by either party of
      any of its remedies under these Terms will be without
      prejudice to its other remedies under these Terms or
      otherwise permitted under law.
    </p>
    <p>
      22.6 You may not assign, transfer or delegate this
      Agreement and your rights and obligations hereunder
      without Fingaz's prior written consent. Fingaz may
      without restriction assign, transfer or delegate this
      Agreement and any rights and obligations hereunder, at
      its sole discretion, with 30 days prior notice. Your
      right to terminate this Agreement at any time remains
      unaffected.
    </p>
    <p>
      22.7 Unless specified otherwise, any notices or other
      communications to Members permitted or required under
      this Agreement, will be provided electronically and
      given by Fingaz via email, Fingaz Platform
      notification, or messaging service (including SMS and
      WeChat). If your booking is in respect of a Listing in
      Japan, you agree and acknowledge that such
      notifications via electronic means in lieu of a
      written statement, satisfies Fingaz’s obligations
      under Article 59 Paragraph 1 of the Japanese Housing
      Accommodation Business Act.
    </p>
    <p>
      22.8 If you have any questions about these Terms
      please <a>email us admin@fingaz.app</a>.
    </p>
  </div>
</div>
