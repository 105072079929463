<div *ngLet="util.storey.all_db as db">
  <div
    [style.background]="'url(' + db.games[slot.game].image + ')'"
    class="pt-4 book-image m-1"
  >
    <p class="fw-7 m-0">{{ db.games[slot.game].name }}</p>
    <p class="fw-4">{{db.consoles[slot.console].name}}</p>
  </div>

  <div class="conty bg-bg2 p-2">
    <ng-container
      *ngFor="let d of slot.schedules  | keyvalue"
    >
      <h5>{{+d.key | dayF : " PPPP "}}</h5>
      <ng-container *ngFor="let t of d.value | keyvalue">
        <button
          class="bg-bg1 h4 csw brad m-2 px-4 py-3"
          [ngClass]="{'bob-c0': curr_times[d.key]?.[t.key] || t.value.title !== 'Open'}"
          (click)="timeClick(d.key, t)"
          [disabled]="is_booking || t.value.title !== 'Open' "
        >
          <span class="fw-7"
            >{{t.value.id | dayF : " h aa"}}</span
          >
        </button>
      </ng-container>
      <div class="bo-bg4 m-4"></div>
    </ng-container>
  </div>
  <p class="my-2">
    <button
      class="mt-1 bg-c0 bg1 brad2 p-3 fw-8 booky"
      (click)="bookCheck(buyer)"
      [disabled]="total_time < 1"
    >
      <ng-container *ngIf="!is_booking">
        <img
          class="bradd mb-1 mr-1 bo3-b2"
          [style.width.em]="1.2"
          [style.height.em]="1.2"
          src="/assets/fingz.png"
          alt=""
        />
        {{ slot.cost * total_time }} &nbsp; &nbsp; BOOK
      </ng-container>
      <span
        class="spinner-border spinner-border-sm mx-5"
        role="status"
        *ngIf="is_booking"
      >
        <span class="sr-only">Loading...</span>
      </span>
    </button>
  </p>
</div>
