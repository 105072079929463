<navbar></navbar>
<div class="main_content" #mainContent>
  <ng-container>
    <router-outlet></router-outlet>
  </ng-container>
</div>

<feeter></feeter>

<div *ngIf="lander" class="lander bg-bg2">
  <div class="fgam m-0">
    <div class="row py-4">
      <div class="col-12 col-md-6 icol">
        <img
          class="mimg fimg"
          src="/assets/land1.png"
          alt=""
        />
      </div>
      <div class="col-12 col-md-6 itexr text-center">
        <p class="mx-4 b4 my-3 display-3 fw-7 spa2">
          <img
            width="80rem"
            src="/assets/logo3.png"
            alt=""
          />
          <span class="ttex">ingaz</span>
        </p>
        <p class="h2 b4 fw-6 mx-5 my-2">
          Find and Play High Quality Games Near You <br />
          <span class="b2 fw-8 display-3">NOW !!!</span>
        </p>
        <button
          class="my-3 bo3-b4 brad3 px-5 py-4 bg-b2 fw-8 cmain mx-5 h3"
          routerLink="/slots"
        >
          <i class="fas fa-gamepad"></i> &nbsp; PLAY
        </button>
      </div>
    </div>
  </div>
  <div class="ftor m-0">
    <div class="row">
      <div class="col-12 col-md-6 py-4 text-center">
        <p class="display-4 cw fw-6 mx-5 my-4">
          Join Gaming Events & <br />
          Make New Friends
        </p>
        <button
          class="my-3 bo3-cw brad3 px-5 py-4 cckk fw-8 cw mx-5 h2"
          routerLink="/parties"
        >
          <i class="fas fa-glass-cheers"></i> &nbsp; PARTY
        </button>
      </div>
      <div class="col-12 col-md-6 icol">
        <img
          class="mimg timg"
          src="/assets/land2.png"
          alt=""
        />
      </div>
    </div>
  </div>
  <div class="fshop m-0">
    <div class="row">
      <div class="col-12 col-md-6 icol">
        <img class="mimg" src="/assets/land3.png" alt="" />
      </div>
      <div class="col-12 col-md-6 py-4 text-center">
        <p class="display-4 cw fw-6 mx-5 my-4">
          Buy Games, Cards, and Gear
        </p>
        <button
          class="my-3 bo3-cw brad3 px-5 py-4 bg-ccb cw mx-5 fw-8 h2"
          routerLink="/shop"
        >
          <i class="fas fa-shopping-cart"></i> &nbsp; SHOP
        </button>
      </div>
    </div>
  </div>
  <div class="fhost text-center p-5">
    <div class="container cw fw-7">
      <p class="h3">
        Do you have High Quality Gaming Equipment ?
      </p>
      <p class="h3">
        Do you have a comfortable space to host Gaming ?
      </p>
      <p class="h3">Is it open to the public?</p>

      <p class="display-4 cw fw-6 mt-4">
        BECOME A FINGAZ HOST AND EARN CASH
      </p>
      <button
        class="my-3 bo3-cw brad3 px-5 py-4 ccpp cw mx-5 fw-8 h2"
        routerLink="/host"
      >
        <i class="fas fa-landmark"></i> &nbsp; HOST
      </button>
    </div>
  </div>
  <div class="bg-b3 m-0 py-4">
    <div class="container py-4">
      <h3 class="display-3 text-center my-4 fw-6 cmain">
        FAQ
      </h3>
      <div class="m-3">
        <div class="mx-2">
          <mat-accordion>
            <mat-expansion-panel
              *ngFor="let faq of util.storey.all_db.faqs  | keyvalue | orderBy:'value.priority' ; let i = index"
              class="my-3 brad2 bg-b1 p-4 bo3-b4"
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="fw-6 h3 cmain">
                  {{ faq.value.question}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p class="cw h5">{{ faq.value.answer }}</p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-b1 m-0 py-4 feet">
    <div class="bottomrow text-center mt-3">
      <!-- * APP ICONS -->
      <p class="my-4">
        <img
          class="poi shadow bo3-b4 brad"
          width="140px"
          src="/assets/ios.png"
          alt=""
        />
        &nbsp;
        <img
          class="poi shadow bo3-b4 brad"
          width="140px"
          src="/assets/android.png"
          alt=""
        />
      </p>
      <!-- * SOCIAL ICONS  -->
      <div
        class="social px-3 pt-2 pb-0 brad2 bo3-b4 bg-b0 shadow-sm wi3 my-3"
      >
        <a
          *ngFor="let s of socials"
          [href]="s.href"
          target="_blank"
        >
          <img [src]="s.image" alt=""
        /></a>
        <p></p>
      </div>
      <!-- * COPYRIGHT  -->
      <div class="text-center mt-4 mb-5 pb-5">
        <logo [size]="60"></logo>
        <h5 class="cmain fw-5 my-1">&copy; 2022</h5>
        <h6 class="cmain fw-5 my-1">{{version}}</h6>
      </div>
    </div>
  </div>
</div>
