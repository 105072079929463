<h3 class="ccp mt-5 display-4 fw-5 text-center">
  Complete your Host Verification
</h3>

<div class="text-center">
  <ng-container>
    <h3 *ngIf="host.verified === 'false'" class="my-3 fw-4">
      Click the button below to schedule an inspection.
    </h3>

    <h3
      *ngIf="host.verified === 'pending'"
      class="my-3 fw-4"
    >
      Your verification is pending. Your host account will
      be ready soon.
    </h3>

    <h4>
      <button
        *ngIf="host.verified === 'false'"
        (click)="verify()"
        class="px-4 brad3 bg-bg1 ccp shadow px-5 py-4 my-4 h3 fw-5"
      >
        <i class="fas fa-search-location"></i> &nbsp; Book
        Inspection
      </button>

      <button
        *ngIf="host.verified === 'pending'"
        class="px-4 brad3 bg-bg1 ccp shadow px-5 py-4 my-4 h3 fw-5"
      >
        <i class="fas fa-pause-circle"></i> &nbsp;
        Verification Pending
      </button>
    </h4>
  </ng-container>
</div>
