import { DOCUMENT } from "@angular/common";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavigationEnd } from "@angular/router";
import { mo_social } from "@models/social.mo";

import { Utilities } from "@services/utilities";

@Component({
  selector: "fingaz",
  templateUrl: "./fingaz.c.html",
  styleUrls: ["./fingaz.c.scss"],
})
export class Fingaz implements OnInit {
  lander = false;
  theme;
  loaded = false;
  version = this.util.snappy().version;

  socials = mo_social;

  @ViewChild("mainContent")
  private mainContentDiv!: ElementRef<HTMLElement>;

  constructor(
    public util: Utilities,
    private el: ElementRef,
    @Inject(DOCUMENT) private _document
  ) {}

  ngOnInit() {
    this.util.brk
      .observe(["(max-width: 980px)"])
      .subscribe((x) => {
        this.util.setMobile(x.matches);
      });
    this.routeListen();
    this.util.setIsConnected(
      localStorage.getItem("user") !== null
    );
    this.util.afa.user.subscribe(async (user) => {
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
        JSON.parse(localStorage.getItem("user")!);
        this.util.setLoggedInUser(
          JSON.parse(JSON.stringify(user))
        );
        this.syncUser(user);
        this.util.setClaims(true);
      } else {
        localStorage.setItem("user", "null");
        this.util.setClaims(false);
        JSON.parse(localStorage.getItem("user")!);
        this.util.setLoggedInUser(null);
        await this.util.sto.dispatch(
          new this.util.axs.ClearState()
        );
        // this.util.router.navigate(["/"]);
      }
    });
    this.util.selly().subscribe((x) => {
      this.util.setStorey(x);

      if (x.all_db) {
        this.util.getSlots(x.all_db);
      }
      let old_class = x.theme === "dark" ? "light" : "dark";

      this._document.body.classList.remove(old_class);
      this._document.body.classList.add(x.theme);
    });
    this.versionize();
    this.getDB();
  }

  async syncUser(user) {
    if (user) {
      let udb: any = await this.util.readLink(
        `dbz/gamers/${user?.uid}`
      );
      if (udb === null) {
        udb = {
          uid: user.uid,
          username: user.uid.slice(0, 8),
          is_host: false,
          verified: false,
        };
        await this.util.afd
          .object(`dbz/gamers/${user.uid}`)
          .update(udb);
      }

      await this.util.sto.dispatch(
        new this.util.axs.UpdateUserDB({
          ...udb,
        })
      );

      if (udb.is_host) {
        let hdb: any = await this.util.readLink(
          `dbz/hosts/${udb.uid}`
        );
        await this.util.sto.dispatch(
          new this.util.axs.SetHost({
            ...hdb,
          })
        );
      } else {
        await this.util.sto.dispatch(
          new this.util.axs.SetHost(null)
        );
      }
    }
  }

  // *  V E R S I O N I N G   *****************
  async versionize() {
    let version = this.util.snappy().version;
    if (version !== "1.0.50") {
      this.util.signOut();
    }
  }
  // *  V E R S I O N I N G   *****************

  async getDB() {
    this.util.subLink("/dbz").subscribe(async (db) => {
      this.util.getSlots(db);
      this.util.setDbz(db);
      let uu = await this.util.afa.currentUser;
      this.syncUser(uu);
    });
  }

  routeListen() {
    this.util.router.events.subscribe((x) => {
      (
        this.mainContentDiv.nativeElement as HTMLElement
      ).scrollTop = 0;

      if (x instanceof NavigationEnd) {
        // this.lander = x.url === "/";

        this.util.sto.dispatch(
          new this.util.axs.UpdateRoute(x)
        );
      }
    });
  }
}
