import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngxs/store";
import filter from "lodash/filter";

@Pipe({
  name: "sieves",
})
export class SievePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(list: any, sieve?: any) {
    let sieved = list;
    if (sieve) {
      sieved = filter(list, { host: sieve });
    }
    return sieved;
  }
}

@Pipe({ name: "shuffle" })
export class RandomOrderPipe implements PipeTransform {
  transform(listmap): Array<any> {
    const newList = Object.values(listmap);
    newList.sort(() => Math.random() - 0.5);
    return newList;
  }
}
