<div class="conty bg-bg3 mt-1 mb-2 pt-3">
  <h5 class="ccp fw-7">
    Set the Prices for each Game per hour
  </h5>
  <div class="divy p-2">
    <ng-container *ngIf="console">
      <button
        class="coimg cowi2 bg-bg1 box2 csw brad m-2 p-0"
        *ngFor="let g of console.games | keyvalue"
      >
        <img
          class="im3"
          [src]="util.storey.all_db.games[g.key].image"
          [style.object-position]="util.storey.all_db.games[g.key].css_class ?? 'top'"
          alt=""
        />
        <h6 class="m-2 shadow-sm bg-bg2 brad p-2">
          <span (click)="util.show(keys)" class="fw-7 h4"
            >$</span
          >
          &nbsp;
          <input
            class="inp csw fw-6 bg-bg2"
            type="number"
            [(ngModel)]="dollar_prices[g.key]"
            [placeholder]="util.hostFingz2Cash(util.storey.all_db.games[g.key].consoles[keys[1]])"
          />
        </h6>
      </button>
    </ng-container>
  </div>
</div>

<div class="my-2">
  <button
    *ngIf="!pr_up"
    class="mx-3 brad2 px-3 py-2 bg-ccp bg1 bo3-bg4"
    (click)="updatePrices()"
  >
    UPDATE
  </button>
  <button
    *ngIf="pr_up"
    class="mx-3 brad2 px-3 py-2 bg-ccp bg1 bo3-bg4"
  >
    <span
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </span>
  </button>
</div>
