<div class="p-2 text-center">
  <p>
    <input
      class="searcher shadow brad m-4 csw p-3"
      type="text"
      [(ngModel)]="searcher"
      placeholder="Search"
    />
  </p>

  <ng-container
    *ngFor="let u of util.map2Arr(util.storey.all_db.gamers, 2) | searchFilter: searcher"
  >
    <div class="itemy brad shadow-sm bg-bg1 csw p-2">
      <img
        width="80%"
        [src]="u.image || '/assets/profile.png'"
        alt=""
      />
      <p class="text-truncate fs07">@{{u.username}}</p>
      <p class="text-truncate fs07">{{u.uid}}</p>
    </div>
  </ng-container>
</div>
