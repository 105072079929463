<div class="p-2 text-center">
  <p class="justify-content-center d-flex">
    <input
      class="searcher shadow brad mx-2 csw p-3"
      type="text"
      [(ngModel)]="searcher"
      placeholder="Search"
    />
    &nbsp;
    <button class="brad bg-bg1 p-3 csw" (click)="synk()">
      SYNC DATA
    </button>
    &nbsp;
    <button class="brad bg-bg1 p-3 csw" (click)="synki()">
      SYNC IMAGES
    </button>
  </p>

  <mat-tab-group mat-align-tabs="center" *ngIf="shop">
    <mat-tab *ngFor="let m of shop_menu">
      <ng-template mat-tab-label>
        <span class="bg-bg1 brad box2 px-3 py-2 csw"
          >{{m.name}}</span
        >
      </ng-template>

      <p>
        <input
          class="brad px-3 py-2 csw box2"
          type="text"
          placeholder="ASIN"
          #new_asin
        />
        &nbsp;
        <button
          (click)="addPro(m.fire, new_asin.value)"
          class="brad bg-bg1 p-2 csw"
        >
          ADD
        </button>
        &nbsp;
        <button
          (click)="moSync(m.fire)"
          class="brad bg-bg1 p-2 csw"
        >
          MOSYNC
        </button>
      </p>
      <ng-container
        *ngFor="let sh of getArray(shop[m.fire])"
      >
        <div
          class="itemy itemy2 brads shadow-sm bg-bg1 csw p-1"
        >
          <p class="fs07">{{sh.ASIN}}</p>
          <img
            class="brads"
            width="100%"
            height="100px"
            [src]="sh.images[0] || '/assets/robot.png'"
            (dblclick)="util.show(sh)"
          />
          <p class="text-truncate fs07 m-0">{{sh.title}}</p>
          <p class="text-truncate ccg fw-7 m-0">
            $ {{sh.price}}
          </p>

          <p class="text-truncate c0 fw-7 m-0">
            <img
              class="mb-1 mr-1"
              [style.width.px]="15"
              [style.height.px]="15"
              src="/assets/fingz.png"
              alt=""
            />
            {{fingy(sh.price)}}
          </p>
        </div>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</div>
