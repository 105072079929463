<div
  *ngLet="util.getId(util.storey.router.url) as hid"
  class="p-4"
>
  <div *ngLet="util.storey.all_db as db">
    <div *ngLet="db.hosts[hid] as ho" class="text-center">
      <!-- <img
        (click)="util.show(pr)"
        class="brad"
        [src]="pr.images[0]"
        alt=""
      /> -->
      <h5 (click)="util.show(ho)" class="my-3">
        {{ho.id}}
      </h5>
      <h3>
        {{ho.locations[0].city}},
        {{ho.locations[0].country}}
      </h3>
    </div>
  </div>
</div>
