<div class="p-2">
  <p class="text-center">
    <input
      class="searcher shadow brad m-4 csw p-3"
      type="text"
      [(ngModel)]="searcher"
      placeholder="Search"
    />

    <button
      (click)="util.dlogtr(new_slot)"
      class="bg-bg1 c0 fs2 li0 px-3 py-4 brad mx-2"
    >
      +
    </button>

    <button
      (click)="synk(slots)"
      class="bg-bg1 c0 fs2 li0 px-3 py-4 brad mx-2"
    >
      ⌘
    </button>

    <button
      (click)="zi()"
      class="bg-bg1 c0 fs2 li0 px-3 py-4 brad mx-2"
    >
      ⎆
    </button>
  </p>

  <div class="agridy">
    <div
      *ngFor="let so of util.storey.all_db.dummy.slot_map | keyvalue | searchFilter: searcher"
      class="zitem brads shadow-sm bg-bg1 csw"
      [ngClass]="{'bo3-c0': swx[0][0] === so.key, 'bo3-cck' : swx[1][0] === so.key, 'bo3-ccr' : chi(so.value.images[0])}"
      (dblclick)="edy(so.value, so.key); util.dlogtr(new_slot)"
    >
      <ng-container *ngLet="so.value as s">
        <swiper
          [slidesPerView]="1"
          [pagination]="true"
          [navigation]="true"
          [centeredSlides]="true"
          effect="fade"
          [thumbs]="{ swiper: thum }"
          class="my-swiper"
        >
          <ng-template
            swiperSlide
            *ngFor="let scr of s.images"
          >
            <img
              width="100%"
              class="brads"
              [src]="scr"
              alt=""
            />
          </ng-template>
        </swiper>
        <p class="text-truncate fs07">{{s.host_name}}</p>
        <p class="text-truncate fs07">{{s.console}}</p>
        <p class="text-truncate fs07">{{s.location}}</p>
        <p class="text-truncate fs07">{{s.price}}/hr</p>
        <p>
          <button (click)="sel(so, 0)" class="m-2 p-2">
            1
          </button>
          <button (click)="sel(so, 1)" class="m-2 p-2">
            2
          </button>
        </p>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #new_slot>
  <div
    class="position-relative p-4 brad bg-bg1 bo3-bg4 wim text-center"
  >
    <span matDialogClose class="h3 ccr poi exiter"
      ><i class="fas fa-times-circle"></i
    ></span>
    <div>
      <form class="">
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            type="text"
            name="host_name"
            placeholder="Host Name"
            [(ngModel)]="eds.host_name"
          />
        </p>

        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            type="text"
            name="console"
            placeholder="Console"
            [(ngModel)]="eds.console"
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            type="text"
            name="location"
            placeholder="Location"
            [(ngModel)]="eds.location"
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            type="text"
            name="price"
            placeholder="Price"
            [(ngModel)]="eds.price"
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            type="text"
            name="rating"
            placeholder="Rating"
            [(ngModel)]="eds.rating"
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            type="text"
            name="img"
            placeholder="Image"
            [(ngModel)]="eds.images[0]"
            (keyup.enter)="upD()"
          />
        </p>
        <img width="60%" [src]="eds.images[0]" alt="" />
      </form>
      <p>
        <button
          (click)="upD()"
          class="px-3 py-2 my-3 h5 brad2 bg-csw bg1"
        >
          ADD
        </button>
      </p>
    </div>
  </div>
</ng-template>
