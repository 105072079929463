<div
  *ngLet="util.storey.all_db as db "
  class="container-xl pt-3 px-2"
>
  <ng-container
    *ngLet="util.storey.user.purchases as buys "
  >
    <div class="slotlist">
      <ng-container *ngIf="!(util.lo_isEmpty(buys))">
        <div
          *ngFor="let buy of buys | keyvalue"
          class="slotcard"
        >
          {{buy | json}}
        </div>
      </ng-container>
    </div>
    <div
      *ngIf="util.lo_isEmpty(buys)"
      class="p-4 text-center"
    >
      <h3 class="csw">You have no purchases</h3>
      <button
        class="bg-ccb bg1 px-4 py-3 m-2 brad2 poi fw-7 h3"
        routerLink="/shop"
      >
        SHOP NOW
      </button>
    </div>
  </ng-container>
</div>
