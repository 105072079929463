import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "p-party",
  templateUrl: "./p-party.html",
  styleUrls: ["./p-party.scss"],
})
export class ProfileParty {
  constructor(public util: Utilities) {}
}
