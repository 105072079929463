export const roadmaps = [
  {
    title: "Create Fingaz Web3 Dapp Frontend",
    content:
      "Fingaz Web3 Frontend with Angular Web Framework",
    mode: "Done",
  },
  {
    title: "Deploy FINGZ TOKEN",
    content:
      "FINGZ ERC20 tokens deployed on Binance Smart Chain Mainnet and Binance Smart Chain Fuji Testnet",
    links: [
      {
        url: "https://fingaz.app",
        name: "Mainnet Contract Address",
      },
      {
        url: "https://fingaz.app",
        name: "Testnet Contract Address",
      },
    ],
    mode: "Done",
  },
  {
    title: "Deploy FINGAZ SMART CONTRACT",
    content:
      "The Fingaz Smart Contract functions as an escrow to handle booking sessions and party participation and winnings",
    links: [
      {
        url: "https://fingaz.app",
        name: "Testnet Contract Address",
      },
    ],
    mode: "Done",
  },
  {
    title: "Setup Fingaz Discord Channel",
    content:
      "Fingaz uses Discord to handle its support and communications. Join the Server using the link below",
    mode: "Done",
    links: [
      {
        url: "https://discord.gg/xnPHVBQ9gJ",
        name: "Discord Channel",
      },
    ],
  },
  {
    title: "Connect Moralis Auth",
    content:
      "Fingaz uses Moralis to authenticate it's users via thier Binance Smart Chain wallets",
    mode: "Done",
    links: [
      {
        url: "https://moralis.io/",
        name: "Visit Moralis ",
      },
    ],
  },
  {
    title: "Connect Moralis Database and Cloud",
    content:
      "Fingaz will user Moralis Cloud and Database to handle its Off-Chain Data operations",
    mode: "Done",
  },
  {
    title: "Deploy Fingaz Party Smart Contract",
    content:
      "Fingaz Parties are Decentralized Gaming Parties where players can join with an entry fee and compete to win prizes determined by the Party Host",
    mode: "Progress",
  },
  {
    title: "Setup Host Account and Host KYC",
    content:
      "Fingaz Hosts will create spaces, games and devices for gamers to use at a cost in FINGZ. Hosts will need to go through a verification process to verify their host setup and personalites. ",
    mode: "Progress",
  },
  {
    title: "Deploy Mobile Apps",
    content:
      "Fingaz mobile apps will be deployed for Android and iOS",
    mode: "Progress",
  },
  {
    title: "Player  KYC",
    content:
      "Players will need to go through a Verification process so Fingaz can be a safe place for both Hosts and Players alike. ",
    mode: "Pending",
  },
  {
    title: "FINGZ Private Sale Offer/ ICO",
    content:
      "The Fingaz Platform runs on FINGZ Tokens. Tokens will be issued out to the first adopters in a private sale. Thereafter an ICO will be raised for a mass sale of Fingaz Tokens",
    mode: "Pending",
    links: [
      {
        url: "https://fingz.fingaz.app/",
        name: "FINGZ ICO Page ",
      },
    ],
  },
];
