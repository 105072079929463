<div *ngLet="util.storey.all_db as db" class="con-fin">
  <div class="ctr mb-2">.</div>
  <div class="frow">
    <div *ngFor="let eve of a_events" class="fcol csw poi">
      <div class="eveslide brad shadow-sm">
        <span class="evshare">
          <i class="fas fa-share-square csw"></i>
        </span>
        <img class="brad" [src]="eve.img" alt="" />
        <div class="px-3 py-2">
          <p class="fw-7 my-1 h5">{{eve.name}}</p>
          <p class="fw-7 cck my-1">{{eve.date}}</p>
          <p class="fw-3 my-1 fs07">{{eve.location}}</p>
          <p class="fw-8 my-1">{{eve.price}}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div>
    <ng-container
      *ngLet="getParties(db.parties, util.storey.filters.party) as pax"
    >
      <div *ngIf="!empty_search" class="mt-2 slotlist">
        <div
          *ngFor="let party of pax | searchFilter: util.searchy.party"
          class="slotcard"
        >
          <div
            class="brad overflow-hidden shadow-sm text-center p-0 bg-bg1 csw bo-bg4"
          >
            <img
              class="image"
              [src]="db.games[party.game].image"
              [style.object-position]="db.games[party.game].css_class ?? 'top'"
              (dblclick)="util.show(to)"
            />
            <p class="fs8 mm1 text-truncate px-2 fw-7">
              {{ db.games[party.game].name }}
            </p>
            <p class="fw-7 mm1 fs9">
              <img
                class="mb-1 mr-1"
                [style.width.px]="15"
                [style.height.px]="15"
                src="/assets/fingz.png"
                alt=""
              />
              {{ party.entry_fingz }}
            </p>
            <p class="mm1 fw-6 ccr">
              <i class="fas fa-stopwatch"></i> &nbsp;
              {{party.deadline | dayF : "MMM do y" }}
            </p>
            <p class="mm1 fw-7 fs8">
              {{util.map2Arr(party.gamers, 1).length ?? 0}}
              / {{party.players}} players
            </p>
            <p
              class="bg-cck bg1 px-2 py-2 m-2 brad2 poi fw-7 fs8"
              (click)="util.singleLink('parties',party.id)"
            >
              <span> Join Party </span>
              &nbsp; &nbsp;
              <i class="fas fa-glass-cheers"></i>
            </p>
          </div>
        </div>
      </div>
      <div
        class="text-center csw mx-auto"
        *ngIf="empty_search"
      >
        <h4 class="mt-4">NO RESULTS</h4>
        <h6 class="fw-3">Change search and filters</h6>
      </div>
    </ng-container>
  </div> -->
</div>
