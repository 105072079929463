import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "eula",
  templateUrl: "./eula.html",
  styles: [""],
})
export class EULA implements OnInit {
  @Input() reader = false;
  fromMobile = false;

  ngOnInit() {
    this.fromMobile = window.location.search.includes("mob");
  }
}
