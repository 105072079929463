import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "h-bookings",
  templateUrl: "./h-bookings.html",
  styleUrls: ["../hosts.scss"],
})
export class HostBookings implements OnInit {
  uid = this.util.storey.user_db.uid;
  curr_date = 0;
  bks = [];
  curr_chat;

  constructor(public util: Utilities) {}

  ngOnInit(): void {
    let zzz = this.util.storey.host_db.bookings;
    console.log(zzz);

    let bk_map = {};
    if (zzz) {
      Object.entries(zzz).forEach((b: any) => {
        let jj = JSON.parse(b[1].schedule);
        Object.keys(jj).forEach((j) => {
          bk_map[j] = {
            gamer: b[0].substring(0, b[0].indexOf("|")),
            pay_key: b[0],
            day: +j.substring(0, j.indexOf("|")),
            time: j.substring(j.indexOf("|") + 1),
          };
        });
      });
    }

    this.bks = Object.entries(bk_map);
  }

  getHost(h) {
    let hid = h
      .toLowerCase()
      .trim()
      .replace(/[\W_]+/g, "_");
    return this.util.storey.all_db.dummy.slot_map[hid];
  }

  openChat(bd, day, ti, pay_k, ch_k, temp) {
    this.curr_chat = [
      bd.data.host_name,
      this.util.dpipe.transform(day, "EEE"),
      ti,
      pay_k,
      ch_k,
      bd.data.uid,
    ];
    this.util.dlogtr(temp);
    this.scro();
  }

  async scro() {
    let ele = document.getElementById("chatb");
    await new Promise((r) => setTimeout(r, 100));
    ele.scrollTop = ele.scrollHeight;
  }

  chatSend(t) {
    if (t.value !== "") {
      this.util.afd
        .object(
          `dbz/gamers/${this.curr_chat[5]}/payments/${this.curr_chat[3]}/chat/${this.curr_chat[4]}`
        )
        .update({
          [new Date().getTime()]: ["host", t.value],
        });
      this.scro();
    }
  }
}
