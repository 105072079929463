import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "h-finances",
  templateUrl: "./h-finances.html",
  styleUrls: ["../hosts.scss"],
})
export class HostFinances implements OnInit {
  // furl = "http://localhost:5201"
  furl = "https://api.techceed.org:5201";
  str_load = false;
  pending_bal = 0;
  available_bal = 0;

  constructor(public util: Utilities) {}

  ngOnInit(): void {
    // this.getBal();
  }

  getBal(bk) {
    let ball = [0, 0];
    if (bk) {
      Object.values(bk).forEach((b: any) => {
        if (b.status === "booked") {
          ball[0] += b.earn;
        } else if (b.status === "completed") {
          ball[1] += b.earn;
        }
      });
    }

    return ball;
  }

  async stripeSetup() {
    this.str_load = true;
    let stripe_acc_no = this.util.storey.host_db.stripe_no;
    console.log(stripe_acc_no);
    if (stripe_acc_no) {
      if (this.util.storey.host_db.stripe_active) {
        console.log("Active");
      } else {
        console.log("Onboard");

        let onboard: any = await firstValueFrom(
          this.util.http.post(`${this.furl}/honboard`, {
            account: stripe_acc_no,
            refresh_url: window.location.href,
            return_url: window.location.href,
          })
        );
        console.log(onboard);
        window.open(onboard.url, "_self");
        this.str_load = false;
      }
    } else {
      let setup: any = await firstValueFrom(
        this.util.http.post(`${this.furl}/hsetup`, {
          h_email: this.util.storey.user.email,
          b_type: "individual",
        })
      );
      console.log(setup);
      this.util.afd
        .object(`dbz/hosts/${this.util.storey.host_db.id}`)
        .update({
          stripe_no: setup.id,
        });
      this.str_load = false;
    }
  }

  async openStripe() {
    let stripe_acc_no = this.util.storey.host_db.stripe_no;

    let stripe_login: any = await firstValueFrom(
      this.util.http.post(`${this.furl}/hlogin`, {
        account_no: stripe_acc_no,
      })
    );
    console.log(stripe_login);
    window.open(stripe_login.url, "_blank");
  }
}
