import { Injectable } from "@angular/core";
import { FormGroup, AbstractControl } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class Validor {
  confirmPass(group: FormGroup) {
    let pass = group.get("password").value;
    let confirmPass = group.get("passwordConfirm").value;

    return pass === confirmPass ? null : { samePass: true };
  }

  unamer(control: AbstractControl) {
    let uname = control.value;
    let lenny = uname.length < 5 || uname.length > 15;
    let charry = RegExp("^[A-Za-z0-9_]*$").test(uname);
    if (lenny || !charry) {
      return { invalid: true };
    }
    return null;
  }
}
