import { Pipe, PipeTransform } from "@angular/core";
import { format } from "date-fns";

@Pipe({
  name: "dayS"
})
export class StringDatePipe implements PipeTransform {
  constructor() {}

  transform(string: any, formats: any) {
    let date = new Date(string);
    return format(date, formats);
  }
}
