export const fingz_tiers = [
  {
    fingz: 1000,
    dollar: 9.99,
    title: "basic",
    class: "bot-ccb opa",
    pid: "price_1LmBITCMqZ2rPGEyZPuqlAUS",
    pidl: "price_1LvBNjCMqZ2rPGEyK3ahPeth",
  },
  {
    fingz: 2200,
    dollar: 19.99,
    title: "standard",
    class: "bot-c0 opa",
    pid: "price_1LmBItCMqZ2rPGEyfsGSIklo",
    pidl: "price_1LvBNeCMqZ2rPGEyS5ZoAxTo",
  },
  {
    fingz: 5500,
    dollar: 49.99,
    title: "premium",
    class: "bot-cck opa",
    pid: "price_1LmBJECMqZ2rPGEyHTHvzddU",
    pidl: "price_1LvBNZCMqZ2rPGEyMf6rjpfN",
  },
  {
    fingz: 11000,
    dollar: 99.99,
    title: "mega",
    class: "bot-ccp",
    pid: "price_1LmBJhCMqZ2rPGEy2sxCiXf5",
    pidl: "price_1LvBNKCMqZ2rPGEycOG0pyZB",
  },
  {
    fingz: 27000,
    dollar: 249.99,
    title: "mega",
    class: "bot-ccp",
    pid: "price_1Lx1wVCMqZ2rPGEylWudoWZI",
    pidl: "price_1Lx1wrCMqZ2rPGEyxBgdmx1a",
  },
  {
    fingz: 55000,
    dollar: 499.99,
    title: "mega",
    class: "bot-ccp",
    pid: "price_1Lx215CMqZ2rPGEy44yujrY0",
    pidl: "price_1Lx21ECMqZ2rPGEyCrtAHgiK",
  },
];

export const fin_wallet = {
  fingz: 0,
  tfingz: 0,
  hfingz: 0,
  zfingz: 0,
};
