import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { addHours, isPast } from "date-fns";

@Component({
  selector: "p-booking",
  templateUrl: "./p-booking.html",
  styleUrls: ["./p-booking.scss"],
})
export class ProfileBooking implements OnInit {
  my_id = this.util.storey.user.uid;
  bk_length = 0;
  filbk;
  bks = [];
  curr_chat;

  constructor(public util: Utilities) {}

  ngOnInit() {
    let zzz = this.util.storey.user_db.bookings ?? {};
    let bk_map = {};
    Object.entries(zzz).forEach((b: any) => {
      let jj = JSON.parse(b[1]);
      Object.keys(jj).forEach((j) => {
        bk_map[j] = {
          pay_key: b[0],
          day: +j.substring(0, j.indexOf("|")),
          time: j.substring(j.indexOf("|") + 1),
        };
      });
    });
    this.bks = Object.entries(bk_map);
  }

  getHost(h) {
    let hid = h
      .toLowerCase()
      .trim()
      .replace(/[\W_]+/g, "_");
    return this.util.storey.all_db.dummy.slot_map[hid];
  }

  checkEnd(bk) {
    let dd = +bk.substring(0, bk.indexOf("|"));
    let tt = bk
      .substring(bk.indexOf("|") + 1)
      .replace(/[^0-9]/g, "");

    let dx = addHours(dd, +tt);
    let is_past = isPast(dx);

    return is_past;
  }

  openEnd(temp) {}

  openChat(bd, day, ti, pay_k, ch_k, temp) {
    this.curr_chat = [
      bd.data.host_name,
      this.util.dpipe.transform(day, "EEE"),
      ti,
      pay_k,
      ch_k,
      bd.data.uid,
    ];

    this.util.dlogtr(temp);
    this.scro();
  }

  async scro() {
    let ele = document.getElementById("chatb");
    await new Promise((r) => setTimeout(r, 100));
    ele.scrollTop = ele.scrollHeight;
  }

  chatSend(t) {
    if (t.value !== "") {
      this.util.afd
        .object(
          `dbz/gamers/${this.my_id}/payments/${this.curr_chat[3]}/chat/${this.curr_chat[4]}`
        )
        .update({
          [new Date().getTime()]: ["gamer", t.value],
        });
      this.scro();
    }
  }
}
