<ng-container *ngLet="util.storey.host_db as host">
  <ng-container *ngLet="host.locations[curr_loc] as lox">
    <div class="container-xl px-2 mt-3 text-center">
      <div class="my-3 csw">
        <h3>
          <span
            routerLink="/host/inventory"
            class="mx-2 bg-csw bg1 px-3 py-1 box2 brad"
          >
            <i class="fas fa-arrow-left"></i>
          </span>
          Location {{+curr_loc + 1}}
          <button
            (click)=" addStuff(add_stuff, 'consoles')"
            class="mx-2 brad3 px-3 py-2 bg-ccp bg1"
          >
            Add Console
          </button>
        </h3>
        <h4>{{lox.city}}, {{lox.country}}</h4>
        <h6 class="csw fw-6">
          <span
            class="d-inline-block mx-3 bg-bg2 py-2 px-3 brad"
            *ngFor="let co of con_ops"
          >
            <i [ngClass]="co.icon" class="ccp"></i> &nbsp;
            {{co.name}}</span
          >
        </h6>
      </div>
      <div class="row">
        <ng-container
          *ngFor="let l of host.locations | keyvalue"
        >
          <ng-container
            *ngFor="let c of l.value.consoles| keyvalue "
          >
            <div
              *ngFor="let n of c.value"
              class="col-6 col-lg-2 col-md-3 col-sm-4 px-2"
            >
              <div
                class="coimg bg-bg1 box2 csw brads p-0 my-2"
              >
                <p class="m-0">
                  <img
                    class="im2"
                    [src]="util.storey.all_db.consoles[c.key]?.image"
                    alt=""
                  />
                </p>
                <h5 class="my-1 px-3 text-truncate fw-5 h4">
                  {{util.storey.all_db.consoles[c.key]?.name}}
                </h5>
                <h5 class="my-1 h2 fw-8">{{n.cid}}</h5>
                <h5 class="m-0 row">
                  <div
                    class="col-6 p-1"
                    *ngFor="let cop of con_ops"
                  >
                    <div
                      class="bg-ccp bg1 box2 py-2 brads poi"
                      (click)="cop.open([l.key, c.key], n)"
                    >
                      <i [ngClass]="cop.icon"></i>
                    </div>
                  </div>
                </h5>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- * SCHEDULER DIALOG  -->
    <ng-template #scheduler>
      <div
        class="position-relative p-0 brad bg-bg1 bo3-bg4 widd text-center"
      >
        <span matDialogClose class="h3 ccr poi exiter"
          ><i class="fas fa-times-circle"></i
        ></span>
        <div class="py-2">
          <p class="my-1 csw">
            <img
              [src]="util.storey.all_db.consoles[curr_keys[1]]?.image"
              height="60px"
              class="brad mx-2"
              alt=""
            />
            {{util.storey.all_db.consoles[curr_keys[1]]?.name}}
          </p>
        </div>

        <h-edit-slot-calendar
          [keys]="curr_keys"
          [events]="curr_events"
          [host]="util.storey.host_db"
          [console]="curr_console"
        ></h-edit-slot-calendar>
      </div>
    </ng-template>

    <!-- * PRICES DIALOG  -->
    <ng-template #prices>
      <div
        class="position-relative p-0 brad bg-bg1 bo3-bg4 widd text-center"
      >
        <span matDialogClose class="h3 ccr poi exiter"
          ><i class="fas fa-times-circle"></i
        ></span>
        <div class="py-2">
          <p class="my-1 csw h3">
            <img
              [src]="util.storey.all_db.consoles[curr_keys[1]]?.image"
              height="60px"
              class="brad mx-2"
              alt=""
            />
            {{util.storey.all_db.consoles[curr_keys[1]]?.name}}
          </p>
        </div>

        <h-edit-slot-prices
          [console]="curr_console"
          [keys]="curr_keys"
        ></h-edit-slot-prices>
      </div>
    </ng-template>

    <!-- * GAMES DIALOG  -->
    <ng-template #co_games>
      <div
        class="position-relative p-0 brad bg-bg1 bo3-bg4 widd text-center"
      >
        <span matDialogClose class="h3 ccr poi exiter"
          ><i class="fas fa-times-circle"></i
        ></span>
        <div class="py-2">
          <p class="my-1 csw h3">
            <img
              [src]="util.storey.all_db.consoles[curr_keys[1]]?.image"
              height="60px"
              class="brad mx-2"
              alt=""
            />
            {{util.storey.all_db.consoles[curr_keys[1]]?.name}}
          </p>
        </div>

        <div class="conty bg-bg3 mt-1 mb-2 pt-3">
          <div class="divy m-3 brad text-center py-3">
            <h5>
              <button
                class="brad bg-ccp bg1 poi px-3 py-2"
                (click)="addStuff(add_stuff, 'games')"
              >
                + Add Games
              </button>
            </h5>
            <p>
              <button
                *ngFor="let gm of lox.consoles[curr_keys[1]][curr_console.cid - 1].games | keyvalue "
                class="coimg cowi2 bg-bg1 csw brads m-2 p-0"
              >
                <img
                  class="im3"
                  [src]="util.storey.all_db.games[gm.key]?.image"
                  [style.object-position]="util.storey.all_db.games[gm.key].css_class ?? 'top'"
                  alt=""
                />
                <p class="my-2 fw-6 text-truncate">
                  {{util.storey.all_db.games[gm.key]?.name}}
                </p>
              </button>
            </p>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- * GADGETS DIALOG  -->
    <ng-template #co_gadgets>
      <div
        class="position-relative p-0 brad bg-bg1 bo3-bg4 widd text-center"
      >
        <span matDialogClose class="h3 ccr poi exiter"
          ><i class="fas fa-times-circle"></i
        ></span>
        <div class="py-2">
          <p class="my-1 csw h3">
            <img
              [src]="util.storey.all_db.consoles[curr_keys[1]]?.image"
              height="60px"
              class="brad mx-2"
              alt=""
            />
            {{util.storey.all_db.consoles[curr_keys[1]]?.name}}
          </p>
        </div>

        <div class="conty bg-bg3 mt-1 mb-2 pt-3">
          <div class="divy m-3 brad text-center py-3">
            <h5>
              <button
                class="brad bg-ccp bg1 poi px-3 py-2"
                (click)="addStuff(add_stuff, 'gadgets')"
              >
                + Add Gadgets
              </button>
            </h5>
            <p>
              <button
                *ngFor="let gd of lox.consoles[curr_keys[1]][curr_console.cid - 1].gadgets | keyvalue "
                class="coimg cowi2 bg-bg1 csw brads m-2 p-0"
              >
                <img
                  class="im3 imgc"
                  [src]="util.storey.all_db.gadgets[gd.key]?.image"
                  alt=""
                />
                <p class="my-2 fw-6 text-truncate">
                  {{util.storey.all_db.gadgets[gd.key]?.name}}
                </p>
              </button>
            </p>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- * ADD NEW STUFF DIALOG  -->
    <ng-template #add_stuff>
      <div
        class="position-relative overflow-hidden p-0 brad bg-bg1 bo3-bg4 wiz csw text-center"
      >
        <span matDialogClose class="h3 ccr poi exiter"
          ><i class="fas fa-times-circle"></i
        ></span>
        <div>
          <div class="text-center">
            <p>
              <input
                class="shadow-sm bo3-bg4 bg-bg2 brad m-4 csw p-3"
                type="text"
                [(ngModel)]="searcher"
                placeholder="Search"
              />
              <button
                class="mx-3 brad2 px-3 py-2 bg-ccp bg1 bo3-bg4"
                (click)="addUpdate()"
                [disabled]="(added_list | json) == '{}'"
              >
                UPDATE
              </button>
            </p>
          </div>
          <!-- * LIST  -->
          <div class="hadd-list bg-bg3 py-1 mb-0">
            <button
              class="coimg cowi2 bg-bgc csw brad m-2"
              [ngClass]="added_list[a.id] ? 'bob-ccp' : 'bo-bg4'"
              *ngFor="let a of add_list | searchFilter: searcher"
            >
              <img
                class="im3"
                [style.object-position]="a.css_class ?? 'top'"
                [src]="a.image"
                alt=""
              />
              <br />
              <span class="my-4 px-3 text-truncate fs07"
                >{{a.name}}</span
              >
              <br />
              <span *ngIf="add_db !== 'games'">
                <span
                  (click)="selecter(a, 0)"
                  class="brad ccr fw-5 fs2"
                  >- &nbsp; &nbsp;</span
                >
                {{added_list[a.id]|| 0}}
                <span
                  (click)="selecter(a, 1)"
                  class="brad ccb fw-5 fs2"
                >
                  &nbsp; &nbsp;+</span
                >
              </span>
              <h5
                *ngIf="add_db === 'games'"
                class="mt-2 mb-0 p-2 bg-bg1"
                [ngClass]="added_list[a.id] ? 'ccr' : 'ccp'"
                (click)="selecter(a, 4)"
              >
                {{added_list[a.id] ? 'REMOVE' : 'ADD'}}
              </h5>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
