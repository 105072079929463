import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "ad-hosts",
  templateUrl: "./ad-hosts.html",
  styleUrls: ["../admin.scss"],
})
export class AdHosts {
  searcher = "";

  constructor(public util: Utilities) {}

  async repSk() {}
}
