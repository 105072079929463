<div class="p-2 text-center">
  <p class="justify-content-center d-flex">
    <input
      class="searcher shadow brad mx-2 csw p-3"
      type="text"
      [(ngModel)]="searcher"
      placeholder="Search"
    />
    &nbsp;
    <button
      (click)="util.dlogtr(new_gadget)"
      class="bg-bg1 c0 fs2 li0 px-3 py-4 brad"
    >
      +
    </button>
  </p>

  <ng-container
    *ngFor="let gd of util.map2Arr(util.storey.all_db.gadgets, 2) | searchFilter: searcher"
  >
    <div class="itemy brads shadow-sm bg-bg1 csw p-1">
      <img
        width="100%"
        height="50px"
        [src]="gd.image || '/assets/robot.png'"
        alt=""
        class="brads mx-auto"
        (dblclick)="util.show(gd)"
      />
      <p class="text-truncate fs07 m-0">{{gd.name}}</p>
      <p class="fs07 m-0">
        <button
          class="m-1 bg-bg1 c0 box2 brad"
          (click)="current_gadget = [gd.id, gd.consoles]; cFilter(util.map2Arr(util.storey.all_db.consoles, 2)); util.dlogtr(update_gadget_console)"
        >
          <i class="fas fa-hdd"></i>
        </button>

        <button
          class="m-1 bg-bg1 c0 box2 brad"
          (click)="current_gadget = [gd.id, gd.image]; util.dlogtr(update_image)"
        >
          <i class="fas fa-image"></i>
        </button>
      </p>
    </div>
  </ng-container>
</div>

<!-- * UPDATE IMAGE  -->
<ng-template #update_image>
  <div
    class="position-relative p-4 brad bg-bg1 bo3-bg4 wim text-center"
  >
    <span matDialogClose class="h3 ccr poi exiter"
      ><i class="fas fa-times-circle"></i
    ></span>
    <div>
      <p>
        <input
          class="bg-bg1 p-3 csw shadow my-2 brad wi4"
          name="name"
          type="text"
          [(ngModel)]="current_gadget[1]"
        />
      </p>
      <p>
        <button
          (click)="updateImage()"
          class="px-3 py-2 bg-csw bg1"
        >
          UPDATE IMAGE
        </button>
      </p>
    </div>
  </div>
</ng-template>

<!-- * UPDATE GADGET CONSOLES -->
<ng-template #update_gadget_console>
  <div
    class="container brad bg-bg1 csw win p-4 text-center"
  >
    <mat-form-field appearance="fill" class="bg-bg1 csw">
      <mat-label>Consoles</mat-label>
      <mat-select #upc multiple>
        <mat-option
          *ngFor="let c of filtered_consoles"
          [value]="c.id"
          >{{c.name}}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <p>
      <button (click)="updateConsoles(upc)">Update</button>
    </p>
    <p *ngIf="current_gadget[1]" class="my-2">
      <span
        class="box2 bg-bg2 brad mx-2 fs07 p-2"
        *ngFor="let co of current_gadget[1]"
        >{{util.storey.all_db.consoles[co].abbr}}</span
      >
    </p>
  </div>
</ng-template>

<!-- * NEW GADGET  -->
<ng-template #new_gadget>
  <div
    class="position-relative p-4 brad bg-bg1 bo3-bg4 wim text-center"
  >
    <span matDialogClose class="h3 ccr poi exiter"
      ><i class="fas fa-times-circle"></i
    ></span>
    <div>
      <form class="" #new_form="ngForm">
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            name="name"
            type="text"
            placeholder="Name"
            ngModel
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            name="image"
            type="text"
            placeholder="Image"
            ngModel
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            name="specs"
            type="text"
            placeholder="Specs"
            ngModel
          />
        </p>
      </form>
      <p>
        <button
          (click)="newGadget(new_form)"
          class="px-3 py-2 bg-csw bg1"
        >
          ADD
        </button>
      </p>
    </div>
  </div>
</ng-template>
