import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { bu_menu, timeq } from "./h-p-data";
import SwiperCore, {
  Keyboard,
  Pagination,
  Navigation,
  Thumbs,
} from "swiper";
import { firstValueFrom } from "rxjs";

SwiperCore.use([Keyboard, Pagination, Navigation, Thumbs]);

@Component({
  selector: "h-profile",
  templateUrl: "./h-profile.html",
  styleUrls: ["../hosts.scss"],
})
export class HostProfile implements OnInit {
  curr_temp;
  curr_date = [0, "Monday"];
  scrollx = [0, 0, 4000];
  book_times = this.util.lo_cloneDeep(timeq);

  hid = this.util.storey.host_db.hid;

  b_menu: any = bu_menu;

  constructor(public util: Utilities) {}

  ngOnInit(): void {}

  openMenu(name, dlog) {
    this.curr_temp = name;
    if (name === "Schedule") {
      this.schInit();
      this.util.dlogtr(dlog);
    } else {
      this.util.wuToast("Coming Soon", "");
    }
  }

  schInit() {
    let sch =
      this.util.storey.all_db.dummy.slot_map[this.hid]
        .schedule;
    if (sch) {
      this.book_times = this.util.lo_cloneDeep(sch);
    }
  }

  checkOpen(h) {
    let hh = this.util.dpipe.transform(h, "Ho");
    return (
      this.book_times[this.curr_date[0]]["times"][hh] ===
      "open"
    );
  }

  checkBook(h) {
    let hh = this.util.dpipe.transform(h, "Ho");
    let sch =
      this.util.storey.all_db.dummy.slot_map[this.hid]
        .schedule;
    return (
      (sch ?? this.book_times)[this.curr_date[0]]["times"][
        hh
      ] === "booked"
    );
  }

  toggleHour(h) {
    let hh = this.util.dpipe.transform(h, "Ho");
    let sch =
      this.util.storey.all_db.dummy.slot_map[this.hid]
        .schedule;
    let is_booked =
      sch?.[this.curr_date[0]]["times"][hh] === "booked";
    let stats =
      this.book_times[this.curr_date[0]]["times"][hh];

    if (is_booked) {
      this.util.wuToast("Booked", "");
    } else if (stats === "closed") {
      this.book_times[this.curr_date[0]].times[hh] = "open";
    } else if (stats === "open") {
      this.book_times[this.curr_date[0]].times[hh] =
        "closed";
    } else if (stats === "booked") {
      this.util.wuToast("Booked", "");
    }
  }

  update() {
    this.util.afd
      .object(`dbz/dummy/slot_map/${this.hid}`)
      .update({
        schedule: this.book_times,
      });
    this.util.suToast("Updated", "");
  }
}
