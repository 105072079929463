<div
  class="px-2 py-0 con-fin"
  *ngLet="util.storey.user as profile"
>
  <div class="csw mt-3 bg-bg1 p-4 shadow brad">
    <div class="row" *ngLet="util.storey.user_db as udb">
      <!-- $ PROFILE  -->
      <div class="col-4 col-lg-2">
        <p class="text-center">
          <img
            [src]="profile?.photoURL ?? '/assets/emoji.jpg'"
            width="98%"
            class="brad shadow-sm"
          />
        </p>
      </div>
      <div class="col-8 col-lg-3">
        <h3 class="fw-8">{{profile.displayName}}</h3>
        <h5 class="fw-6">@{{udb.username}}</h5>
        <h5 class="fw-4">
          <i class="fas fa-envelope mr-2"></i>
          {{profile.email}}
        </h5>

        <h6 class="my-3">
          <i class="fab fa-discord ccp"></i> {{udb.discord
          ?? '-------'}} &nbsp; &nbsp; &nbsp;
          <i class="fab fa-twitter ccb"></i> {{udb.twitter
          ?? '-------'}}
        </h6>
      </div>
      <div class="col-3 col-lg-2 p-2">
        <div class="bothi text-center py-3">
          <h4 class="c0 display-4 fw-8">0</h4>
          <p>Bookings</p>
        </div>
      </div>
      <div class="col-3 col-lg-2 p-2">
        <div class="bothi text-center py-3">
          <h4 class="cck display-4 fw-8">0</h4>
          <p>Events</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- *EDIT PROFILE MODAL -->
<ng-template #editor>
  <edit-profile></edit-profile>
</ng-template>
