import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "p-purchases",
  templateUrl: "./p-purchases.html",
  styleUrls: ["./p-purchases.scss"],
})
export class ProfilePurchases {
  constructor(public util: Utilities) {}
}
