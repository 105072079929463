<div class="container">
  <!-- <game-search></game-search> -->
  <div class="mapcon brad2 bo-bg4 shadow m-4">
    <mgl-map
      [style]="'mapbox://styles/mapbox/dark-v9'"
      [zoom]="[3]"
      [center]="[-97.38, 35]"
    >
      <mgl-marker [lngLat]="[-122.473187, 37.756187]">
        <div
          (click)="alert('Fortnite')"
          class="marker"
          style="
            background-image: url(assets/marker.png);
            background-size: cover;
            width: 20px;
            height: 30px;
          "
        ></div>
      </mgl-marker>
      <mgl-marker [lngLat]="[-96.634312, 32.587812]">
        <div
          (click)="alert('Fifa 21')"
          class="marker"
          style="
            background-image: url(assets/marker.png);
            background-size: cover;
            width: 20px;
            height: 30px;
          "
        ></div>
      </mgl-marker>
      <mgl-marker [lngLat]="[-73.573188, 39.913938]">
        <div
          (click)="alert('The Crew')"
          class="marker"
          style="
            background-image: url(assets/marker.png);
            background-size: cover;
            width: 20px;
            height: 30px;
          "
        ></div>
      </mgl-marker>
    </mgl-map>
  </div>
</div>
