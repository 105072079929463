import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "ad-bookings",
  templateUrl: "./ad-bookings.html",
  styleUrls: ["../admin.scss"],
})
export class AdBookings implements OnInit {
  searcher;
  page_mode = true;
  mo_arr = Array.from({ length: 12 }, (item, i) => {
    return new Date(0, i);
  });
  sl_map;
  sc_map = {};
  bk_map = {};
  bk_t_map = {};

  constructor(public util: Utilities) {}

  ngOnInit(): void {
    this.getSchedules(Object.entries(this.util.slot_map));
    this.getBks();
  }

  showD(d) {
    let dd = this.util.startOfDay(d).getTime();
    console.log(this.bk_t_map[dd]);
  }

  checkD(d) {
    let dd = this.util.startOfDay(d).getTime();
    return dd in this.sc_map;
  }

  checkB(d) {
    let dd = this.util.startOfDay(d).getTime();
    return this.bk_t_map[dd] !== undefined;
  }

  getBks() {
    Object.entries(
      this.util.storey.all_db.bookings
    ).forEach((bk: any) => {
      if (bk[1].status === "paid") {
        this.bk_map[bk[0]] = bk[1];
        Object.entries(bk[1].schedule).forEach(
          (sk: any) => {
            this.bk_t_map[sk[0]] = {
              booking: bk[0],
              times: sk[1],
            };
          }
        );
      }
    });
  }

  getSchedules(scs) {
    scs.forEach((s) => {
      Object.entries(s[1].schedules).forEach((sc: any) => {
        this.util.lo_setWith(
          this.sc_map,
          `${sc[0]}.${s[0]}`,
          sc[1],
          Object
        );
      });
    });
  }
}
