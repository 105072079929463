import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "party",
  templateUrl: "./party.html",
  styleUrls: ["./parties.scss"],
})
export class Party implements OnInit {
  curr_user = this.util.storey.user_db;
  joining = false;

  social = [
    {
      icon: "fas fa-copy",
      bg: "bg-csw bg1",
    },
    {
      icon: "fab fa-facebook",
      bg: "fabg cw",
    },
    {
      icon: "fab fa-twitter",
      bg: "twibg cw",
    },
    {
      icon: "fab fa-whatsapp",
      bg: "watbg cw",
    },
  ];

  trophys = [
    {
      img: "/assets/gold.png",
      perc: "40%",
    },
    {
      img: "/assets/silver.png",
      perc: "20%",
    },
    {
      img: "/assets/bronze.png",
      perc: "10%",
    },
  ];

  constructor(public util: Utilities) {}

  ngOnInit(): void {}

  joinParty(party) {
    // if (this.util.is_connected) {
    //   let funded =
    //     this.util.storey.wallet.fingz >= party.entry_fingz;
    //   if (funded) {
    //     this.util.openConfirm({
    //       type: "party",
    //       party: party,
    //     });
    //   } else {
    //     this.util.openBuyer();
    //     this.util.wuToast(
    //       "BUY FINGZ",
    //       "You don't have enough $FINGZ or gas to join this party"
    //     );
    //   }
    // } else {
    //   this.util.openAuth("in");
    //   this.util.wuToast("Sign In", "Sign In to Join Party");
    // }
  }
}
