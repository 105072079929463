const timex = {
  "0th": "closed",
  "10th": "closed",
  "11th": "closed",
  "12th": "closed",
  "13th": "closed",
  "14th": "closed",
  "15th": "closed",
  "16th": "closed",
  "17th": "closed",
  "18th": "closed",
  "19th": "closed",
  "1st": "closed",
  "20th": "closed",
  "21st": "closed",
  "22nd": "closed",
  "23rd": "closed",
  "2nd": "closed",
  "3rd": "closed",
  "4th": "closed",
  "5th": "closed",
  "6th": "closed",
  "7th": "closed",
  "8th": "closed",
  "9th": "closed",
};

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const timeq = days.map((x) => {
  let nu: any = {};
  nu.day = x;
  nu.times = { ...timex };
  return nu;
});

export const bu_menu = [
  {
    name: "Edit",
    icon: "fas fa-edit",
  },
  {
    name: "Games",
    icon: "fas fa-gamepad",
  },
  {
    name: "Gadgets",
    icon: "fas fa-mouse",
  },
  {
    name: "Specs",
    icon: "fas fa-list-alt",
  },
  {
    name: "Photos",
    icon: "fas fa-images",
  },
  {
    name: "Schedule",
    icon: "fas fa-calendar",
  },
];
