<div
  *ngLet="util.getId(util.storey.router.url.split('?')[0]) as sid"
  class="con-fin pt-5"
>
  <ng-container *ngLet="util.storey.all_db as db">
    <ng-container *ngLet="db.dummy.slot_map[sid] as slot">
      <ng-container
        *ngLet="getBookings(db.hosts[slot.hid]?.bookings) as bk_map"
      >
        <div class="ffrow">
          <div class="ffcol text-center">
            <div class="p-0">
              <swiper
                [slidesPerView]="1"
                [pagination]="true"
                [navigation]="true"
                [centeredSlides]="true"
                effect="fade"
                [thumbs]="{ swiper: thum }"
                class="my-swiper"
              >
                <ng-template swiperSlide>
                  <img
                    width="100%"
                    class="brad mimg"
                    [src]="slot.images[0]"
                    alt=""
                  />
                </ng-template>
                <ng-template swiperSlide>
                  <img
                    width="100%"
                    class="brad mimg"
                    [src]="slot.images[0]"
                    alt=""
                  />
                </ng-template>
              </swiper>
              <h5 class="py-0 my-3">
                <button
                  class="box2 px-3 py-2 brads mx-2"
                  [ngClass]="so.bg"
                  *ngFor="let so of social"
                >
                  <i [ngClass]="so.icon"></i>
                </button>
                <button
                  class="box2 px-3 py-2 brads mx-2"
                  [ngClass]="util.checkFav(slot.host_name) ? 'bg-c0 bg1' : 'bg-bg1 c0'"
                  (click)="util.addFav(slot)"
                >
                  <i class="far fa-heart"></i>
                </button>
              </h5>
            </div>
          </div>
          <div class="ffcol">
            <div class="p-1 csw">
              <h3 class="py-1 my-1 fw-6 display-4">
                {{slot.host_name}}
              </h3>
              <h6 class="py-1 my-1 fw-4">
                {{slot.location}}
              </h6>
              <h4 class="py-1 mb-3 fw-5">
                {{slot.console}}
              </h4>
              <h3 class="my-1 fw-8">
                ${{slot.game_cost}}/hr
              </h3>
              <h3>
                <button
                  class="bg-c0 bg1 bkrad pl-3 pr-4 py-3 fw-7 my-3"
                  (click)="bookG(bklog, slot.schedule)"
                >
                  BOOK NOW
                </button>
              </h3>
            </div>
          </div>
          <div class="ffcol shadow-sm bg-cw brad p-1">
            <div class="pt-3 px-1">
              <mat-tab-group [color]="'main'">
                <mat-tab label="Games"
                  ><div class="p-2 tbox">
                    <ng-container
                      *ngFor="let gg of db.games | keyvalue"
                    >
                      <div
                        class="m-2 brads bo3-bg2 fw-6"
                        (click)="util.show(gg.value)"
                        *ngIf="gg.value.consoles.hasOwnProperty(slot.cid)"
                      >
                        <img
                          width="100px"
                          [src]="gg.value.image"
                          [style.object-position]="gg.value.css_class ?? 'top'"
                          alt=""
                          class="brads gimg"
                        />
                        &nbsp; &nbsp; {{
                        gg.value.full_name}}
                      </div>
                    </ng-container>
                  </div></mat-tab
                >
                <mat-tab label="Gadgets"
                  ><div class="p-2 tbox">
                    <ng-container
                      *ngFor="let gd of db.gadgets | keyvalue"
                    >
                      <div
                        class="m-2 brads bo3-bg2 fw-4"
                        (click)="util.show(gg.value)"
                        *ngIf="gd.value.consoles.includes(slot.cid)"
                      >
                        <img
                          width="80px"
                          height="50px"
                          [src]="gd.value.image"
                          alt=""
                          class="brads"
                        />
                        &nbsp; &nbsp; {{ gd.value.name}}
                      </div>
                    </ng-container>
                  </div></mat-tab
                >
                <mat-tab label="Specs">
                  <div class="p-2">Specs</div></mat-tab
                >
              </mat-tab-group>
            </div>
          </div>
          <div class="ffcol shadow-sm bg-cw brad p-1">
            <div class="pt-3 px-3">
              <h3 class="csw fw-5">
                <i class="fas fa-star"></i> {{slot.rating}}
                &#x2022; 0 reviews
              </h3>
            </div>
          </div>
        </div>
        <ng-template #bklog>
          <div
            class="position-relative p-1 brad bg-bg1 shadow wim8 text-center csw"
          >
            <span matDialogClose class="h3 poi exiter"
              ><i class="fas fa-times"></i
            ></span>
            <div class="pt-5 px-2">
              <div>
                <div class="conbox">
                  <div
                    class="conlist"
                    #conscroll
                    (scroll)="util.conscroller(scrollx, conscroll)"
                  >
                    <ng-container
                      *ngFor="let d of checkAv(slot.schedule); let i = index"
                    >
                      <div
                        class="dpk mx-3 poi text-center"
                        [ngClass]="{'bg-c0 cw shadow-sm brad2' : curr_date[0] === i}"
                        (click)="curr_date = [i, d]"
                      >
                        <h5 class="my-2 fw-4">
                          {{d | dayF : 'EEE'}}
                        </h5>
                        <h5 class="m-0 fw-8">
                          {{d | dayF : 'dd'}}
                        </h5>
                      </div>
                    </ng-container>
                  </div>
                  <div
                    class="conleft shadow-sm bg-bg2 csw poi"
                    (click)="util.conScrollLeft(scrollx, conscroll)"
                    *ngIf="scrollx[0] > 20 && !util.isM"
                  >
                    <i
                      class="fas fa-chevron-circle-left"
                    ></i>
                  </div>
                  <div
                    class="conright shadow-sm bg-bg2 csw poi text-center"
                    (click)="util.conScrollRight(scrollx, conscroll)"
                    *ngIf="scrollx[0] < (scrollx[2] - 20)"
                  >
                    <i
                      class="fas fa-chevron-circle-right"
                    ></i>
                  </div>
                </div>
                <hr />
                <div class="p-5">
                  <div class="hgrid">
                    <ng-container
                      *ngFor="let h of util.getHoursInDay()"
                    >
                      <div
                        class="shadow-sm brad2 py-3 bg-bg1 bo-bg4 poi bo-35"
                        [ngClass]="{'bo3-c0 c0' : checkOpen(h)}"
                        *ngIf="checkHr(slot, bk_map,h)"
                        (click)="addHour(h, slot.game_cost, slot.price)"
                      >
                        {{h | dayF: 'hh '}}
                        <span class="fw-8"
                          >{{h | dayF: 'aaa'}}</span
                        >
                      </div>
                    </ng-container>
                  </div>
                </div>
                <hr />
                <div class="text-center py-4">
                  <h3 class="fw-8">
                    $ {{total_cost}}
                    <button
                      class="shadow bg1 bg-c0 poi brad2 mx-3 px-4 py-2 fw-6"
                      [disabled]="total_cost <= 0"
                      (click)="booker(slot)"
                    >
                      Book
                    </button>
                    <button
                      *ngIf="total_cost > 0"
                      class="shadow bg1 bg-ccr poi brad2 mx-0 px-4 py-2 fw-6"
                      (click)="clearBooks()"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
