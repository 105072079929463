import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "terms",
  templateUrl: "./terms.html",
  styles: [""],
})
export class Terms implements OnInit {
  @Input() reader = false;
  constructor() {}

  ngOnInit() {}
}
