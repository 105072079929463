import { Component, OnInit } from "@angular/core";
import { party_filter_menu } from "@models/menu";
import { Utilities } from "@services/utilities";
import { dummy_evs } from "./p-dummy";

@Component({
  selector: "parties",
  templateUrl: "./parties.html",
  styleUrls: ["./parties.scss"],
})
export class Parties implements OnInit {
  search_params = {
    name: "party",
    title: "Party",
    color: "cck",
    border: "bot-cck opa",
    border_l: "bol-cck",
    profile_icon: "fas fa-house-user",
    profile_bg: "bg-cck",
    profile_link: "/profile/party",
    menu: [...party_filter_menu],
  };

  empty_search = false;
  a_events = dummy_evs;

  constructor(public util: Utilities) {}

  ngOnInit() {}

  getParties(pax, filx) {
    let paxx: any = Object.values(pax ?? {});
    let party = paxx?.map((tt) => {
      let new_t = this.util.lo_cloneDeep(tt);
      new_t.g_name =
        this.util.storey.all_db.games[tt.game].name;
      new_t.g_cats =
        this.util.storey.all_db.games[tt.game].categories;
      return new_t;
    });
    let is_cats = this.util.truthMap(filx?.t_categories);
    let is_cons = this.util.truthMap(filx?.t_consoles);
    // console.log(filx);
    // console.log(is_cats);

    let filt = [];

    filt = party?.filter((t) => {
      let valid = false;
      let co_valid = false;
      let cat_valid = false;
      if (is_cons) {
        t.consoles.forEach((c) => {
          if (filx?.t_consoles[c]) {
            co_valid = true;
          }
        });
      }
      if (is_cats) {
        t.g_cats.forEach((cat) => {
          if (filx?.t_categories[cat]) {
            cat_valid = true;
          }
        });
      }
      valid =
        !is_cons && is_cats
          ? cat_valid
          : is_cons && !is_cats
          ? co_valid
          : is_cons && is_cats
          ? co_valid && cat_valid
          : true;
      // console.log(is_cons, is_cats);

      return valid;
    });

    this.empty_search =
      filt?.length < 1 && (is_cons || is_cats);

    return filt;
  }
}
