<div class="sticky-top">
  <div *ngLet="util.storey.router.url as curr_path">
    <div class="bg-bg1 pb-1 scrollmenu">
      <div class="container-xl px-2 mt-4">
        <div
          *ngFor="let n of navs"
          [routerLink]="n.link"
          class="navb bg-bg1 brads box2 csw px-4 py-3 fw-7 h6 poi"
          [ngClass]="curr_path.includes(n.link) ?   'bob-csw' : 'bot-b9'"
        >
          {{n.name}}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="">
  <router-outlet></router-outlet>
</div>
