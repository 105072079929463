export const stat_list = [
  {
    name: "MRR",
    fullname: "Monthly Recurring Revenue",
  },
  {
    name: "BKC",
    fullname: "Booking Count",
  },
  {
    name: "ARR",
    fullname: "Annual Recurring Revenue",
  },
  {
    name: "ARPA",
    fullname: "Average Revenue Per Account",
  },
  {
    name: "GPR",
    fullname: "Gross Profit",
  },
  {
    name: "TCV",
    fullname: "Total Contract Value",
  },
  {
    name: "ACV",
    fullname: "Annual Contract Value",
  },
  {
    name: "LTV",
    fullname: "Lifetime Value",
  },
  {
    name: "DFR",
    fullname: "Deferred Revenue",
  },
  {
    name: "BGS",
    fullname: "Billings",
  },
  {
    name: "CAC",
    fullname: "Customer Acquisition Cost",
  },
  {
    name: "CCR",
    fullname: "Concentration Risk",
  },
  {
    name: "DAU",
    fullname: "Daily Active Users",
  },
  {
    name: "MAU",
    fullname: "Monthly Active Users",
  },
  {
    name: "NLG",
    fullname: "Number of Logins",
  },
  {
    name: "ACR",
    fullname: "Activation Rate",
  },
  {
    name: "MOMGR",
    fullname: "Month on Month Growth Rate",
  },
  {
    name: "CMGR",
    fullname: "Compounded Monthly Growth",
  },
  {
    name: "MCR",
    fullname: "Monthly Churn Rate",
  },
  {
    name: "RET",
    fullname: "Retention",
  },
  {
    name: "GCR",
    fullname: "Gross Churn Rate",
  },
  {
    name: "NCH",
    fullname: "Net Churn",
  },
  {
    name: "BR",
    fullname: "Burn Rate",
  },
  {
    name: "TAM",
    fullname: "Total Addressable Market",
  },
  {
    name: "MRRP",
    fullname: "MRR Projection",
  },
];
