<div *ngLet="util.storey.host_db as host" class="px-2 py-0">
  <div
    class="con-fin csw mt-3 bg-bg1 p-4 shadow brad"
    *ngLet="util.storey.all_db.dummy.slot_map[host.hid] as slot"
  >
    <div class="row crow" *ngLet="util.storey.user as user">
      <div class="col-12 col-lg-5 my-auto">
        <swiper
          [slidesPerView]="1"
          [pagination]="true"
          [navigation]="true"
          [centeredSlides]="true"
          effect="fade"
          [thumbs]="{ swiper: thum }"
          class="my-swiper"
        >
          <ng-template swiperSlide>
            <img
              width="100%"
              class="brad mimg"
              [src]="slot.images[0]"
              alt=""
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              width="100%"
              class="brad mimg"
              [src]="slot.images[0]"
              alt=""
            />
          </ng-template>
        </swiper>
      </div>
      <div class="col-12 col-lg-7">
        <h4 class="fw-6 display-4">
          {{slot.host_name}}
          <span class="fw-8 ccp">${{slot.price}}/hr</span>
        </h4>
        <h5 class="fw-6">{{slot.location}}</h5>
        <h5 class="fw-4">
          <i class="fas fa-envelope mr-2"></i>
          {{host.email}}
        </h5>

        <h4 class="my-3">
          <i class="fas fa-star"></i> {{slot.rating | number
          : '1.2-2'}}
        </h4>

        <h5 class="mt-4">
          <button
            class="bmenu px-4 py-3 brad2 shadow bg-ccp bg1 fw-5 m-2"
            (click)="openMenu(b.name, dlog)"
            *ngFor="let b of b_menu"
          >
            <i class="mx-2" [ngClass]="b.icon"></i>
            {{b.name}}
          </button>
        </h5>
      </div>
    </div>
  </div>

  <ng-template #dlog>
    <div
      class="position-relative p-1 brad bg-bg1 shadow wim8 text-center csw"
      *ngIf="curr_temp === 'Schedule'"
    >
      <span matDialogClose class="h3 poi exiter"
        ><i class="fas fa-times"></i
      ></span>
      <div class="pt-5 px-2">
        <div>
          <div class="conbox">
            <div
              class="conlist conlist2"
              #conscroll
              (scroll)="util.conscroller(scrollx, conscroll)"
            >
              <ng-container
                *ngFor="let d of book_times; let i = index "
              >
                <div
                  class="dapk mx-3 poi text-center"
                  [ngClass]="{'bg-ccp cw shadow-sm brad2' : curr_date[1] === d.day}"
                  (click)="curr_date = [i, d.day]"
                >
                  <h5 class="my-2 fw-6">{{d.day}}</h5>
                </div>
              </ng-container>
            </div>
            <div
              class="conleft shadow-sm bg-bg2 csw poi"
              (click)="util.conScrollLeft(scrollx, conscroll)"
              *ngIf="scrollx[0] > 20 && !util.isM"
            >
              <i class="fas fa-chevron-circle-left"></i>
            </div>

            <div
              class="conright shadow-sm bg-bg2 csw poi text-center"
              (click)="util.conScrollRight(scrollx, conscroll)"
              *ngIf="scrollx[0] < (scrollx[2] - 20)"
            >
              <i class="fas fa-chevron-circle-right"></i>
            </div>
          </div>
          <hr />
          <div class="p-5">
            <div class="hgrid">
              <div
                class="shadow-sm brad2 fw-6 py-3 bg-bg1 bo-bg4 poi"
                [ngClass]="{'bo3-ccp ccp' : checkOpen(h), 'bg-c0 bg1' : checkBook(h)}"
                *ngFor="let h of util.getHoursInDay()"
                (click)="toggleHour(h)"
              >
                {{h | dayF: 'hh '}}
                <span class="fw-8"
                  >{{h | dayF: 'aaa'}}</span
                >
              </div>
            </div>
          </div>
          <hr />
          <div class="text-center py-4">
            <h3 class="fw-8">
              <button
                class="shadow bg1 bg-ccp poi brad2 mx-3 px-4 py-3 fw-6"
                (click)="update()"
              >
                Update
              </button>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
