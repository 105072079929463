import {
  Component,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { CalendarView } from "angular-calendar";

@Component({
  selector: "cal-head",
  template: `
    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn bg1 bg-ccp"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
            Previous
          </div>
          <div
            class="btn ccp bo-ccp"
            mwlCalendarToday
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
            Today
          </div>
          <div
            class="btn  bg1 bg-ccp"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
            Next
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>
          {{
            viewDate
              | calendarDate: view + "ViewTitle":locale
          }}
        </h3>
      </div>
      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn  bg1 bg-ccp"
            (click)="viewChange.emit(CalendarView.Month)"
            [class.active]="view === CalendarView.Month"
          >
            Month
          </div>
          <div
            class="btn bg1 bg-ccp"
            (click)="viewChange.emit(CalendarView.Week)"
            [class.active]="view === CalendarView.Week"
          >
            Week
          </div>
          <div
            class="btn bg1 bg-ccp"
            (click)="viewChange.emit(CalendarView.Day)"
            [class.active]="view === CalendarView.Day"
          >
            Day
          </div>
        </div>
      </div>
    </div>
    <br />
  `,
})
export class CalendarHeader {
  @Input() view: CalendarView;

  @Input() viewDate: Date;

  @Input() locale: string = "en";

  @Output() viewChange = new EventEmitter<CalendarView>();

  @Output() viewDateChange = new EventEmitter<Date>();

  CalendarView = CalendarView;
}
