export class AddUser {
  static readonly type = "[User] AddUser";
  constructor(public user: any) {}
}

export class UpdateUserDB {
  static readonly type = "[User] UpdateUserDB";
  constructor(public user_db: any) {}
}

export class UpdateRole {
  static readonly type = "[Auth] UpdateRole";
  constructor(public role: any) {}
}

export class UpdateRoute {
  static readonly type = "[Route] UpdateRoute";
  constructor(public route: any) {}
}

export class WalletState {
  static readonly type = "[Wallet] WalletState";
  constructor(public wallet: any) {}
}

export class SetHost {
  static readonly type = "[Host] SetHost";
  constructor(public host: any) {}
}

export class SetTheme {
  static readonly type = "[Theme] SetTheme";
  constructor(public theme: string) {}
}

export class SetCountry {
  static readonly type = "[Country] SetCountry";
  constructor(public country: string) {}
}

export class UpdateDB {
  static readonly type = "[DB] UpdateDB";
  constructor(public db: any) {}
}

export class SetFilter {
  static readonly type = "[Filter] SetFilter";
  constructor(public filter: any, public type: any) {}
}

export class Searcher {
  static readonly type = "[Search] Searcher";
  constructor(
    public search_type: string,
    public search_string: string
  ) {}
}

/* ********************
     C L E A R
  ********************* */

export class ClearState {
  static readonly type = "[State] ClearState";
}
