<div class="conty mt-1 mb-2">
  <mwl-calendar-week-view
    [viewDate]="viewDate"
    [events]="mod_events"
    [hourDuration]="60"
    [hourSegmentHeight]="30"
    [hourSegments]="1"
    [hourSegmentTemplate]="weekViewHourSegmentTemplate"
    [weekStartsOn]="weekStartsOn"
    [daysInWeek]="daysInWeek"
    (eventClicked)="removeEvent($event.event)"
    (beforeViewRender)="weekRender($event)"
  >
  </mwl-calendar-week-view>
</div>

<div class="my-2">
  <div class="btn-group my-2">
    <button
      class="btn bg1 bg-ccp"
      (click)="decrement()"
      [disabled]="prevBtnDisabled"
    >
      Previous
    </button>
    <button
      class="btn ccp bo-ccp"
      mwlCalendarToday
      [(viewDate)]="viewDate"
    >
      Today
    </button>
    <button
      class="btn bg1 bg-ccp"
      (click)="increment()"
      [disabled]="nextBtnDisabled"
    >
      Next
    </button>
  </div>
  <button
    *ngIf="!ev_up"
    class="mx-3 brad2 px-3 py-2 bg-ccp bg1 bo3-bg4"
    (click)="updateEvents()"
  >
    UPDATE
  </button>
  <button
    *ngIf="ev_up"
    class="mx-3 brad2 px-3 py-2 bg-ccp bg1 bo3-bg4"
  >
    <span
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </span>
  </button>
</div>

<ng-template
  #weekViewHourSegmentTemplate
  let-segment="segment"
  let-locale="locale"
  let-segmentHeight="segmentHeight"
  let-isTimeLabel="isTimeLabel"
>
  <div
    #segmentElement
    class="cal-hour-segment"
    [style.height.px]="segmentHeight"
    [class.cal-hour-start]="segment.isStart"
    [class.cal-after-hour-start]="!segment.isStart"
    [ngClass]="segment.cssClass"
    (mousedown)="addEvent(segment)"
  >
    <div class="cal-time" *ngIf="isTimeLabel">
      {{ segment.date | calendarDate:'weekViewHour':locale
      }}
    </div>
  </div>
</ng-template>
