import { Component, Input, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "h-new",
  templateUrl: "./h-new.html",
  styleUrls: ["../hosts.scss"],
})
export class NewHost implements OnInit {
  user = this.util.snappy().user;
  countries;
  curr_page = 1;
  country;
  city;
  email = "";
  consoles = {};

  constructor(public util: Utilities) {}

  async ngOnInit() {
    let countries = await this.util.readLink("countries");
    this.countries = Object.values(countries);

    this.util.selly().subscribe((x) => {});
  }

  selectConsole(c) {
    if (this.consoles[c.id]) {
      delete this.consoles[c.id];
    } else {
      this.consoles[c.id] = [
        { cid: 1, games: {}, gadgets: {} },
      ];
    }
  }

  next() {
    switch (this.curr_page) {
      case 1:
        if (this.country && this.city) {
          this.curr_page++;
        } else {
          this.util.wuToast("Complete Form", "");
        }
        break;
      case 2:
        if (Object.keys(this.consoles).length > 0) {
          this.curr_page++;
        } else {
          this.util.wuToast("Choose a console", "");
        }
        break;
    }
  }

  async apply() {
    if (!this.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      this.util.wuToast("Provide valid Email", "");
    } else {
      let new_host = {
        id: this.user.uid,
        email: this.email,
        locations: {
          0: {
            country: this.country,
            city: this.city,
            consoles: this.consoles,
          },
        },
        verified: "false",
        rating: 5.0,
        balance: 0,
      };

      console.log(new_host);

      await this.util.afd
        .object(`dbz/hosts/${this.user.uid}`)
        .update(new_host);

      await this.util.afd
        .object(`dbz/gamers/${this.user.uid}`)
        .update({ is_host: true });

      this.util.dlogx();
      this.util.suToast("Success", "");
    }
  }
}
