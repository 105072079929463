<div class="onepage">
  <section class="error-container">
    <span class="four"><span class="screen-reader-text">4</span></span>
    <span class="zero"><span class="screen-reader-text">0</span></span>
    <span class="four"><span class="screen-reader-text">4</span></span>
  </section>
  <div class="link-container">
    <button class="btn fbtn" routerLink="">
      <mat-icon>home</mat-icon> Go Back Home
    </button>
  </div>
</div>
