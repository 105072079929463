import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "admin",
  templateUrl: "./admin.html",
  styleUrls: ["./admin.scss"],
})
export class Admin implements OnInit {
  navs = [
    {
      name: "Users",
      link: "/admin",
    },
    {
      name: "Hosts",
      link: "/admin/hosts",
    },
    {
      name: "Slots",
      link: "/admin/slots",
    },
    // {
    //   name: "Payments",
    //   link: "/admin/payments",
    // },
    {
      name: "Bookings",
      link: "/admin/bookings",
    },
    {
      name: "Events",
      link: "/admin/events",
    },
    {
      name: "Consoles",
      link: "/admin/consoles",
    },
    {
      name: "Games",
      link: "/admin/games",
    },
    {
      name: "Gadgets",
      link: "/admin/gadgets",
    },
    {
      name: "Stats",
      link: "/admin/stats",
    },
    // {
    //   name: "Shop",
    //   link: "/admin/shop",
    // },
  ];

  constructor(public util: Utilities) {}

  ngOnInit(): void {}
}
