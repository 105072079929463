import { Component, Inject, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "navbar",
  templateUrl: "./navbar.html",
  styleUrls: ["./navbar.scss"],
})
export class Navbar implements OnInit {
  search_open = false;

  constructor(public util: Utilities) {}

  async ngOnInit() {}

  nRouter(r) {
    if (this.util.is_connected) {
    } else {
      this.util.openAuth("in");
    }
  }
}
