<div *ngLet="util.storey.filters[search_type.name] as filx">
  <div class="bg-bg1 shadow-sm scrollmenu">
    <div class="con-fin">
      <div class="conbox">
        <div
          class="conlist"
          #conscroll
          (scroll)="util.conscroller(scrollx, conscroll)"
        >
          <ng-container
            *ngFor="let co of util.map2Arr(util.storey.all_db.consoles, 2) | orderBy:'value' : 'reverse' "
          >
            <div
              class="bg-bg1 mx-3 csw p-0 poi text-center"
              [ngClass]="{'bob-c0 bot-c0': util.storey.search.slot === co.name}"
              *ngIf="checkConsoles(co.id)"
              (click)="setConsole(co.name)"
            >
              <img class="conim" [src]="co.image" alt="" />
              <h6 class="brad p-2 text-truncate">
                {{co.abbr}}
              </h6>
            </div>
          </ng-container>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </div>
        <div
          class="conleft shadow-sm bg-bg2 csw poi"
          (click)="util.conScrollLeft(scrollx, conscroll)"
          *ngIf="scrollx[0] > 20 && !util.isM"
        >
          <i class="fas fa-chevron-circle-left"></i>
        </div>

        <div
          class="conright shadow-sm bg-bg2 csw poi text-center"
          (click)="util.conScrollRight(scrollx, conscroll)"
          *ngIf="scrollx[0] < (scrollx[2] - 20) && !util.isM"
        >
          <i class="fas fa-chevron-circle-right"></i>
        </div>
      </div>
      <div *ngIf="!util.isM" class="filbox px-3">
        <button
          class="brad bg-bg1 bo-bg4 csw shadow-sm px-3"
        >
          <i class="fas fa-sliders-h mx-2"> </i> Filters
        </button>
      </div>
    </div>
    <div *ngIf="filexpand" class="slist p-1">
      <ng-container
        *ngTemplateOutlet="current_filter_template[0]"
      ></ng-container>
    </div>
  </div>

  <!-- * CONSOLES TEMPLATE -->
  <ng-template #t_consoles>
    <div class="cogrid">
      <ng-container
        *ngFor="let co of util.map2Arr(util.storey.all_db.consoles, 2) "
      >
        <div
          class="coimg bg-bg1 box2 csw p-0 poi"
          [ngClass]="filx.t_consoles[co.id] ? search_type.border : 'bot-b9'"
          (click)="util.filterModify(search_type.name, filx, 't_consoles', co.id)"
          *ngIf="checkConsoles(co.id)"
        >
          <img [src]="co.image" alt="" />
          <h6 class="m-2 brad p-2 text-truncate">
            {{co.name}}
          </h6>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <!-- * CATEGORIES TEMPLATE  -->
  <ng-template #t_categories>
    <div class="cogrid">
      <div
        class="coimg bg-bg1 box2 csw p-0 poi"
        [ngClass]="filx.t_categories[c.key] ? search_type.border : 'bot-b9'"
        (click)="util.filterModify(search_type.name, filx, 't_categories', c.key)"
        *ngFor="let c of util.categories | keyvalue"
      >
        <h3
          [ngClass]="filx.t_categories[c.key] ? search_type.color : ''"
          class="text-center py-3 csw"
        >
          <i [ngClass]="c.value.icon"></i>
        </h3>
        <h6 class="m-2 p-2">{{c.value.name}}</h6>
      </div>
    </div>
  </ng-template>

  <!-- * SHOP CATEGORIES TEMPLATE  -->
  <ng-template #t_shop_categories>
    <div class="cogrid">
      <div
        class="coimg bg-bg1 box2 csw p-0 poi"
        [ngClass]="filx.t_shop_categories[sc.key] ? search_type.border : 'bot-b9'"
        (click)="util.filterModify(search_type.name, filx, 't_shop_categories', sc.key)"
        *ngFor="let sc of util.shop_categories | keyvalue"
      >
        <h3
          [ngClass]="filx.t_shop_categories[sc.key] ? search_type.color : ''"
          class="text-center py-3 csw"
        >
          <i [ngClass]="sc.value.icon"></i>
        </h3>
        <h6 class="m-2 p-2">{{sc.value.name}}</h6>
      </div>
    </div>
  </ng-template>

  <!-- * DATE/TIME TEMPLATE  -->
  <ng-template #t_date>
    <div class="cogrid">
      <div
        class="coimg bg-bg1 box2 csw p-0 poi"
        [ngClass]="filx.t_date[d] ? search_type.border : 'bot-b9'"
        (click)="util.filterModify(search_type.name, filx, 't_date', d)"
        *ngFor="let d of util.getAvailableDates() "
      >
        <h6 class="m-2 shadow-sm bg-bg3 brads p-2">
          {{d | dayF : "MMM" }}
        </h6>
        <h3
          [ngClass]="filx.t_date[d] ? search_type.color : ''"
          class="text-center py-3 csw"
        >
          {{d | dayF : "dd" }}
        </h3>
        <h6 class="m-2 shadow-sm bg-bg3 brads p-2">
          {{d | dayF : "iii" }}
        </h6>
      </div>
    </div>
  </ng-template>
</div>
