import { Pipe, PipeTransform } from "@angular/core";
import { format } from "date-fns";

@Pipe({
  name: "dayF",
})
export class DayPipe implements PipeTransform {
  transform(day: any, formats: any) {
    return format(day, formats);
  }
}
