import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "hosts",
  templateUrl: "./hosts.html",
  styleUrls: ["./hosts.scss"],
})
export class Hosts implements OnInit {
  host_faq = [
    {
      question: "Can I host in my Garage?",
      answer:
        "Yes you can host your garage if its comfortable and clean.",
    },
    {
      question: "Can I host in my Barbershop?",
      answer: "Yes you can host in your barbershop.",
    },
    {
      question: "What are Fingaz Fees?",
      answer: `Fingaz typically collects a 3% service fee 
        of the reservation subtotal when you get paid. 
        We also collect fees from the gamers when they book.`,
    },
  ];

  constructor(public util: Utilities) {}

  ngOnInit(): void {}

  newHost(template) {
    if (this.util.is_connected) {
      this.util.dlogtr(template);
    } else {
      this.util.openAuth("in");
    }
  }
}
