import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { Terms } from "./components/pages/terms/terms";
import { Cookies } from "./components/pages/cookies/cookies";
import { Privacy } from "./components/pages/privacy/privacy";
import { Community } from "./components/pages/community/community";
import { EULA } from "./components/pages/eula/eula";
import { Land } from "@components/widgets/land/land";
import {
  AdminGuard,
  AuthGuard,
  FingazGuard,
} from "@services/guard.service";
import { Hosts } from "@components/host/hosts";
import { Admin } from "@components/admin/admin";
import { AdUsers } from "@components/admin/ad-users/ad-users";
import { AdHosts } from "@components/admin/ad-hosts/ad-hosts";
import { AdGames } from "@components/admin/ad-games/ad-games";
import { AdParties } from "@components/admin/ad-parties/ad-parties";
import { HostBookings } from "@components/host/h-bookings/h-bookings";
import { HostProfile } from "@components/host/h-profile/h-profile";
import { HostSlots } from "@components/host/h-slots/h-slots";
import { HostInventory } from "@components/host/h-inventory/h-inventory";
import { HostFinances } from "@components/host/h-finances/h-finances";
import { AdConsoles } from "@components/admin/ad-consoles/ad-consoles";
import { AdGadgets } from "@components/admin/ad-gadgets/ad-gadgets";
import { AdBuyFingz } from "@components/admin/ad-buyfingz/ad-buyfingz";
import { ProfileDash } from "@components/profile/p-dash/p-dash";
import { ProfileHome } from "@components/profile/p-home/p-home";
import { ProfileBooking } from "@components/profile/p-booking/p-booking";
import { ProfileParty } from "@components/profile/p-party/p-party";
import { AdShop } from "@components/admin/ad-shop/ad-shop";
import { ProfilePurchases } from "@components/profile/p-purchases/p-purchases";
import { Shop } from "@components/widgets/shop/shop";
import { Parties } from "@components/widgets/parties/parties";
import { Party } from "@components/widgets/parties/party";
import { Product } from "@components/widgets/shop/product";
import { HPage } from "@components/host/h-page/h-page";
import { AdSlots } from "@components/admin/ad-slots/ad-slots";
import { AdBookings } from "@components/admin/ad-bookings/ad-bookings";
import { Slots } from "@components/widgets/slots/slots";
import { FingazHelp } from "@components/pages/help/help";
import { Faq } from "@components/widgets/faq/faq";
import { About } from "@components/pages/about/about";
import { Fingz } from "@components/pages/fingz/fingz";
import { Slot } from "@components/widgets/slots/slot";
import { NewHost } from "@components/host/h-new/h-new";
import { ProfileFavs } from "@components/profile/p-favs/p-favs";
import { AuthC } from "@components/widgets/auth/auth";
import { AdStats } from "@components/admin/ad-stats/ad-stats";

// ***** ROUTES ****
const routes: Routes = [
  {
    path: "",
    component: Land,
    data: {
      title: "Fingaz: Gaming Near You",
    },
  },
  {
    path: "signup",
    component: AuthC,
    canActivate: [AuthGuard],
    data: {
      title: "Fingaz Shop",
    },
  },
  {
    path: "signin",
    component: AuthC,
    canActivate: [AuthGuard],
    data: {
      title: "Fingaz Shop",
    },
  },
  {
    path: "shop",
    component: Shop,
    data: {
      title: "Fingaz Shop",
    },
  },
  {
    path: "shop/:product",
    component: Product,
  },
  {
    path: "slots",
    component: Slots,
    data: {
      title: "Fingaz Games",
    },
  },
  {
    path: "slots/:id",
    component: Slot,
  },
  {
    path: "events",
    component: Parties,
    data: {
      title: "Fingaz Parties",
    },
  },
  {
    path: "events/:id",
    component: Party,
  },
  {
    path: "profile",
    component: ProfileDash,
    canActivate: [FingazGuard],
    children: [
      {
        path: "",
        component: ProfileHome,
      },
      {
        path: "favs",
        component: ProfileFavs,
      },
      {
        path: "booking",
        component: ProfileBooking,
      },
      {
        path: "party",
        component: ProfileParty,
      },
      {
        path: "purchases",
        component: ProfilePurchases,
      },
    ],
  },
  // {
  //   path: "hosts/:host",
  //   component: HPage,
  // },
  {
    path: "host",
    component: Hosts,
    children: [
      {
        path: "home",
        component: HostProfile,
      },
      {
        path: "new",
        component: NewHost,
      },
      {
        path: "bookings",
        component: HostBookings,
      },
      {
        path: "inventory",
        component: HostInventory,
      },
      {
        path: "inventory/:location",
        component: HostSlots,
      },
      {
        path: "finances",
        component: HostFinances,
      },
    ],
  },
  {
    path: "admin",
    component: Admin,
    canActivate: [AdminGuard],
    children: [
      {
        path: "",
        component: AdUsers,
      },
      {
        path: "hosts",
        component: AdHosts,
      },
      {
        path: "consoles",
        component: AdConsoles,
      },
      {
        path: "games",
        component: AdGames,
      },
      {
        path: "slots",
        component: AdSlots,
      },
      {
        path: "bookings",
        component: AdBookings,
      },
      {
        path: "gadgets",
        component: AdGadgets,
      },
      {
        path: "shop",
        component: AdShop,
      },
      {
        path: "events",
        component: AdParties,
      },
      {
        path: "buyfingz",
        component: AdBuyFingz,
      },
      {
        path: "stats",
        component: AdStats,
      },
    ],
  },
  {
    path: "help",
    component: FingazHelp,
    data: {
      title: "Help & Support | Fingaz - Gaming Near You",
    },
    children: [
      {
        path: "faq",
        component: Faq,
      },
      {
        path: "about",
        component: About,
      },
      {
        path: "privacy",
        component: Privacy,
      },
      {
        path: "terms",
        component: Terms,
      },
      {
        path: "comms",
        component: Community,
      },
      {
        path: "eula",
        component: EULA,
      },
      {
        path: "",
        redirectTo: "faq",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "cookies",
    component: Cookies,
    data: {
      title: "Cookie Policy | Fingaz - Gaming Near You",
    },
  },
  {
    path: "privacy",
    component: Privacy,
    data: {
      title: "Privacy Policy | Fingaz - Gaming Near You",
    },
  },
  {
    path: "terms",
    component: Terms,
    data: {
      title: "Terms of Service | Fingaz - Gaming Near You",
    },
  },
  {
    path: "comms",
    component: Community,
    data: {
      title:
        "Community Guidelines | Fingaz - Gaming Near You",
    },
  },
  {
    path: "eula",
    component: EULA,
    data: {
      title:
        "End User License Agreement | Fingaz - Gaming Near You",
    },
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      scrollOffset: [0, 0],
    }),
  ],
  exports: [RouterModule],
})
export class FingazRouting {}
