<nav
  class="navbar navbar-expand-md bg-bg1 shadow-sm fixed-top"
  *ngLet="util.storey.router.url as current_url"
>
  <div class="con-fin">
    <a
      class="navbar-brand align-items-baseline"
      routerLink="/"
    >
      <logo></logo>
      <span *ngIf="!util.isM" class="fw-6 c0 h2">
        ingaz</span
      >
    </a>

    <div *ngIf="util.storey.router.url === '/'">
      <div
        *ngIf="!util.isM"
        class="hai bg-bg1 bo-bg3 shadow-sm csw brad2 h6 fw-3 px-1"
      >
        <!-- <span class="fw-6 px-4">City</span> |
        <span class="fw-6 px-4">Date</span> |
        <span class="px-4">Add Players</span> -->
        <input
          #city_search
          class="csw px-3"
          type="text"
          placeholder="Search City"
        />

        <button class="searchx shadow-sm bg-c0">
          <i class="fas fa-search mx-2 cw"></i>
        </button>
      </div>
      <div
        *ngIf="util.isM"
        class="hai bg-bg1 bo-bg3 shadow-sm csw brad2 fs09 fw-3 px-1"
      >
        <span class="fw-6 px-2">City</span> |
        <span class="fw-6 px-2">Date</span> |
        <span class="px-2">Add Players</span>

        <button class="searchx shadow-sm bg-c0">
          <i class="fas fa-search mx-2 bg1"></i>
        </button>
      </div>

      <button
        *ngIf="util.isM"
        class="hai bg-bg1 mx-2 bo-bg3 brad csw px-2 shadow-sm h5"
      >
        <i class="fas fa-sliders-h mx-2"></i>
      </button>
    </div>

    <div *ngIf="util.storey.router.url === '/events'">
      <div
        *ngIf="!util.isM"
        class="hai bg-bg1 bo-bg3 shadow-sm csw brad2 h6 fw-3 px-1"
      >
        <span class="fw-6 px-4">Location</span> |
        <span class="fw-6 px-4">Game</span>

        <button class="searchx shadow-sm bg-cck">
          <i class="fas fa-search mx-2 cw"></i>
        </button>
      </div>
      <div
        *ngIf="util.isM"
        class="hai bg-bg1 bo-bg3 shadow-sm csw brad2 fs09 fw-3 px-1"
      >
        <span class="fw-6 px-3">Location</span> |
        <span class="fw-6 px-3">Game</span>

        <button class="searchx shadow-sm bg-cck">
          <i class="fas fa-search mx-2 cw"></i>
        </button>
      </div>

      <button
        class="hai bg-bg1 mx-3 bo-bg3 brad csw px-2 shadow-sm h5"
      >
        <i class="fas fa-sliders-h mx-2"></i>
      </button>
    </div>

    <div class="tmenu">
      <button
        *ngIf=" !util.isM"
        class="bg-bg1 mx-2 shadow-sm csw brad2 h6 fw-3 px-3"
        [ngClass]="util.storey.router.url === '/' ? 'bo3-c0' : 'bo-bg3'"
        routerLink="/"
      >
        <i class="fas fa-gamepad mx-2 c0"></i> Games
      </button>
      <button
        *ngIf="!util.isM"
        class="bg-bg1 mx-2 shadow-sm csw brad2 h6 fw-3 px-3"
        [ngClass]="util.storey.router.url === '/events' ? 'bo3-cck' : 'bo-bg3'"
        routerLink="/events"
      >
        <i class="fas fa-calendar-alt mx-2 cck"></i> Events
      </button>
      <button
        *ngIf="util.is_admin && !util.isM"
        routerLink="admin"
        class="bg-bg1 mx-2 brad2 px-2 shadow-sm h5"
        [ngClass]="current_url?.includes('admin') ? ' bo3-ccr ccr' : 'bo-b9 b9'"
      >
        <i class="fas fa-shield-alt mx-2"></i>
      </button>
      <button
        *ngIf="util.is_connected && !util.isM"
        class="bg-bg1 b9 bo-b9 mx-2 brad2 px-2 shadow-sm h5"
      >
        <i class="fas fa-bell mx-2"></i>
      </button>
      <button
        [matMenuTriggerFor]="umenu"
        class="bg-bg1 mx-2 bo-bg3 brad2 csw px-2 shadow-sm h5"
        *ngIf="!util.isM"
      >
        <i class="fas fa-caret-down mx-2"></i>
        <i class="fas fa-user-circle mx-2"></i>
      </button>
      <mat-menu
        #umenu="matMenu"
        xPosition="before"
        class="bg-bg1 mt-4 wi3 shadow csw brad p-0 menux"
      >
        <h5
          *ngIf="!util.is_connected"
          (click)="util.openAuth('in')"
          class="fw-4"
        >
          Sign in
        </h5>
        <h5
          *ngIf="!util.is_connected"
          (click)="util.openAuth('up')"
          class="fw-6"
        >
          Sign up
        </h5>

        <h5
          *ngIf="util.is_connected"
          routerLink="/profile"
          class="fw-6"
        >
          My Profile
        </h5>

        <h5
          *ngIf="util.is_connected"
          routerLink="/profile/booking"
          class="fw-6"
        >
          My Bookings
        </h5>

        <h5
          *ngIf="util.is_connected"
          (click)="util.signOut()"
          class="fw-6 ccr"
        >
          Sign Out
        </h5>
        <hr class="b9 m-0" />

        <h5
          *ngIf="!util.storey.user_db.is_host"
          class="fw-5 ccp"
          routerLink="/host"
        >
          Host a Game
        </h5>

        <h5
          *ngIf="util.storey.user_db.is_host"
          class="fw-6 ccp"
          routerLink="/host/home"
        >
          Host Dashboard
        </h5>

        <h5 class="fw-6 cck" (click)="nRouter('event')">
          Setup an Event
        </h5>

        <h5 routerLink="/help/faq" class="fw-4">
          Help & Support
        </h5>
      </mat-menu>
    </div>
  </div>
  <div *ngIf="search_open"></div>
</nav>
