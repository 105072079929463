import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "ad-games",
  templateUrl: "./ad-games.html",
  styleUrls: ["../admin.scss"],
})
export class AdGames {
  current_game;
  filtered_consoles;
  filtered_categories;
  searcher;

  constructor(public util: Utilities) {}

  async syncG(db) {
    // Object.values(db).forEach((gm: any) => {
    //   // let soles = gm.consoles;
    //   // let solex = gm.consoles.reduce((r, v) => {
    //   //   r[v] = 1000;
    //   //   return r;
    //   // }, {});
    //   // console.log(soles, solex);
    //   // let csx = gm.consoles.map((x) => {
    //   //   return mox[x];
    //   // });
    //   // console.log(csx);
    //   this.util.afd.object(`dbz/games/${gm.id}`).update({
    //     consolex: null,
    //   });
    // });
  }

  idlize(s) {
    return s
      .toLowerCase()
      .trim()
      .replace(/[\W_]+/g, "_");
  }

  idCon(con) {
    return con.map((x) => x.id);
  }
  idCat() {
    return Object.keys(this.util.categories);
  }

  updateConCat() {
    let solex = this.current_game[1].reduce((r, v) => {
      r[v] = 1000;
      return r;
    }, {});
    this.util.afd
      .object(`dbz/games/${this.current_game[0]}`)
      .update({
        consoles: solex,
        categories: this.current_game[2],
      });

    this.util.dlogx();
  }

  newGame(f) {
    let gm_id = this.idlize(f.value.name);
    let new_g = { ...f.value };
    new_g.value = Math.floor(
      Math.random() * (20 - 5 + 1) + 5
    );
    new_g.id = gm_id;
    this.util.afd
      .object(`dbz/games/${gm_id}`)
      .update(new_g);
    this.util.dlogx();
  }

  updateImage() {
    this.util.afd
      .object(`dbz/games/${this.current_game[0]}`)
      .update({
        image: this.current_game[1],
        css_class: this.current_game[2],
        full_name: this.current_game[3],
        screenshots: this.current_game[4],
      });

    this.util.dlogx();
  }
}
