import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "p-dash",
  templateUrl: "./p-dash.html",
  styleUrls: ["./p-dash.scss"],
})
export class ProfileDash implements OnInit {
  navs = [
    {
      name: "Account",
      link: "/profile",
      icon: "fas fa-user",
      active: "bob-csw",
    },
    {
      name: "Bookings",
      link: "/profile/booking",
      icon: "fas fa-calendar",
      active: "bob-c0 c0",
    },
    {
      name: "Favorites",
      link: "/profile/favs",
      icon: "fas fa-heart",
      active: "bob-ccp ccp",
    },
    {
      name: "Events",
      link: "/profile/party",
      icon: "fas fa-calendar-alt",
      active: "bob-cck cck",
    },
  ];

  constructor(public util: Utilities) {}

  ngOnInit(): void {}
}
