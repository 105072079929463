<ng-container *ngLet="util.storey.host_db as host">
  <div
    class="con-fin"
    *ngLet="getBal(host.bookings) as ball"
  >
    <div
      *ngIf="host"
      class="bg-bg1 csw brad shadow mx-auto my-2 wim6 p-4 text-center"
    >
      <div class="row mb-3">
        <h4 class="mt-3 b9 fw-2 col-6">
          <span class="fw-7 display-4"> $ {{ball[0]}}</span>
          <br />
          PENDING
        </h4>
        <h4 class="mt-3 csw fw-2 col-6">
          <span class="fw-7 display-4"> $ {{ball[1]}}</span>
          <br />
          AVAILABLE
        </h4>
      </div>
      <button
        class="m-3 px-4 py-3 bg-ccp bgc brad3 fw-6 h4"
        (click)="stripeSetup()"
        *ngIf="!host.stripe_active && !str_load"
      >
        {{host.stripe_no ? 'SETUP' : 'OPEN ACCOUNT'}}
      </button>
      <button
        class="m-3 px-4 py-3 bg-ccp bgc brad3 fw-6 h4"
        *ngIf="!host.stripe_active && str_load"
      >
        <div
          class="spinner-border text-light"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </button>
      <button
        class="m-3 px-4 py-3 bg-ccg bgc brad3 fw-6 h4"
        (click)="openStripe()"
        *ngIf="host.stripe_active"
      >
        OPEN STRIPE
      </button>
    </div>
  </div>

  <!-- <div
    class="bg-bg2 bo-bg4 csw brad shadow mx-auto wim p-4 my-2 text-center"
  >
    <h4>Booking History</h4>

    <h5 class="shadow-sm brad m-2 py-3 bg-bg1 bob-bg4">
      No bookings yet
    </h5>
  </div> -->
</ng-container>
