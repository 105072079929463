<div class="p-2 text-center">
  <p class="justify-content-center d-flex">
    <input
      class="searcher shadow brad mx-2 csw p-3"
      type="text"
      [(ngModel)]="searcher"
      placeholder="Search"
    />
    &nbsp;
    <button
      (click)="util.dlogtr(new_game)"
      class="bg-bg1 c0 fs2 li0 px-4 py-3 brad"
    >
      +
    </button>
    &nbsp;
    <!-- * SYNC DB  -->
    <button
      (click)="syncG(util.storey.all_db.games)"
      class="bg-ccb cw li0 px-4 py-3 brad"
    >
      SYNC
    </button>
  </p>

  <ng-container
    *ngFor="let g of util.map2Arr(util.storey.all_db.games, 2) | searchFilter: searcher"
  >
    <div
      class="itemy itemy2 brads shadow-sm bg-bg1 csw p-1"
    >
      <img
        class="brads"
        width="100%"
        height="60px"
        [src]="g.image || '/assets/robot.png'"
        (dblclick)="util.show(g)"
      />
      <p class="text-truncate fs07 m-0">{{g.name}}</p>
      <p class="fs07 m-0">
        <button
          class="m-1 bg-bg1 c0 box2 brad"
          (click)="current_game = [g.id, util.map2Arr(g.consoles, 1) ?? [], g.categories ?? []]; util.dlogtr(update_game_concat)"
        >
          <i class="fas fa-hdd"></i>
        </button>

        <button
          class="m-1 bg-bg1 c0 box2 brad"
          (click)="current_game = [g.id, g.image, g.css_class, g.full_name, g.screenshots ?? []]; util.dlogtr(update_image)"
        >
          <i class="fas fa-image"></i>
        </button>
      </p>
    </div>
  </ng-container>
</div>

<!-- * UPDATE IMAGE  -->
<ng-template #update_image>
  <div
    class="position-relative p-4 brad bg-bg1 bo3-bg4 wim text-center"
  >
    <span matDialogClose class="h3 ccr poi exiter"
      ><i class="fas fa-times-circle"></i
    ></span>
    <div class="overflow-auto hhh">
      <p>
        <input
          class="bg-bg1 p-3 csw shadow my-2 brad wi4"
          name="full_name"
          type="text"
          [(ngModel)]="current_game[3]"
        />
      </p>
      <p>
        <input
          class="bg-bg1 p-3 csw shadow my-2 brad wi4"
          name="name"
          type="text"
          [(ngModel)]="current_game[1]"
        />
      </p>
      <p>
        <input
          class="bg-bg1 p-3 csw shadow my-2 brad wi4"
          name="css_class"
          type="text"
          [(ngModel)]="current_game[2]"
        />
      </p>
      <p class="csw">Screenshots:</p>
      <p *ngFor="let scr of [0,1,2,3,4,5,6]">
        <input
          class="bg-bg1 p-3 csw shadow my-1 brad wi4"
          name="name"
          type="text"
          [(ngModel)]="current_game[4][scr]"
        />
      </p>
      <p>
        <button
          (click)="updateImage()"
          class="px-3 py-2 bg-csw bg1"
        >
          UPDATE IMAGE
        </button>
      </p>
    </div>
  </div>
</ng-template>

<!-- * UPDATE GAME CONSOLES AND CATEGORIES -->
<ng-template #update_game_concat>
  <ng-container
    *ngLet=" util.storey.all_db.consoles as cons"
  >
    <div
      class="brad bg-bg1 csw p-4 text-center"
      *ngLet="idCon(util.map2Arr(util.storey.all_db.consoles, 2)) as conx"
    >
      <mat-form-field appearance="fill" class="bg-bg1 csw">
        <mat-label>Consoles</mat-label>
        <mat-select
          #upcon
          multiple
          [(ngModel)]="current_game[1]"
        >
          <mat-option *ngFor="let c of conx" [value]="c"
            >{{cons[c].name}}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="bg-bg1 csw">
        <mat-label>Categories</mat-label>
        <mat-select
          #upcat
          multiple
          [(ngModel)]="current_game[2]"
        >
          <mat-option
            *ngFor="let cat of idCat()"
            [value]="cat"
            >{{cat}}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <p class="mt-4">
        <button
          class="bg-c0 bg1 brad2 px-3 py-2"
          (click)="updateConCat()"
        >
          Update
        </button>
      </p>
    </div>
  </ng-container>
</ng-template>

<!-- * NEW GAME  -->
<ng-template #new_game>
  <div
    class="position-relative p-4 brad bg-bg1 bo3-bg4 wim text-center"
  >
    <span matDialogClose class="h3 ccr poi exiter"
      ><i class="fas fa-times-circle"></i
    ></span>
    <div>
      <form class="" #new_form="ngForm">
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            name="name"
            type="text"
            placeholder="Name"
            ngModel
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            name="image"
            type="text"
            placeholder="Image"
            ngModel
          />
        </p>
        <p>
          <mat-form-field
            appearance="fill"
            class="bg-bg1 csw"
          >
            <mat-label>Consoles</mat-label>
            <mat-select name="consoles" ngModel multiple>
              <mat-option
                *ngFor="let c of util.map2Arr(util.storey.all_db.consoles, 2)"
                [value]="c.id"
                >{{c.name}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field
            appearance="fill"
            class="bg-bg1 csw"
          >
            <mat-label>Categories</mat-label>
            <mat-select name="categories" ngModel multiple>
              <mat-option
                *ngFor="let ca of util.categories | keyvalue"
                [value]="ca.key"
                >{{ca.key}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </p>
        <!-- <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            type="number"
            placeholder="value"
            name="value"
            ngModel
          />
        </p> -->
      </form>
      <p>
        <button
          (click)="newGame(new_form)"
          class="px-3 py-2 bg-csw bg1"
        >
          ADD
        </button>
      </p>
    </div>
  </div>
</ng-template>
