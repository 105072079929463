<div class="container">
  <h3 class="my-4 text-center">
    <span class="px-5 py-2 bg-bg2 brad c0">ROADMAP</span>
  </h3>

  <div class="timeline">
    <div
      *ngFor="let r of roads"
      class="timeline-container primary"
    >
      <div class="brad bg-bg2 p-4 shadow my-3">
        <h6
          class="bg-bg1 brad shadow-sm px-3 py-2 bbl"
          [ngClass]="{'ccg': r.mode === 'Done','ccb': r.mode === 'Progress','ccr': r.mode === 'Pending'}"
        >
          <span class="c0">{{r.title}}</span>
        </h6>
        <p class="my-4 csw">{{r.content}}</p>
        <p *ngIf="r.links">
          <a
            [href]="l.url"
            target="_blank"
            *ngFor="let l of r.links"
          >
            <button
              class="brad p-2 bg-bg1 bo-bg4 cck mx-2 my-1"
            >
              {{l.name}}
            </button></a
          >
        </p>
      </div>
    </div>
  </div>
</div>
