import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "help",
  templateUrl: "./help.html",
  styleUrls: ["./help.scss"],
})
export class FingazHelp {
  navs = [
    {
      name: "FAQ",
      link: "/help/faq",
    },
    {
      name: "Community",
      link: "/help/comms",
    },
    {
      name: "Terms",
      link: "/help/terms",
    },
    {
      name: "Privacy",
      link: "/help/privacy",
    },
    {
      name: "EULA",
      link: "/help/eula",
    },
  ];

  constructor(public util: Utilities) {}
}
