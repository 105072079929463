//* MODULES
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import {
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { SwiperModule } from "swiper/angular";
import { HttpClientModule } from "@angular/common/http";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { NgxMapboxGLModule } from "ngx-mapbox-gl";
import { Mat } from "./material";
import { CarouselModule } from "ngx-owl-carousel-o";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgxStripeModule } from "ngx-stripe";

import {
  CalendarModule,
  DateAdapter,
} from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { environment as env } from "@env/environment";
//* WIDGETS
import { AuthC } from "./widgets/auth/auth";
import { Content } from "./widgets/content";
import { TOS } from "./widgets/tos/tos";
import { Land } from "./widgets/land/land";
import { Parties } from "./widgets/parties/parties";
import { Maps } from "./widgets/maps/maps";
import { RoadMap } from "./widgets/roadmap/roadmap";
import { Loader } from "./widgets/loader/loader";
import { SearchBar } from "./widgets/search-bar/search-bar";
import { Faq } from "./widgets/faq/faq";
import { Slots } from "./widgets/slots/slots";
import { Slot } from "./widgets/slots/slot";
import { CalendarHeader } from "./widgets/cal-header";
import { Buyer } from "./widgets/buyer/buyer";
import { GameBook } from "./widgets/slots/game-book";
import { Shop } from "./widgets/shop/shop";
import { Party } from "./widgets/parties/party";
import { Product } from "./widgets/shop/product";
import { ConfirmPay } from "./widgets/confirmpay/confirmpay";
//* ADMIN WIDGETS
import { Admin } from "./admin/admin";
import { AdUsers } from "./admin/ad-users/ad-users";
import { AdHosts } from "./admin/ad-hosts/ad-hosts";
import { AdGames } from "./admin/ad-games/ad-games";
import { AdParties } from "./admin/ad-parties/ad-parties";
import { AdConsoles } from "./admin/ad-consoles/ad-consoles";
import { AdGadgets } from "./admin/ad-gadgets/ad-gadgets";
import { AdShop } from "./admin/ad-shop/ad-shop";
import { AdBuyFingz } from "./admin/ad-buyfingz/ad-buyfingz";
import { AdSlots } from "./admin/ad-slots/ad-slots";
import { AdBookings } from "./admin/ad-bookings/ad-bookings";
import { AdStats } from "./admin/ad-stats/ad-stats";
//* PROFILE WIDGETS
import { ProfileDash } from "./profile/p-dash/p-dash";
import { ProfileHome } from "./profile/p-home/p-home";
import { ProfileBooking } from "./profile/p-booking/p-booking";
import { ProfileParty } from "./profile/p-party/p-party";
import { ProfilePurchases } from "./profile/p-purchases/p-purchases";
import { ProfileFavs } from "./profile/p-favs/p-favs";
//* HOSTS WIDGETS
import { Hosts } from "./host/hosts";
import { NewHost } from "./host/h-new/h-new";
import { HostDash } from "./host/h-dash/h-dash";
import { HostVerify } from "./host/h-verify/h-verify";
import { HostBookings } from "./host/h-bookings/h-bookings";
import { HostProfile } from "./host/h-profile/h-profile";
import { HostSlots } from "./host/h-slots/h-slots";
import { HostInventory } from "./host/h-inventory/h-inventory";
import { HostFinances } from "./host/h-finances/h-finances";
import { HostEditSlotCalendar } from "./host/h-slots/h-edit-slot-calendar";
import { HostEditSlotPrices } from "./host/h-slots/h-edit-slot-prices";
import { HPage } from "./host/h-page/h-page";
//* NAVS
import { Logo } from "./navs/logo/logo";
import { Feeter } from "./navs/feeter/feeter";
import { Navbar } from "./navs/navbar/navbar";
//*PAGES
import { FingazHelp } from "./pages/help/help";
import { About } from "./pages/about/about";
import { Fingz } from "./pages/fingz/fingz";
import { Cookies } from "./pages/cookies/cookies";
import { NotFound } from "./pages/not-found/not-found";
import { Privacy } from "./pages/privacy/privacy";
import { Terms } from "./pages/terms/terms";
import { Community } from "./pages/community/community";
import { EULA } from "./pages/eula/eula";
//* SERVICES
import { Utilities } from "@services/utilities";
import {
  AdminGuard,
  AuthGuard,
  FingazGuard,
} from "@services/guard.service";
//* DIRECTIVES
import { NumberDirective } from "@directives/numbers.directive";
import { PhoneDirective } from "@directives/phone.dir";
import { LetDirective } from "@directives/let.directive";
//* PIPES
import { OrderPipe } from "@pipes/order-by.pipe";
import { DoxPipe } from "@pipes/dox.pipe";
import { FilterPipe } from "@pipes/filter.pipe";
import {
  RandomOrderPipe,
  SievePipe,
} from "@pipes/sieve.pipe";
import { RandomPipe } from "@pipes/random.pipe";
import { DistancePipe } from "@pipes/distance.pipe";
import { DayPipe } from "@pipes/calendar/day.pipe";
import { StringDatePipe } from "@pipes/calendar/stringDate.pipe";
import { SearchFilterPipe } from "@pipes/search.pipe";

const modules = [
  CommonModule,
  NgbModule,
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  TabsModule,
  SwiperModule,
  NgxMaterialTimepickerModule,
  HttpClientModule,
  Mat,
  NgxStripeModule.forRoot(env.stripe),
  CalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  }),
  NgxMapboxGLModule.withConfig({
    accessToken: env.mapbox.access, // Optional, specify if different from the map access token, can also be set per mgl-geocoder (accessToken input of mgl-geocoder)
  }),
  CarouselModule,
];

//* prettier-ignore
const components = [
  //* widgets
  AuthC,
  Content,
  TOS,
  Land,
  Parties,
  Party,
  Maps,
  RoadMap,
  Loader,
  SearchBar,
  Faq,
  Slots,
  Slot,
  GameBook,
  CalendarHeader,
  Buyer,
  Shop,
  Product,
  ConfirmPay,
  //* admin-widgets
  Admin,
  AdUsers,
  AdHosts,
  AdGames,
  AdParties,
  AdConsoles,
  AdGadgets,
  AdBuyFingz,
  AdShop,
  AdSlots,
  AdBookings,
  AdStats,
  //* profile-widgets
  ProfileDash,
  ProfileHome,
  ProfileBooking,
  ProfileParty,
  ProfilePurchases,
  ProfileFavs,
  //* host-widgets
  Hosts,
  NewHost,
  HostDash,
  HostVerify,
  HostBookings,
  HostProfile,
  HostSlots,
  HostInventory,
  HostFinances,
  HostEditSlotCalendar,
  HostEditSlotPrices,
  HPage,
  //* nav
  Logo,
  Feeter,
  Navbar,
  //* pages
  FingazHelp,
  About,
  Fingz,
  NotFound,
  Cookies,
  Privacy,
  Terms,
  Community,
  EULA,
];

//* prettier-ignore
const entry = [];

const directives = [
  NumberDirective,
  PhoneDirective,
  LetDirective,
];

//* prettier-ignore
const pipes = [
  OrderPipe,
  DoxPipe,
  FilterPipe,
  SievePipe,
  RandomPipe,
  DistancePipe,
  DayPipe,
  StringDatePipe,
  SearchFilterPipe,
  RandomOrderPipe,
];

//* prettier-ignore
const services = [
  AuthGuard,
  FingazGuard,
  AdminGuard,
  Utilities,
];

@NgModule({
  declarations: [
    ...components,
    ...entry,
    ...directives,
    ...pipes,
  ],
  imports: [...modules],
  exports: [...components, ...entry, ...modules, ...pipes],
  providers: [...services, ...pipes],
  entryComponents: [...entry],
})
export class Components {}
