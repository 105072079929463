import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "game-book",
  templateUrl: "./game-book.html",
  styleUrls: ["./slots.scss"],
})
export class GameBook implements OnInit {
  @Input() slot;
  curr_user;
  wallet;
  curr_times = {};
  total_time = 0;
  is_booking;

  constructor(
    public util: Utilities,
    private el: ElementRef,
    private renr: Renderer2
  ) {}

  ngOnInit(): void {
    let maxx = `${(window.innerHeight * 3) / 4}px`;
    let ell = this.el.nativeElement.querySelector(".conty");
    this.renr.setStyle(ell, "height", maxx);

    this.util.selly().subscribe(async (x) => {
      this.curr_user = x?.user;
      this.wallet = x?.wallet;
    });
  }

  timeClick(d, t) {
    if (this.curr_times?.[d]?.[t.key]) {
      delete this.curr_times[d][t.key];
      if (this.util.lo_isEmpty(this.curr_times[d])) {
        delete this.curr_times[d];
      }
      this.total_time--;
    } else {
      this.util.lo_setWith(
        this.curr_times,
        `${d}.${t.key}`,
        true,
        Object
      );
      this.total_time++;
    }
  }

  bookCheck(temp) {
    // let book_cost = this.slot.cost * this.total_time;
    // if (this.util.is_connected) {
    //   let wallet = this.util.storey.wallet;
    //   let funded = wallet.fingz > book_cost;
    //   if (funded) {
    //     this.util.openConfirm({
    //       type: "game",
    //       cost: book_cost,
    //       schedule: this.curr_times,
    //       slot: this.slot,
    //     });
    //   } else {
    //     this.util.openBuyer();
    //     this.util.wuToast(
    //       "BUY FINGZ",
    //       "You don't have enough FINGZ to complete this booking"
    //     );
    //   }
    // } else {
    //   this.util.dlogx();
    //   this.util.openAuth("in");
    //   this.util.wuToast("Sign In", "Sign In to Book Game");
    // }
  }
}
