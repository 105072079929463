import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "ad-gadgets",
  templateUrl: "./ad-gadgets.html",
  styleUrls: ["../admin.scss"],
})
export class AdGadgets {
  current_gadget;
  filtered_consoles;
  searcher;

  constructor(public util: Utilities) {}

  cFilter(consoles) {
    let c = this.current_gadget[1];
    if (c) {
      this.filtered_consoles = [...consoles].filter(
        (x) => !c.includes(x.id)
      );
    } else {
      this.filtered_consoles = [...consoles];
    }
    console.log(this.filtered_consoles);
  }

  updateConsoles(v) {
    let new_c = [
      ...(this.current_gadget[1] || []),
      ...v.value,
    ];

    this.util.afd
      .object(`Consoles/${this.current_gadget[0]}`)
      .update({ consoles: new_c });

    this.util.dlogx();
  }

  newGadget(f) {
    let gid = ``;
    let gadget = { ...f.value };
    gadget.id = gid;
    this.util.afd.object(`Gadgets/${gid}`).update(gadget);
    this.util.dlogx();
  }

  updateImage() {
    this.util.afd
      .object(`Gadgets/${this.current_gadget[0]}`)
      .update({ image: this.current_gadget[1] });
    this.util.dlogx();
  }
}
