export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAB0tie9gRgUj97pyMQ5GpZ1S5yX4kIpkM",
    authDomain: "fingaz-tcd.firebaseapp.com",
    databaseURL: "https://fingaz-tcd.firebaseio.com",
    projectId: "fingaz-tcd",
    storageBucket: "fingaz-tcd.appspot.com",
    messagingSenderId: "603870050930",
    appId: "1:603870050930:web:dabc62aabebb4c8aa660eb",
    measurementId: "G-LBF7RFS5J8",
  },
  stripe:
    "pk_test_51LiP1ZCMqZ2rPGEyX1tFYy7RkvsVsJNOT4yItvlVKDgHdYVgEQ4AxJJSrCjzgflf3z2pVc693IsEDbdra5hhjgIS001GhYxEXh",
  stripe_live:
    "pk_live_51LiP1ZCMqZ2rPGEympfwGGOUF80gctRQ89AXYX5p2bsXfJwQJFRK6IdmfaDJNL3G64X1m9mgEQ0VMt3J9xHJtI3p00c6Abeo6z",
  mapbox: {
    access:
      "pk.eyJ1IjoiZmluZ2F6IiwiYSI6ImNreDZ5N3poczJwZTkycG9iazJvcTVveHcifQ.Gx2Pj8qBoTJ9x9SschnOEQ",
    geocoder: "",
  },
};
