import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "h-inventory",
  templateUrl: "./h-inventory.html",
  styleUrls: ["../hosts.scss"],
})
export class HostInventory implements OnInit {
  constructor(public util: Utilities) {}

  ngOnInit() {}

  //* OPEN LOCATION
  openLocation(l) {
    this.util.router.navigate([`host/inventory/${l}`]);
  }
}
