// <reference types="@types/googlemaps" />
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "distance",
})
export class DistancePipe implements PipeTransform {
  constructor() {}

  transform(value: any): string {
    // const a = new google.maps.LatLng(37.782624, -122.404539);
    // const b = new google.maps.LatLng(value._lat, value._long);
    // const meter = google.maps.geometry.spherical.computeDistanceBetween(a, b);
    // const feet = meter * 3.28;
    // const miles = feet / 5280;
    // let distString =
    //   miles >= 1 ? Math.round(miles) + " miles" : Math.round(feet) + " feet";
    return "constr";
  }
}
