<div
  class="position-relative p-1 brad bg-c0 box2 wim text-center"
>
  <span matDialogClose class="h3 b1 poi exiter"
    ><i class="fas fa-times-circle"></i
  ></span>

  <h3 class="b2 fw-6 py-3">
    FINGZ PACKAGES &nbsp;
    <span class="h5 b4"
      ><i class="fas fa-question-circle"></i
    ></span>
  </h3>
  <div class="bg-b4 overflow-hidden brad box2 p-0">
    <div
      *ngFor="let t of tiers"
      class="row mx-1 my-1 poi"
      (click)="buyFingz(t)"
    >
      <div class="col-5 cwe fw-6 bg-b1 brads m-0 py-3 h3">
        ${{t.dollar}}
      </div>
      <div
        class="col-7 m-0 bol-b4 bg-b0 brads py-3 fw-7 text-center"
      >
        <span
          class="cmain px-4 py-3 h5 m-0 fw-7 wib text-center"
        >
          <img
            class="mb-1"
            [style.width.em]="1.2"
            [style.height.em]="1.2"
            src="/assets/fingz.png"
            alt=""
          />
          &nbsp;
          <span class="spa2">{{t.fingz |number}}</span>
        </span>
      </div>
    </div>
  </div>
</div>

<ng-template #stryper>
  <div class="bg-bg1 brad p-5 box2">
    <ngx-stripe-card
      [options]="cardOptions"
      [elementsOptions]="elementsOptions"
    ></ngx-stripe-card>
  </div>
</ng-template>
