<div class="con-fin">
  <div *ngIf="favs.length > 0" class="frow f2row px-3">
    <ng-container *ngFor="let fav of favs">
      <div
        *ngLet="util.storey.all_db.dummy.slot_map[fav] as slot"
        class="fcol p-2 csw poi"
      >
        <div class="slotslide brad shadow-sm">
          <span (click)="removeFav(slot)" class="slotfav">
            <i class="fas fa-trash-alt ccr"></i>
          </span>
          <span class="slotrating fw-6">
            <i class="fas fa-star"></i> {{slot.rating}}
          </span>
          <swiper
            [slidesPerView]="1"
            [pagination]="true"
            [navigation]="true"
            [centeredSlides]="true"
            effect="fade"
            [thumbs]="{ swiper: thum }"
            class="my-swiper"
          >
            <ng-template swiperSlide>
              <img
                width="100%"
                class="brad mimg"
                [src]="slot.images[0]"
                alt=""
              />
            </ng-template>
            <ng-template swiperSlide>
              <img
                width="100%"
                class="brad mimg"
                [src]="slot.images[0]"
                alt=""
              />
            </ng-template>
          </swiper>
          <div
            (click)="oSlot(slot.host_name)"
            class="px-3 py-2"
          >
            <p class="fw-7 my-1 h5">{{slot.host_name}}</p>
            <p class="fw-7 ccp my-1">{{slot.console}}</p>
            <p class="fw-3 my-1 fs07">{{slot.location}}</p>
            <p class="fw-8 my-1">
              $ {{slot.game_cost}} / hr
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="favs.length < 1"
    class="p-5 text-center mx-auto"
  >
    <h3 class="csw">You have no favorite hosts.</h3>

    <button
      class="bg-ccp bg1 px-4 py-3 m-2 brad2 h3 poi fw-7 fs8"
      routerLink="/games"
    >
      FIND HOSTS
    </button>
  </div>
</div>
