import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

import { StripeService } from "ngx-stripe";
import SwiperCore, {
  Keyboard,
  Pagination,
  Navigation,
  Thumbs,
} from "swiper";
import { firstValueFrom } from "rxjs";

SwiperCore.use([Keyboard, Pagination, Navigation, Thumbs]);

@Component({
  selector: "slot",
  templateUrl: "./slot.html",
  styleUrls: ["./slots.scss"],
})
export class Slot implements OnInit {
  curr_user = this.util.storey.user_db;
  joining = false;
  wallet;
  curr_times = {};
  total_time = 0;
  is_booking;
  av_dates = this.util.getAvailableDates();
  schd_map = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
  };

  social = [
    {
      icon: "fas fa-copy",
      bg: "bg-b4 cw",
    },
    {
      icon: "fab fa-facebook",
      bg: "fabg cw",
    },
    {
      icon: "fab fa-twitter",
      bg: "twibg cw",
    },
    {
      icon: "fab fa-whatsapp",
      bg: "watbg cw",
    },
  ];

  scrollx = [0, 0, 4000];
  curr_date = [0, this.av_dates[0]];
  total_cost = 0;
  total_hcost = 0;

  constructor(
    public util: Utilities,
    private stripe: StripeService
  ) {}

  ngOnInit(): void {
    if (window.location.search.length > 0) {
      this.stripeCheck(window.location.search);
    }
  }

  checkAv(sch) {
    let sk = {};
    sch.forEach((s) => {
      sk[s.day] = Object.values(s.times).filter(
        (j) => j === "open"
      ).length;
    });

    let filx = this.av_dates.filter((v) => {
      let dd = this.util.dpipe.transform(v, "EEEE");
      return sk[dd] > 0;
    });

    return filx;
  }

  async stripeCheck(qu) {
    let reff = decodeURI(qu.substring(qu.indexOf("=") + 1));
    await new Promise((r) => setTimeout(r, 1000));
    let session =
      this.util.storey.all_db.gamers[this.curr_user.uid]
        .payments[reff];
    if (qu.substring(0, 8) === "?success") {
      if (session?.id && session?.status === "attempted") {
        let is_paid: any = await firstValueFrom(
          this.util.http.post(
            "https://api.techceed.org:5201/getSession",
            {
              sid: session.id,
            }
          )
        );

        if (is_paid.payment_status === "paid") {
          this.util.afd
            .object(
              `dbz/gamers/${this.curr_user.uid}/payments/${reff}`
            )
            .update({
              status: "paid",
            });

          this.util.afd
            .object(
              `dbz/gamers/${this.curr_user.uid}/bookings`
            )
            .update({
              [reff]: session.data.schedule,
            });

          this.util.afd
            .object(
              `dbz/hosts/${session.data.host_uid}/bookings`
            )
            .update({
              [reff]: {
                schedule: session.data.schedule,
                status: "booked",
                earn: session.data.h_cost,
              },
            });

          this.util.suToast("Payment Successful", "");
          this.navReset();
        } else {
          this.util.afd
            .object(
              `dbz/gamers/${this.curr_user.uid}/payments/${reff}`
            )
            .update({
              status: "cancelled",
            });
          this.util.errToast("Payment Error", "");
          this.navReset();
        }
      } else {
        this.quError();
        console.log("No Session");
      }
    } else if (qu.substring(0, 7) === "?cancel") {
      if (session.id && session.status === "attempted") {
        this.util.afd
          .object(
            `dbz/gamers/${this.curr_user.uid}/payments/${reff}`
          )
          .update({
            status: "cancelled",
          });
        this.util.errToast("Payment Cancelled", "");
        this.navReset();
      } else {
        this.quError();
      }
    } else {
      this.quError();
    }
  }

  quError() {
    this.util.errToast("Payment Error", "");
    this.navReset();
  }

  navReset() {
    this.util.router.navigate([
      this.util.storey.router.url.split("?")[0],
    ]);
  }

  getBookings(bk) {
    let bk_map = {};
    if (bk) {
      Object.values(bk).forEach((b: any) => {
        let jj = JSON.parse(b.schedule);
        Object.keys(jj).forEach((j) => {
          bk_map[j] = true;
        });
      });
    }
    return bk_map;
  }

  checkHr(slot, bk, h) {
    let hh = this.util.dpipe.transform(h, "Ho");
    let dd = this.util.dpipe.transform(
      this.curr_date[1],
      "EEEE"
    );
    let status =
      slot.schedule?.[this.schd_map[dd]].times[hh];
    return (
      status === "open" && !bk[`${this.curr_date[1]}|${hh}`]
    );
  }

  checkOpen(h) {
    let hh = this.util.dpipe.transform(h, "Ho");
    return this.curr_times[`${this.curr_date[1]}|${hh}`];
  }

  addHour(h, c, hc) {
    let hh = this.util.dpipe.transform(h, "Ho");
    this.curr_times[`${this.curr_date[1]}|${hh}`] =
      !this.curr_times[`${this.curr_date[1]}|${hh}`];

    this.total_cost =
      Object.values(this.curr_times).filter(Boolean)
        .length * c;
    this.total_hcost =
      Object.values(this.curr_times).filter(Boolean)
        .length * hc;
  }

  clearBooks() {
    this.curr_times = {};
    this.total_cost = 0;
  }

  bookG(temp, sl) {
    if (sl) {
      this.clearBooks();
      this.util.dlogtr(temp);
    } else {
      this.util.wuToast("No Slots", "All slots are booked");
    }
  }

  async booker(slot) {
    if (this.util.is_connected) {
      if (slot.hid && slot.stripe) {
        let metadatax = {
          uid: this.util.storey.user.uid,
          host_stripe: slot.stripe,
          host_uid: slot.hid,
          host_name: slot.host_name,
          cost: this.total_cost,
          h_cost: this.total_hcost,
          schedule: JSON.stringify(this.curr_times),
        };
        let session: any = await firstValueFrom(
          this.util.http.post(
            "https://api.techceed.org:5201/bookGame",
            {
              success_url: window.location.href,
              cancel_url: window.location.href,
              price: this.total_cost * 100,
              email: this.util.storey.user.email,
              metadata: metadatax,
            }
          )
        );
        console.log(session);

        this.stripe
          .redirectToCheckout({
            sessionId: session.id,
          })
          .subscribe((result) => {
            if (result.error) {
              alert(result.error.message);
            }
          });
      } else {
        this.util.wuToast("Coming Soon", "");
      }
    } else {
      this.util.openAuth("up");
    }
  }
}
