import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "h-edit-slot-prices",
  templateUrl: "./h-edit-slot-prices.html",
  styleUrls: ["../hosts.scss"],
})
export class HostEditSlotPrices implements OnInit {
  @Input() keys;
  @Input() console;
  prices;
  dollar_prices;
  pr_up;

  constructor(
    public util: Utilities,
    private el: ElementRef,
    private renr: Renderer2
  ) {}

  ngOnInit(): void {
    let maxx = `${(window.innerHeight * 2) / 3}px`;
    let ell = this.el.nativeElement.querySelector(".divy");
    this.renr.setStyle(ell, "height", maxx);
    this.prices = { ...this.console.prices } ?? {};
    this.dollar_prices = Object.entries(this.prices).reduce(
      (p: any, [k, v]: any) => ({ ...p, [k]: v / 100 }),
      {}
    );
  }

  async updatePrices() {
    this.prices = Object.entries(this.dollar_prices).reduce(
      (p: any, [k, v]: any) => ({ ...p, [k]: v * 100 }),
      {}
    );
    let path1 = `locations/${this.keys[0]}/consoles/${
      this.keys[1]
    }/${this.console.cid - 1}/prices`;

    this.util.afd
      .object(
        `dbz/hosts/${this.util.storey.host_db.id}/${path1}`
      )
      .update(this.prices);
    this.util.suToast("Prices Updated", "");
  }
}
