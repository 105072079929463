import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Utilities } from "./utilities";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private util: Utilities) {}
  async canActivate(): Promise<boolean> {
    if (JSON.parse(localStorage.getItem("user"))) {
      this.util.router.navigate(["/profile"]);
      return false;
    }
    return true;
  }
}

@Injectable()
export class FingazGuard implements CanActivate {
  constructor(private util: Utilities) {}
  async canActivate(): Promise<boolean> {
    if (!JSON.parse(localStorage.getItem("user"))) {
      this.util.router.navigate(["/signin"]);
      return false;
    }
    return true;
  }
}

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private util: Utilities) {}
  async canActivate(): Promise<boolean> {
    if (!JSON.parse(localStorage.getItem("is_admin"))) {
      this.util.router.navigate(["/"]);
      return false;
    }
    return true;
  }
}
