<div
  *ngLet="util.getId(util.storey.router.url) as pid"
  class="p-4"
>
  <div *ngLet="util.storey.all_db as db">
    <div
      *ngLet="db.store[pid] as pr"
      class="container-xl text-center"
    >
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="overh p-2 brad shadow bg-cw">
            <swiper
              [slidesPerView]="1"
              [navigation]="true"
              [centeredSlides]="true"
              [keyboard]="{
          enabled: true
          }"
              [thumbs]="{ swiper: thum }"
              class="my-swiper"
            >
              <ng-template
                *ngFor="let i of images"
                swiperSlide
              >
                <img [src]="i" alt=""
              /></ng-template>
            </swiper>

            <swiper
              (swiper)="thumbsSwiper = $event"
              [spaceBetween]="10"
              [slidesPerView]="4"
              [freeMode]="true"
              [watchSlidesProgress]="true"
              class="thumber"
            >
              <ng-template
                *ngFor="let i of images"
                swiperSlide
              >
                <img [src]="i" alt=""
              /></ng-template>
            </swiper>
          </div>
        </div>
        <div class="col-md-6 col-12 text-left">
          <h5 (dblclick)="util.show(pr)" class="my-3">
            {{pr.title}}
          </h5>
          <p
            class="fw-7 py-2 csw display-4 m-2 brad2 poi"
            (click)="util.singleLink('shop', sh.id)"
          >
            <img
              class="mb-1 mr-1"
              [style.width.px]="45"
              [style.height.px]="45"
              src="/assets/fingz.png"
              alt=""
            />
            {{ util.fingy(pr.price) }}
          </p>
          <p class="csw">{{pr.description}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
