export const mo_social = [
  // {
  //   href: "https://www.facebook.com/fingaz.gaming",
  //   image: "assets/social/facebook.svg",
  // },
  {
    href: "https://twitter.com/fingaz_gaming",
    image: "assets/social/twitter.svg",
  },
  // {
  //   href: 'https://fingaz.app',
  //   image: 'assets/social/linkedin.svg',
  // },
  {
    href: "https://discord.gg/K2cmehUr",
    image: "assets/social/discord.svg",
  },
  {
    href: "https://www.instagram.com/fingaz.games/",
    image: "assets/social/instagram.svg",
  },
  {
    href: "https://www.youtube.com/channel/UCijLBmohIoWASSHijUybREQ",
    image: "assets/social/youtube.svg",
  },
  {
    href: "https://www.twitch.tv/fingaz_gaming",
    image: "assets/social/twitch.svg",
  },
  // {
  //   href: "https://www.reddit.com/user/fingaz_gaming",
  //   image: "assets/social/reddit.svg",
  // },
];
