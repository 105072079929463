<div class="sticky-top">
  <search-bar [search_type]="search_params"></search-bar>
</div>
<div *ngIf="!util.is_connected" class="marg">
  <div class="con-fin">
    <p
      routerLink="/signup"
      class="display-4 my-0 px-3 cw text-center"
    >
      <img
        class="mx-auto brad poi"
        width="100%"
        src="/assets/banner.png"
        alt=""
      />
    </p>
  </div>
</div>
<div
  class="con-fin"
  [ngClass]="{'marg': util.is_connected}"
>
  <div class="frow px-3">
    <div
      *ngFor="let slotx of util.storey.all_db.dummy.slot_map | keyvalue | orderBy:'value.priority' | searchFilter: util.storey.search.slot"
      class="fcol p-2 csw poi"
    >
      <div
        class="slotslide brad shadow-sm"
        *ngLet="slotx.value as slot"
      >
        <span class="slotfav">
          <i
            class="fas fa-heart fs15"
            [ngClass]="util.checkFav(slot.host_name) ? 'c0' : 'cbtr'"
          ></i>
        </span>
        <span (click)="util.addFav(slot)" class="slotfav">
          <i class="far fa-heart cw fs15"></i>
        </span>

        <span class="slotrating fw-6">
          <i class="fas fa-star"></i> {{slot.rating}}
        </span>
        <swiper
          [slidesPerView]="1"
          [pagination]="true"
          [navigation]="true"
          [centeredSlides]="true"
          effect="fade"
          [thumbs]="{ swiper: thum }"
          class="my-swiper"
        >
          <ng-template swiperSlide>
            <img
              width="100%"
              class="brad mimg"
              [src]="util.imSizer(slot.images[0])"
              alt=""
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              width="100%"
              class="brad mimg"
              [src]="util.imSizer(slot.images[0])"
              alt=""
            />
          </ng-template>
        </swiper>
        <div
          (click)="oSlot(slot.host_name)"
          class="px-3 py-2"
        >
          <p class="fw-7 my-1 h5">{{slot.host_name}}</p>
          <p class="fw-7 c0 my-1">{{slot.console}}</p>
          <p class="fw-3 my-1 fs07">{{slot.location}}</p>
          <p class="fw-8 my-1">$ {{slot.game_cost}} / hr</p>
        </div>
      </div>
    </div>
  </div>
</div>
