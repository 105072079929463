import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormGroup,
  AbstractControlOptions,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Utilities } from "@services/utilities";

@Component({
  selector: "auth",
  templateUrl: "./auth.html",
  styleUrls: ["./auth.scss"],
})
export class AuthC implements OnInit {
  auth_form: FormGroup;
  auth_type;
  verify = false;
  verifyUser;
  modex;
  is_link = false;
  curr_out;

  socials = [
    {
      name: "Google",
      icon: "fab fa-google",
      img: "https://i.imgur.com/tMnb70j.png",
      bg: "goobg cw",
      auth: () => {
        this.util.googleAuth();
      },
    },
    {
      name: "Apple",
      icon: "fab fa-apple",
      img: "https://i.imgur.com/PUNkpBU.png",
      bg: "bg-b1 cw",
      auth: () => {
        this.util.appleAuth();
      },
    },
    {
      name: "Facebook",
      icon: "fab fa-facebook",
      img: "https://i.imgur.com/oBwpc3X.png",
      bg: "fabg cw",
      auth: () => {
        // this.util.wuToast("Coming Soon", "");
        this.util.fbAuth();
      },
    },
    {
      name: "Twitter",
      icon: "fab fa-twitter",
      img: "https://i.imgur.com/KzUi4Ua.png",
      bg: "twibg cw",
      auth: () => {
        this.util.twAuth();
      },
    },
  ];

  constructor(public util: Utilities) {}

  ngOnInit() {
    if (
      window.location.pathname.substring(0, 7) === "/signup"
    ) {
      this.modex = "up";
      this.is_link = true;
    } else if (
      window.location.pathname.substring(0, 7) === "/signin"
    ) {
      this.modex = "in";
      this.is_link = true;
    }

    this.auth_type =
      this.modex === "up" ? "Sign up" : "Sign in";
    this.setForm();
  }

  get af() {
    return this.auth_form.controls;
  }

  authy() {
    switch (this.auth_type) {
      case "Sign in":
        this.signIn();
        break;
      case "Sign up":
        this.signUp();
        break;
      case "Reset":
        this.recover();
        break;

      default:
        break;
    }
  }

  setForm() {
    this.auth_form = this.util.fb.group({
      fullname:
        this.auth_type === "Sign up"
          ? ["", Validators.required]
          : [""],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"
          ),
        ],
      ],
      password: !(this.auth_type === "Reset")
        ? [
            "",
            [Validators.required, Validators.minLength(6)],
          ]
        : [""],
      passwordConfirm:
        this.auth_type === "Sign up"
          ? ["", Validators.required]
          : [""],
    });

    this.pValidator(this.auth_type === "Sign up");
  }

  switchAuth() {
    switch (this.auth_type) {
      case "Sign in":
        this.auth_type = "Sign up";
        break;
      case "Sign up":
        this.auth_type = "Sign in";
        break;
      case "Reset":
        this.auth_type = "Sign in";
        break;

      default:
        break;
    }
    this.setForm();
    this.auth_form.markAsPristine();
  }

  pValidator(add) {
    if (add) {
      this.auth_form.addValidators(
        matchValidator(
          this.auth_form.get("password"),
          this.auth_form.get("passwordConfirm")
        )
      );
    } else {
      this.auth_form.setValidators(null);
    }
  }

  signIn() {
    this.util.afa
      .signInWithEmailAndPassword(
        this.auth_form.value.email,
        this.auth_form.value.password
      )
      .then((x) => {
        this.util.suToast("Success", "");
        if (this.is_link) {
          window.location.reload();
        }
        this.util.dlogx();
      })
      .catch((e) => {
        this.util.errToast("Error", e.message);
      });
  }

  signUp() {
    this.util.afa
      .createUserWithEmailAndPassword(
        this.auth_form.value.email,
        this.auth_form.value.password
      )
      .then((x) => {
        x.user.updateProfile({
          displayName: this.auth_form.value.fullname,
        });
        this.util.suToast("Success", "");
        if (this.is_link) {
          window.location.reload();
        }
        this.util.dlogx();
      })
      .catch((e) => {
        this.util.errToast("Error", e.message);
      });
  }

  recover() {
    this.util.afa
      .sendPasswordResetEmail(this.auth_form.value.email)
      .then((x) => {
        this.util.suToast(
          "Success",
          "Check your email and reset password"
        );

        this.switchAuth();
      })
      .catch((e) => {
        this.util.errToast("Error", e.message);
      });
  }
}

function matchValidator(
  control: AbstractControl,
  controlTwo: AbstractControl
): ValidatorFn {
  return () => {
    if (control.value !== controlTwo.value)
      return { match_error: "Value does not match" };
    return null;
  };
}
