export const dummy_evs = [
  {
    img: "https://cdn-cf.ginx.tv/respawn-cdn/82NIOZ81bnsmlERh9CPFL18CX53AMZtjhwQ9zuDaL3k/fill/1200/0/no/1/aHR0cHM6Ly93d3cuZ2lueC50di91cGxvYWRzMi8wLl9BZHZlcnRvcmlhbF9QaWN0dXJlcy9Fc3BvcnRzX0F3YXJkc18yMDIyL0VBLUdBLnBuZw.webp",
    name: "E-Sports Award",
    location: "Online",
    date: "4 February, 2023",
    price: "$5",
  },
  {
    img: "https://www.esportsbets.com/wp-content/uploads/2019/04/msi-trophy-3-800x450-1.jpg",
    name: "League of Legends MSI",
    location: "Online",
    date: "4 February, 2023",
    price: "$5",
  },
  {
    img: "https://www.esportsbets.com/wp-content/uploads/2021/05/fifae-world-cup-arena.jpg",
    name: "FIFA e World Cup",
    location: "San Francisco, California",
    date: "4 February, 2023",
    price: "$5",
  },
  {
    img: "https://www.esportsbets.com/wp-content/uploads/2019/08/nba-2k-league-betting-guide.jpg",
    name: "NBA 2k League",
    location: "San Francisco, California",
    date: "4 February, 2023",
    price: "$5",
  },
  {
    img: "https://www.esportsbets.com/wp-content/uploads/2019/08/call-of-duty-world-league-betting.jpg",
    name: "Call of Duty World League",
    location: "San Francisco, California",
    date: "4 February, 2023",
    price: "$5",
  },
  {
    img: "https://www.esportsbets.com/wp-content/uploads/2021/08/esl-pro-league-betting-guide-e1645539954353.jpg",
    name: "ESL Pro League Championships",
    location: "San Francisco, California",
    date: "4 February, 2023",
    price: "$5",
  },
  {
    img: "https://www.esportsbets.com/wp-content/uploads/2021/05/overwatch-league-betting-guide.jpg",
    name: "Overwatch League 5th Season",
    location: "San Francisco, California",
    date: "4 February, 2023",
    price: "$5",
  },
  {
    img: "https://www.esportsbets.com/wp-content/uploads/2020/01/dota-pro-circuit-predictions-2021.jpg",
    name: "Dota Pro Circuit",
    location: "San Francisco, California",
    date: "4 February, 2023",
    price: "$5",
  },
  {
    img: "https://www.esportsbets.com/wp-content/uploads/2021/01/lck-betting.jpg",
    name: "League of Legends Korea",
    location: "San Francisco, California",
    date: "4 February, 2023",
    price: "$5",
  },
  {
    img: "https://www.esportsbets.com/wp-content/uploads/2021/09/halo-championship-betting-guide-e1631877161533.jpg",
    name: "Halo Championship",
    location: "San Francisco, California",
    date: "4 February, 2023",
    price: "$5",
  },
  {
    img: "https://www.esportsbets.com/wp-content/uploads/2019/05/fortnite-pro-am-betting.jpeg",
    name: "Fortnite Pro Am League",
    location: "San Francisco, California",
    date: "4 February, 2023",
    price: "$5",
  },
  {
    img: "https://www.esportsbets.com/wp-content/uploads/2022/08/six-berlin-major-betting-guide.png",
    name: "6 Berlin Majodr League",
    location: "San Francisco, California",
    date: "4 February, 2023",
    price: "$5",
  },
];
