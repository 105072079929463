import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "land",
  templateUrl: "./land.html",
  styleUrls: ["./land.scss"],
})
export class Land implements OnInit {
  constructor(public util: Utilities) {}

  ngOnInit(): void {}
}
