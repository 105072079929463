import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Utilities } from "@services/utilities";
import {
  CalendarEvent,
  CalendarView,
} from "angular-calendar";
import { BreakpointState } from "@angular/cdk/layout";
import { WeekViewHourSegment } from "calendar-utils";

@Component({
  selector: "h-edit-slot-calendar",
  templateUrl: "./h-edit-slot-calendar.html",
  styleUrls: ["../hosts.scss"],
})
export class HostEditSlotCalendar implements OnInit {
  @Input() events;
  @Input() host;
  @Input() console;
  @Input() keys;
  ev_up = false;
  mod_events = [];

  viewDate = new Date();

  view: CalendarView = CalendarView.Week;

  minDate: Date = this.util.subDays(new Date(), 0);

  maxDate: Date = this.util.addWeeks(new Date(), 2);

  prevBtnDisabled: boolean = true;

  nextBtnDisabled: boolean = false;

  daysInWeek = 7;

  weekStartsOn: 0 = 0;

  private destroy$ = new this.util.rx_Subject<void>();

  CALENDAR_RESPONSIVE = {
    small: {
      breakpoint: "(max-width: 576px)",
      daysInWeek: 2,
    },
    medium: {
      breakpoint: "(max-width: 768px)",
      daysInWeek: 3,
    },
    large: {
      breakpoint: "(max-width: 960px)",
      daysInWeek: 5,
    },
  };

  constructor(
    public util: Utilities,
    private el: ElementRef,
    private renr: Renderer2
  ) {}

  ngOnInit(): void {
    this.syncEvents();

    let maxx = `${(window.innerHeight * 2) / 3}px`;
    let ell = this.el.nativeElement.querySelector(
      ".cal-week-view .cal-time-events"
    );
    this.renr.setStyle(ell, "max-height", maxx);
    this.util.brk
      .observe(
        Object.values(this.CALENDAR_RESPONSIVE).map(
          ({ breakpoint }) => breakpoint
        )
      )
      .pipe(this.util.rx_takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        const foundBreakpoint = Object.values(
          this.CALENDAR_RESPONSIVE
        ).find(
          ({ breakpoint }) =>
            !!state.breakpoints[breakpoint]
        );
        if (foundBreakpoint) {
          this.daysInWeek = foundBreakpoint.daysInWeek;
        } else {
          this.daysInWeek = 7;
        }
      });
  }

  syncEvents() {
    this.events.forEach((ev) => {
      Object.values(ev).forEach((dd: any) => {
        let d = { ...dd };
        d.start = new Date(d.start);
        if (d.title === "Booked") {
          d["color"] = {
            primary: "#ad2121",
            secondary: "#FAE3E3",
          };
          d["cssClass"] = "bookede";
        }

        this.mod_events.push(d);
      });
    });
  }

  increment(): void {
    this.changeDate(
      this.util.addDays(this.viewDate, this.daysInWeek)
    );
  }

  decrement(): void {
    this.changeDate(
      this.util.subDays(this.viewDate, this.daysInWeek)
    );
  }

  dateIsValid(date: Date): boolean {
    return (
      this.util.startOfDay(date) >=
        this.util.startOfDay(this.minDate) &&
      this.util.endOfDay(date) <=
        this.util.endOfDay(this.maxDate)
    );
  }

  changeDate(date: Date): void {
    if (
      this.util.endOfDay(date) <
        this.util.endOfDay(this.maxDate) ||
      this.util.startOfDay(date) >
        this.util.startOfDay(this.minDate)
    ) {
      this.viewDate = date;
    }
    this.prevBtnDisabled =
      this.util.startOfDay(date) <=
      this.util.startOfDay(this.minDate);
    this.nextBtnDisabled =
      this.util.endOfDay(date) >=
      this.util.endOfDay(this.maxDate);
  }

  weekRender(ev): void {
    ev.hourColumns.forEach((day, i) => {
      if (!this.dateIsValid(day.date)) {
        ev.header[i].cssClass = "cal-disabled";
        day.hours.forEach((hour) => {
          hour.segments.forEach((segment) => {
            segment.cssClass = "cal-disabled";
          });
        });
      }
    });
  }

  addEvent(segment: WeekViewHourSegment) {
    if (this.dateIsValid(segment.date)) {
      const newEvent: CalendarEvent = {
        id: segment.date.getTime(),
        title: "Open",
        start: segment.date,
        meta: segment.date.toDateString(),
      };
      this.mod_events = [...this.mod_events, newEvent];
    }
  }

  removeEvent(ev) {
    if (ev.title === "Open") {
      this.mod_events = this.mod_events.filter(
        (iEvent) => iEvent !== ev
      );
    }
  }

  async updateEvents() {
    this.ev_up = true;
    let path1 = `locations/${this.keys[0]}/consoles/${
      this.keys[1]
    }/${this.console.cid - 1}/schedules`;

    let event_map = this.mod_events.reduce((r, v) => {
      let d = this.util.startOfDay(v.id).getTime();

      this.util.lo_setWith(
        r,
        `${d}.${v.id}`,
        { ...v },
        Object
      );
      return r;
    }, {});

    this.util.afd
      .object(`dbz/hosts/${this.host.id}/${path1}`)
      .update(event_map);
    this.util.suToast("Schedules Updated", "");
    this.ev_up = false;
  }
}
