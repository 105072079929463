import { Component, OnInit } from "@angular/core";
import { roadmaps } from "@models/road.mo";

@Component({
  selector: "roadmap",
  templateUrl: "./roadmap.html",
  styleUrls: ["./roadmap.scss"],
})
export class RoadMap implements OnInit {
  roads = roadmaps;

  constructor() {}

  ngOnInit(): void {}
}
