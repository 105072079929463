<div class="con-fin" *ngLet="util.storey.all_db as db">
  <div *ngIf="bks.length > 0" class="frow f2row px-3">
    <ng-container *ngFor="let bk of bks">
      <div
        class="fcol p-2 csw poi"
        *ngLet="this.util.storey.user_db.payments[bk[1].pay_key] as bd"
      >
        <div
          class="slotslide brad shadow-sm"
          *ngLet="getHost(bd.data.host_name) as host"
        >
          <button
            (click)="openChat(bd, bk[1].day, util.hour_maps[bk[1].time], bk[1].pay_key, bk[0], chat)"
            class="chat bg-c0 px-2 py-1 h4 cw brad2"
          >
            <i class="fas fa-comment-dots"></i>
          </button>
          <button
            class="end-s bg-ccr px-3 py-1 h5 cw brad2"
            (click)="openEnd(end_s)"
            *ngIf="checkEnd(bk[0])"
          >
            End
          </button>
          <swiper
            [slidesPerView]="1"
            [pagination]="true"
            [navigation]="true"
            [centeredSlides]="true"
            effect="fade"
            [thumbs]="{ swiper: thum }"
            class="my-swiper"
          >
            <ng-template swiperSlide>
              <img
                width="100%"
                class="brad mimg"
                [src]="host.images[0]"
                alt=""
              />
            </ng-template>
            <ng-template swiperSlide>
              <img
                width="100%"
                class="brad mimg"
                [src]="host.images[0]"
                alt=""
              />
            </ng-template>
          </swiper>
          <div class="px-3 py-2">
            <p class="fw-7 my-1 h5">
              {{bd.data.host_name}}
            </p>
            <p class="fw-4 my-1 h5">
              <span class="fw-7"
                >{{util.hour_maps[bk[1].time] }}</span
              >
            </p>
            <p class="fw-4 my-1 h5">
              {{bk[1].day | dayF : 'do EEE MMM' }}
            </p>
            <p class="fw-3 my-1 fs07">{{host.location}}</p>
            <!-- <p>{{bd |json}}</p> -->
            <!-- <p class="fw-7 ccp my-1">{{slot.console}}</p>
            <p class="fw-3 my-1 fs07">{{slot.location}}</p>
            <p class="fw-8 my-1">$ {{slot.price}} / hr</p> -->
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="bks.length < 1"
    class="p-5 text-center mx-auto"
  >
    <h3 class="csw">You have no active bookings.</h3>

    <button
      class="bg-c0 bg1 px-4 py-3 m-2 brad2 h3 poi fw-7 fs8"
      routerLink="/games"
    >
      FIND GAMES
    </button>
  </div>

  <ng-template #chat>
    <div
      class="position-relative p-1 brad bg-bg2 shadow wim6 text-center"
    >
      <span matDialogClose class="h3 poi exiter csw">
        <i class="fas fa-times"></i
      ></span>

      <p class="csw py-2 fw-6 h6">
        {{curr_chat[0]}} - {{curr_chat[1]}} :
        {{curr_chat[2]}}
      </p>

      <div
        #chatb
        id="chatb"
        class="chatb mt-2 bot-bg3 bob-bg3 bg-bg1 px-3 py-3 fw-6"
      >
        <table width="100%" style="table-layout: fixed">
          <div class="chatm">
            <p class="ctr">
              ________________________________________________________________________________________________________
            </p>
            <div
              *ngFor="let c of this.util.storey.all_db.gamers[curr_chat[5]].payments[curr_chat[3]].chat?.[curr_chat[4]] | keyvalue"
              [ngClass]="{'chat-right' : c.value[0] === 'gamer', 'chat-left' : c.value[0] === 'host'}"
            >
              <div
                class="chas p-2 my-1 shadow-sm bg1"
                [ngClass]="{'bg-c0' : c.value[0] === 'gamer', 'bg-ccp' : c.value[0] === 'host'}"
              >
                {{c.value[1]}}
              </div>
            </div>
          </div>
        </table>
      </div>
      <div class="chaty pt-4">
        <p class="px-2">
          <textarea
            rows="1"
            class="mr-0 px-3 brad2 bo-bg3 csw"
            style="resize: none"
            type="text"
            placeholder="Aa"
            (keyup.enter)="chatSend(charea); charea.value = ''"
            #charea
          >
          </textarea>
          <button
            (click)="chatSend(charea); charea.value = '' "
            class="brad2 bg-c0 cw px-2 ml-2"
          >
            <i class="fas fa-paper-plane"></i>
          </button>
        </p>
      </div>
    </div>
  </ng-template>

  <ng-template #end_s>
    <div
      class="position-relative p-1 brad bg-bg2 shadow wim6 text-center"
    >
      <span matDialogClose class="h3 poi exiter csw">
        <i class="fas fa-times"></i
      ></span>

      <p class="csw py-2 fw-6 h6">
        {{curr_chat[0]}} - {{curr_chat[1]}} :
        {{curr_chat[2]}}
      </p>

      <div
        class="chatb mt-2 bot-bg3 bob-bg3 bg-bg1 px-3 py-3 fw-6"
      >
        <h4>Rate Your Experience</h4>
      </div>
    </div>
  </ng-template>
</div>
