import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "ad-buyfingz",
  templateUrl: "./ad-buyfingz.html",
  styleUrls: ["../admin.scss"],
})
export class AdBuyFingz {
  searcher;

  constructor(public util: Utilities) {}

  getOrders(orders) {
    return Object.entries(orders).sort(
      (x: any, y: any) => x[1].status - y[1].status
    );
  }

  buy(order) {
    let purchase: any = {};

    purchase
      .once("transactionHash", (txhash) => {
        console.log(["TRXHASH", txhash]);
      })
      .once("receipt", async (receipt) => {
        this.util.suToast("Success", "The order was sent");
        await this.util.afd
          .object(`BuyFingz/${order.id}`)
          .update({ status: "done" });
      })
      .on("error", (e) => {
        this.util.errToast(
          "Transaction Failed",
          "Transaction was not sucessful. Try Again"
        );
      });
  }
}
