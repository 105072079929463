import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "ad-shop",
  templateUrl: "./ad-shop.html",
  styleUrls: ["../admin.scss"],
})
export class AdShop implements OnInit {
  searcher;
  list;
  shop;
  shop_menu = [
    {
      name: "Consoles",
      fire: "consoles_asins",
    },
    {
      name: "Games",
      fire: "games_asins",
    },
    {
      name: "Gadgets",
      fire: "gadgets_asins",
    },
    {
      name: "Cards",
      fire: "gcards_asins",
    },
  ];

  constructor(public util: Utilities) {}

  ngOnInit(): void {
    this.util.subLink("shop").subscribe((x: any) => {
      this.shop = x;
    });
  }

  getArray(m) {
    return Object.values(m ?? {});
  }

  fingy(pr) {
    return Math.round(pr + (pr * 0.1) / 2);
  }

  async addPro(db, asin) {
    //  await this.util.afd.object(`shop/${db}`).update({
    //     [asin]: {
    //     }
    //   });

    let dd: any = (await this.getPro(asin))["result"][0];

    let pics = await this.getImage(asin);

    let data1 = {
      title: dd.title,
      description: dd.description,
      price: dd.price.current_price ?? 0,
      features: dd.feature_bullets,
    };

    this.util.afd.object(`shop/${db}/${asin}`).update({
      ...data1,
      ASIN: asin,
      link: `http://www.amazon.com/dp/${asin}`,
      images: pics,
    });
  }

  async getPro(fasin) {
    let aurl = "http://localhost:5201/pro1";
    let pro = await firstValueFrom(
      this.util.http.post(aurl, { asin: fasin })
    );
    return pro;
  }

  async getImage(iasin) {
    let purl = "http://localhost:5201/pro2";
    let pro: any = await firstValueFrom(
      this.util.http.post(purl, { fasin: iasin })
    );
    let aaa = JSON.parse(
      pro.slice(1, -2).replace(/'/g, '"')
    );

    return aaa.images;
  }

  synk() {
    this.list.forEach(async (e) => {
      let dd: any = (await this.getPro(e.ASIN))[
        "result"
      ][0];

      let data1 = {
        title: dd.title,
        description: dd.description,
        price: dd.price.current_price ?? 0,
        features: dd.feature_bullets,
      };

      this.util.afd
        .object(`shop/games_asins/${e.ASIN}`)
        .update({ ...data1 });
    });
  }

  synki() {
    this.list.forEach(async (e) => {
      let pics = await this.getImage(e.ASIN);

      this.util.afd
        .object(`shop/games_asins/${e.ASIN}`)
        .update({ images: pics });
    });
  }

  async shopSync(db) {
    Object.entries(this.shop).forEach((x) => {
      Object.entries(x[1]).forEach((y) => {
        let item: any = { ...y[1] };
        item.category = x[0].split("_")[0];
        let pr_id = `pid`; // ! CHECK

        this.util.afd.object(`Store/${pr_id}`).update(item);
      });
    });
  }
}
