import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "faq",
  templateUrl: "./faq.html",
  styles: [``],
})
export class Faq implements OnInit {
  constructor(public util: Utilities) {}

  async ngOnInit() {}
}
