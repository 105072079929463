export const fingazMenu = {
  admin_menu: [
    {
      link: "Admins",
      icon: "admins",
      url: "/admin/admins",
    },
    { link: "Hosts", icon: "hosts", url: "/admin/hosts" },
    {
      link: "Players",
      icon: "users",
      url: "/admin/players",
    },
    {
      link: "Platforms",
      icon: "platforms",
      url: "/admin/platforms",
    },
    {
      link: "Gadgets",
      icon: "gadgets",
      url: "/admin/gadgets",
    },
    { link: "Games", icon: "games", url: "/admin/games" },
    { link: "Slots", icon: "slots", url: "/admin/slots" },
    { link: "Faqs", icon: "faq", url: "/admin/faq" },
    {
      link: "Feedback",
      icon: "feedback",
      url: "/admin/feedback",
    },
    {
      link: "Subscribers",
      icon: "subscribers",
      url: "/admin/subscribers",
    },
    {
      link: "Analytics",
      icon: "analytics",
      url: "/admin/analytics",
    },
  ],
  host_menu: [
    {
      link: "Profile",
      icon: "profile",
      url: "/host/dashboard",
    },
    {
      link: "Inventory",
      icon: "platforms",
      url: "/host/inventory",
    },
    {
      link: "Schedule",
      icon: "slots",
      url: "/host/schedule",
    },
    { link: "Slots", icon: "games", url: "/host/slots" },
    {
      link: "Finances",
      icon: "finances",
      url: "/host/finances",
    },
  ],
  player_menu: [
    {
      link: "Profile",
      icon: "profile",
      url: "/player/profile",
    },
    // { link: "Games", icon: "games", url: "/player/games" },
    { link: "Slots", icon: "slots", url: "/player/slots" },
    {
      link: "Schedules",
      icon: "schedule",
      url: "/player/schedules",
    },
    {
      link: "Inbox",
      icon: "inbox",
      url: "/player/inbox",
    },
  ],
  game_categories: {
    action: {
      icon: "fas fa-bomb",
      name: "Action",
    },
    adventure: {
      icon: "fas fa-mountain",
      name: "Adventure",
    },
    board: {
      icon: "fas fa-chess",
      name: "Board",
    },
    coop: {
      icon: "fas fa-user-friends",
      name: "Coop",
    },
    flight: {
      icon: "fas fa-fighter-jet",
      name: "Flight",
    },
    puzzle: {
      icon: "fas fa-puzzle-piece",
      name: "Puzzle",
    },
    racing: {
      icon: "fas fa-flag-checkered",
      name: "Racing",
    },
    rpg: {
      icon: "fas fa-user-shield",
      name: "RPG",
    },
    simulation: {
      icon: "fab fa-unity ",
      name: "Simulation",
    },
    space: {
      icon: "fas fa-user-astronaut",
      name: "Space",
    },
    sports: {
      icon: "fas fa-basketball-ball",
      name: "Sports",
    },
    strategy: {
      icon: "fas fa-brain",
      name: "Strategy",
    },
    vr: {
      icon: "fas fa-vr-cardboard",
      name: "Virtual Reality",
    },
  },
  shop_categories: {
    consoles: {
      icon: "fas fa-hdd",
      name: "Consoles",
    },
    laptops: {
      icon: "fas fa-laptop",
      name: "Laptops",
    },
    controllers: {
      icon: "fas fa-gamepad",
      name: "Controllers",
    },
    games: {
      icon: "fas fa-compact-disc",
      name: "Video Games",
    },
    gadgets: {
      icon: "fas fa-mouse",
      name: "Gadgets",
    },
    giftcards: {
      icon: "fas fa-gift",
      name: "Gift Cards",
    },
    displays: {
      icon: "fas fa-tv",
      name: "Displays",
    },
    vr: {
      icon: "fas fa-vr-cardboard",
      name: "VR & AR",
    },
  },
};

export const empty_search = {
  slot: "",
  party: "",
  shop: "",
};

export const empty_filter = {
  slot: {
    t_consoles: {},
    t_categories: {},
    t_date: {},
  },
  party: {
    t_consoles: {},
    t_categories: {},
  },
  shop: {
    t_shop_categories: {},
  },
};

export const slot_filter_menu = [
  {
    name: "Consoles",
    icon: "fas fa-hdd",
    template: "t_consoles",
  },
  {
    name: "Categories",
    icon: "fas fa-grip-horizontal",
    template: "t_categories",
  },
  {
    name: "Date/Time",
    icon: "fas fa-calendar-day",
    template: "t_date",
  },
];

export const party_filter_menu = [
  {
    name: "Consoles",
    icon: "fas fa-hdd",
    template: "t_consoles",
  },
  {
    name: "Categories",
    icon: "fas fa-grip-horizontal",
    template: "t_categories",
  },
];

export const shop_filter_menu = [
  {
    name: "Categories",
    icon: "fas fa-grip-horizontal",
    template: "t_shop_categories",
  },
];
