import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "random"
})
export class RandomPipe implements PipeTransform {
  constructor() {}

  transform(value: any): string {
    let max = Math.floor(Math.random() * 50800) + 1;
    let aaa = max / 5280;
    let dist = aaa >= 1 ? Math.round(aaa) + " miles" : max + " feet";
    return dist;
  }
}
