export const dummy_slots = [
  {
    images: [
      "https://cdn.home-designing.com/wp-content/uploads/2020/09/gaming-room-ideas.jpg",
      "https://m.media-amazon.com/images/I/81+T-uMG8JL.jpg",
      "https://i.pinimg.com/originals/94/dc/83/94dc83111a5a18e0d3ccadaeeb21f004.jpg",
      "https://cdn.homedit.com/wp-content/uploads/2014/10/RGB-Lighting.jpg",
      "https://scholarlyoa.com/wp-content/uploads/2020/08/e15e6087a12669173eafb7c2bc50a6d8.jpg",
    ],
    host_name: "Squid Games",
    location: "Santa Monica, California",
    price: "25",
    console: "X Box One",
    rating: "4.98",
  },

  {
    images: [
      "https://scholarlyoa.com/wp-content/uploads/2020/08/e15e6087a12669173eafb7c2bc50a6d8.jpg",
      "https://cdn.home-designing.com/wp-content/uploads/2020/09/gaming-room-ideas.jpg",
      "https://m.media-amazon.com/images/I/81+T-uMG8JL.jpg",
      "https://i.pinimg.com/originals/94/dc/83/94dc83111a5a18e0d3ccadaeeb21f004.jpg",
      "https://cdn.homedit.com/wp-content/uploads/2014/10/RGB-Lighting.jpg",
    ],
    host_name: "Jasper's Lounge",
    location: "San Francisco, California",
    price: "50",
    console: "Digital Storm PC",
    rating: "4.77",
  },
  {
    images: [
      "https://m.media-amazon.com/images/I/81+T-uMG8JL.jpg",
      "https://cdn.home-designing.com/wp-content/uploads/2020/09/gaming-room-ideas.jpg",
      "https://i.pinimg.com/originals/94/dc/83/94dc83111a5a18e0d3ccadaeeb21f004.jpg",
      "https://cdn.homedit.com/wp-content/uploads/2014/10/RGB-Lighting.jpg",
      "https://scholarlyoa.com/wp-content/uploads/2020/08/e15e6087a12669173eafb7c2bc50a6d8.jpg",
    ],
    host_name: "Gaming Zone",
    location: "Mountain View, California",
    price: "30",
    console: "PS5",
    rating: "4.55",
  },
  {
    images: [
      "https://i.pinimg.com/originals/94/dc/83/94dc83111a5a18e0d3ccadaeeb21f004.jpg",
      "https://cdn.home-designing.com/wp-content/uploads/2020/09/gaming-room-ideas.jpg",
      "https://m.media-amazon.com/images/I/81+T-uMG8JL.jpg",
      "https://cdn.homedit.com/wp-content/uploads/2014/10/RGB-Lighting.jpg",
      "https://scholarlyoa.com/wp-content/uploads/2020/08/e15e6087a12669173eafb7c2bc50a6d8.jpg",
    ],
    host_name: "Need for Sweet",
    location: "Sacramento, California",
    price: "15",
    console: "X Box Series X",
    rating: "4.48",
  },
  {
    images: [
      "https://cdn.homedit.com/wp-content/uploads/2014/10/RGB-Lighting.jpg",
      "https://cdn.home-designing.com/wp-content/uploads/2020/09/gaming-room-ideas.jpg",
      "https://m.media-amazon.com/images/I/81+T-uMG8JL.jpg",
      "https://i.pinimg.com/originals/94/dc/83/94dc83111a5a18e0d3ccadaeeb21f004.jpg",
      "https://scholarlyoa.com/wp-content/uploads/2020/08/e15e6087a12669173eafb7c2bc50a6d8.jpg",
    ],
    host_name: "Winners Circle",
    location: "Miami, Florida",
    price: "30",
    console: "Alienware PC",
    rating: "4.88",
  },
];
