<div class="sticky-top">
  <search-bar [search_type]="search_params"></search-bar>
</div>
<div class="container-xl px-2">
  <div class="ctr mb-5">.</div>
  <div
    *ngLet="util.storey.all_db as db"
    class="mt-2 shoplist"
  >
    <ng-container *ngIf="db">
      <ng-container>
        <div
          *ngFor="let sh of util.map2Arr(db.store, 2) | orderBy : 'title' "
          class="shopcard"
        >
          <div
            class="brad overflow-hidden shadow-sm text-center p-0 bg-bg1 csw bo-bg4"
          >
            <p class="bg-cw p-2">
              <img
                class="image"
                [src]="sh.images[0]"
                (click)="order(sh)"
              />
            </p>
            <p class="title fs9 mb-3 px-2 fw-4">
              {{ sh.title }}
            </p>
            <p class="fw-7 mm1 h4">
              <img
                class="mb-1 mr-1"
                [style.width.px]="20"
                [style.height.px]="20"
                src="/assets/fingz.png"
                alt=""
              />
              {{ fingy(sh.price) }}
            </p>
            <p
              class="fw-7 bg-ccb py-2 bg1 m-2 brad2 poi"
              (click)="util.singleLink('shop', sh.id)"
            >
              BUY &nbsp;&nbsp;&nbsp;
              <i class="fas fa-shopping-cart"></i>
            </p>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
