import { Component, Input } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "h-verify",
  templateUrl: "./h-verify.html",
  styleUrls: ["../hosts.scss"],
})
export class HostVerify {
  @Input() host;

  constructor(public util: Utilities) {}

  async verify() {
    this.util.afd
      .object(`dbz/hosts/${this.host.id}`)
      .update({ verified: "pending" });
  }
}
