<div
  class="feeter box2 bg-bg1 wi100"
  [ngClass]="isIos ? 'feetios': ''"
>
  <div *ngIf="!util.isM" class="con-fin pt-2">
    <span class="h6 fw-3 poi">
      © 2023 Fingaz, Inc. &#x2022;
      <span routerLink="/terms" class="px-2 fw-6"
        >Terms</span
      >
      &#x2022;
      <span routerLink="/privacy" class="px-2 fw-6"
        >Privacy</span
      >
      &#x2022;
      <span routerLink="/help/faq" class="px-2 fw-6"
        >Help & Support</span
      ></span
    >
    <div>
      <span class="h6">
        <a
          *ngFor="let s of socials"
          [href]="s.href"
          target="_blank"
          class="px-3"
        >
          <img width="24px" [src]="s.image" alt=""
        /></a>
      </span>
      <span
        class="bg-bg1 ml-4 bo-bg3 brad2 csw p-2 shadow-sm h5"
      >
        &nbsp; 🇺🇸 &nbsp;
        <i class="fas fa-globe mx-2"></i>
      </span>
    </div>
  </div>

  <div *ngIf="util.isM" class="container my-0 mobFoot">
    <div class="row csw">
      <div
        routerLink="/"
        class="col text-center shadow-sm"
        [ngClass]="util.storey.router.url === '/' ? 'c0' : 'csw'"
      >
        <i class="fas fa-gamepad"></i>
        <p class="fw-4 fs7">Games</p>
      </div>
      <div
        routerLink="/events"
        class="col text-center shadow-sm"
        [ngClass]="util.storey.router.url === '/events' ? 'cck' : 'csw'"
      >
        <i class="fas fa-calendar-alt"></i>
        <p class="fw-4 fs7">Events</p>
      </div>
      <div
        class="col text-center shadow-sm"
        *ngIf="util.is_connected"
        [matMenuTriggerFor]="umenu"
      >
        <i class="fas fa-bars"></i>
        <p class="fw-4 fs7">Menu</p>
      </div>
      <div
        (click)="util.openAuth('in')"
        class="col text-center shadow-sm"
        *ngIf="!util.is_connected"
      >
        <i class="far fa-user"></i>
        <p class="fw-4 fs7">Sign in</p>
      </div>
      <div
        routerLink="/help/faq"
        class="col text-center shadow-sm"
        *ngIf="!util.is_connected"
      >
        <i class="fas fa-life-ring"></i>
        <p class="fw-4 fs7">Help</p>
      </div>

      <div
        routerLink="/profile"
        class="col text-center shadow-sm"
        *ngIf="util.is_connected"
      >
        <i class="fas fa-user"></i>
        <p class="fw-4 fs7">Profile</p>
      </div>
      <div
        class="col text-center"
        *ngIf="util.is_connected"
      >
        <i class="fas fa-bell"></i>
        <p class="fw-4 fs7">0</p>
      </div>

      <mat-menu
        #umenu="matMenu"
        xPosition="after"
        class="bg-bg1 mb-4 mr-5 wi3 shadow csw brad p-0 menux text-center"
      >
        <h5
          *ngIf="!util.is_connected"
          (click)="util.openAuth('in')"
          class="fw-4"
        >
          Sign in
        </h5>
        <h5
          *ngIf="!util.is_connected"
          (click)="util.openAuth('up')"
          class="fw-6"
        >
          Sign up
        </h5>

        <h5
          *ngIf="util.is_connected"
          routerLink="/profile/booking"
          class="fw-6"
        >
          <i class="fas fa-calendar-check mr-2"></i> My
          Bookings
        </h5>

        <h5
          *ngIf="util.is_connected"
          routerLink="/profile/favs"
          class="fw-6"
        >
          <i class="fas fa-heart mr-2"></i> My Favorites
        </h5>

        <h5
          *ngIf="util.is_connected"
          (click)="util.signOut()"
          class="fw-6 ccr"
        >
          <i class="fas fa-power-off mr-2"></i> Sign Out
        </h5>
        <hr class="b9 m-0" />

        <h5
          *ngIf="!util.storey.user_db.is_host"
          class="fw-5 ccp"
          routerLink="/host"
        >
          <i class="fas fa-landmark mr-2"></i> Host a Game
        </h5>

        <h5
          *ngIf="util.storey.user_db.is_host"
          class="fw-6 ccp"
          routerLink="/host/home"
        >
          <i class="fas fa-landmark mr-2"></i> Host
          Dashboard
        </h5>

        <h5 class="fw-6 cck">
          <i class="fas fa-calendar-plus mr-2"></i> Setup an
          Event
        </h5>

        <h5 routerLink="/help/faq" class="fw-4">
          <i class="fas fa-life-ring mr-2"></i> Help &
          Support
        </h5>
      </mat-menu>
    </div>
  </div>
</div>
<!-- * FOOTER FINGZ  -->
