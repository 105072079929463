import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "ad-users",
  templateUrl: "./ad-users.html",
  styleUrls: ["../admin.scss"],
})
export class AdUsers {
  searcher;

  constructor(public util: Utilities) {}
}
