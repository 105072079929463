import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngxs/store";
@Pipe({
  name: "filters",
})
export class FilterPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(list: any, filter?: any) {
    let filtered = {};
    if (filter && list) {
      filtered = filter.reduce((res, val) => {
        res[val] = list[val];
        return res;
      }, {});
      return filtered;
    }
    return list;
  }
}
