<div class="text-center">
  <h5 *ngIf="!isAuth" class="fs1 mb-2">
    Accept these terms to continue using Smartbate
  </h5>
  <h5 *ngIf="isAuth" class="fs1 mb-4">
    Accept these terms to become a <br />
    Fingaz Player
  </h5>

  <div *ngFor="let l of list" class="position-relative m-2">
    <div
      class="termy bg-d3 brad2 py-2 pl-4 text-left"
      [class]="l.form.value ? 'bo-ccg' : 'bo-ccr'"
      (click)="l.form.setValue(!l.form.value)"
    >
      <span
        class="check mx-2 bradd"
        [class]="l.form.value? 'bg-ccg' : 'bg-cwe'"
      ></span>
      <input
        [formControl]="l.form"
        type="checkbox"
        class="d-none"
      />
      <span class="ml-4 fs07">{{l.text}}</span>
    </div>
    <button
      class="read ml-4 px-2 bg-d2 c3 brad2 bo-c3"
      (click)="reedy(l.html, readtos)"
    >
      <i class="fab fa-readme"></i>
    </button>
  </div>

  <button
    class="py-2 px-4 bg-d1 ccg brad2 bo-ccg mt-2"
    [disabled]="!(list[0].form.value && list[1].form.value && list[2].form.value && list[3].form.value)"
    (click)="agree()"
  >
    I Agree
  </button>

  <small
    *ngIf="!isAuth"
    class="mt-2 ccr d-block"
    (click)="util.dlogx(); util.auth.signout()"
    >Sign out</small
  >
</div>

<ng-template #readtos>
  <button
    class="cls bo-ctr bg-ccr ccw fw-7 brad2 p-1 px-3"
    (click)="dlogref.close()"
  >
    X
  </button>
  <div class="pt-3">
    <terms [reader]="true" *ngIf="html === 'terms'"></terms>
    <privacy
      [reader]="true"
      *ngIf="html === 'priv'"
    ></privacy>
    <eula [reader]="true" *ngIf="html === 'eula'"></eula>
    <community
      [reader]="true"
      *ngIf="html === 'comm'"
    ></community>
  </div>
</ng-template>
