import { Component } from "@angular/core";

@Component({
  selector: "content",
  template: `
    <div class="container"></div>
  `,
  styles: [
    "div{ height: 400px; background-color: purple; margin: 10px auto; border: 2px solid orange}"
  ]
})
export class Content {
  constructor() {}
}
