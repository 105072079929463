import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "ad-consoles",
  templateUrl: "./ad-consoles.html",
  styleUrls: ["../admin.scss"],
})
export class AdConsoles {
  current_console;
  searcher;

  constructor(public util: Utilities) {}

  newConsole(f) {
    let cid = f.value.name
      .toLowerCase()
      .trim()
      .replace(/[\W_]+/g, "_");

    let cons = { ...f.value };
    cons.id = cid;
    this.util.afd
      .object(`dbz/consoles/${cid}`)
      .update(cons);
    this.util.dlogx();
  }

  updateImage() {
    this.util.afd
      .object(`dbz/consoles/${this.current_console[0]}`)
      .update({ image: this.current_console[1] });

    this.util.dlogx();
  }
}
