import { State, Action, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";

import * as actions from "@store/fingaz.actions";
import { empty_filter, empty_search } from "@models/menu";
import { fin_wallet } from "@models/wlink.mo";

export interface FingazFace {
  user: any;
  user_db: any;
  host_db: any;
  role: string;
  theme: string;
  version: string;
  country: string;
  wallet: any;
  all_db?: any;
  search: any;
  filters: any;
  router: any;
}

const defy = {
  user: "",
  user_db: "",
  host_db: "",
  role: "guest",
  version: "1.0.50",
  country: "",
  wallet: fin_wallet,
  search: { ...empty_search },
  filters: { ...empty_filter },
};
const defx = {
  ...defy,
  theme: "light",
  router: {},
};

@State<FingazFace>({
  name: "fingazstate",
  defaults: defx,
})
@Injectable()
export class FingazState {
  constructor() {}

  @Action(actions.AddUser)
  AddUser(
    ctx: StateContext<FingazFace>,
    action: actions.AddUser
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user: action.user,
    });
  }

  @Action(actions.UpdateUserDB)
  UpdateUserDB(
    ctx: StateContext<FingazFace>,
    action: actions.UpdateUserDB
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user_db: action.user_db,
    });
  }

  @Action(actions.UpdateRole)
  UpdateRole(
    ctx: StateContext<FingazFace>,
    action: actions.UpdateRole
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      role: action.role,
    });
  }

  @Action(actions.UpdateRoute)
  UpdateRoute(
    ctx: StateContext<FingazFace>,
    action: actions.UpdateRoute
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      router: action.route,
    });
  }

  @Action(actions.WalletState)
  WalletState(
    ctx: StateContext<FingazFace>,
    action: actions.WalletState
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      wallet: action.wallet,
    });
  }

  @Action(actions.SetHost)
  SetHost(
    ctx: StateContext<FingazFace>,
    action: actions.SetHost
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      host_db: action.host,
    });
  }

  @Action(actions.SetTheme)
  SetTheme(
    ctx: StateContext<FingazFace>,
    action: actions.SetTheme
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      theme: action.theme,
    });
  }

  @Action(actions.SetCountry)
  SetCountry(
    ctx: StateContext<FingazFace>,
    action: actions.SetCountry
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      country: action.country,
    });
  }

  @Action(actions.UpdateDB)
  UpdateDV(
    ctx: StateContext<FingazFace>,
    action: actions.UpdateDB
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      all_db: action.db,
    });
  }

  @Action(actions.SetFilter)
  SetFilter(
    ctx: StateContext<FingazFace>,
    action: actions.SetFilter
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      filters: {
        ...state.filters,
        [action.type]: action.filter,
      },
    });
  }

  @Action(actions.Searcher)
  Searcher(
    ctx: StateContext<FingazFace>,
    action: actions.Searcher
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      search: {
        ...state.search,
        [action.search_type]: action.search_string,
      },
    });
  }

  /* **************************
    C L E A R   :::   A L L
  *************************** */

  @Action(actions.ClearState)
  ClearState(ctx: StateContext<any>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      ...defy,
    });
  }
}
