<div class="p-2 text-center">
  <p class="justify-content-center d-flex">
    <input
      class="searcher shadow brad mx-2 csw p-3"
      type="text"
      [(ngModel)]="searcher"
      placeholder="Search"
    />
    &nbsp;
    <button
      (click)="util.dlogtr(new_console)"
      class="bg-bg1 c0 fs2 li0 px-3 py-4 brad"
    >
      +
    </button>
  </p>

  <ng-container
    *ngFor="let c of util.map2Arr(util.storey.all_db.consoles, 2) | searchFilter: searcher"
  >
    <div class="itemy brads shadow-sm bg-bg1 csw p-1">
      <img
        width="100%"
        height="45px"
        [src]="c.image || '/assets/robot.png'"
        alt=""
        class="brads"
        (dblclick)="util.show(c)"
      />
      <p class="text-truncate fs07 m-0">{{c.name}}</p>
      <p class="fs07 m-0">
        <button
          class="m-1 bg-bg1 c0 box2 brad"
          (click)="current_console = [c.id, c.image]; util.dlogtr(update_image)"
        >
          <i class="fas fa-image"></i>
        </button>
      </p>
    </div>
  </ng-container>
</div>

<!-- * UPDATE IMAGE  -->
<ng-template #update_image>
  <div
    class="position-relative p-4 brad bg-bg1 bo3-bg4 wim text-center"
  >
    <span matDialogClose class="h3 ccr poi exiter"
      ><i class="fas fa-times-circle"></i
    ></span>
    <div>
      <p>
        <input
          class="bg-bg1 p-3 csw shadow my-2 brad wi4"
          name="name"
          type="text"
          [(ngModel)]="current_console[1]"
        />
      </p>
      <p>
        <button
          (click)="updateImage()"
          class="px-3 py-2 bg-csw bg1"
        >
          UPDATE IMAGE
        </button>
      </p>
    </div>
  </div>
</ng-template>

<!-- * NEW CONSOLE  -->
<ng-template #new_console>
  <div
    class="position-relative p-4 brad bg-bg1 bo3-bg4 wim text-center"
  >
    <span matDialogClose class="h3 ccr poi exiter"
      ><i class="fas fa-times-circle"></i
    ></span>
    <div>
      <form class="" #new_form="ngForm">
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            name="name"
            type="text"
            placeholder="Name"
            ngModel
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            name="abbr"
            type="text"
            placeholder="Abbreviation"
            ngModel
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            name="image"
            type="text"
            placeholder="Image"
            ngModel
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            type="number"
            placeholder="Max Players"
            name="max_players"
            ngModel
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            type="number"
            placeholder="value"
            name="value"
            ngModel
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            type="text"
            placeholder="Specs"
            name="specs"
            ngModel
          />
        </p>
      </form>
      <p>
        <button
          (click)="newConsole(new_form)"
          class="px-3 py-2 bg-csw bg1"
        >
          ADD
        </button>
      </p>
    </div>
  </div>
</ng-template>
