<div class="wim8 mx-auto">
  <h3 class="display-3 text-center my-4 fw-6 csw">FAQ</h3>
  <div class="m-3">
    <div class="mx-2">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let faq of util.storey.all_db.faqs  | keyvalue | orderBy:'value.priority' ; let i = index"
          class="my-3 brad bg-bg1 bol-csw px-3 py-2 shadow-sm"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="fw-6 h4 csw">
              {{ faq.value.question}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="csw h5">{{ faq.value.answer }}</p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
