import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "maps",
  templateUrl: "./maps.html",
  styleUrls: ["./maps.scss"],
})
export class Maps {
  constructor(public util: Utilities) {}

  alert(x) {
    this.util.toast.warning(x, "", {
      positionClass: "toast-bottom-left",
    });
  }
}
