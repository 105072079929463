<div class="bg-bg1 p-4 m-0">
  <div class="container text-center csw">
    <h3 class="h1">
      <img
        width="40px"
        src="/assets/fingz.png"
        class="mx-2"
        alt=""
      />
      FINGZ
    </h3>
    <h5 class="m-3 text-left">
      FINGZ are virtual tokens used on the Fingaz platform
      and they can be
      <button class="mx-3 bg-c0 bg1 px-3 py-2 brad3">
        OBTAINED
      </button>
      by purchasing with cash and soon with crypto. <br />
      <br />
      FINGZ can be used to
      <span routerLink="/games" class="c0 fw-7 poi"
        ><u>book games</u></span
      >, enter into
      <span routerLink="/parties" class="cck fw-7 poi"
        ><u>gaming parties</u></span
      >
      and shop in the
      <span routerLink="/shop" class="ccb fw-7 poi"
        ><u>Fingaz Gaming Shop.</u></span
      >
      <br />
      <br />
      FINGZ are
      <span class="ccr fw-7">non refundable</span>, All
      purchases are final.
    </h5>
  </div>
</div>
<div class="bg-bg1 p-4 m-0">
  <div class="container text-center csw">
    <h3 class="h1">
      <img
        width="40px"
        src="/assets/h-fingz.png"
        class="mx-2"
        alt=""
      />Host FINGZ
    </h3>

    <h5 class="m-3 text-left">
      Host FINGZ are only awarded to Fingaz Hosts for
      hosting a
      <span routerLink="/games" class="c0 fw-7"
        ><u>gaming session.</u></span
      >
      <br />
      <br />
      If you have a publicly accessible space and high
      quality gaming gear, you can
      <span routerLink="/host" class="ccp fw-7"
        ><u>sign up</u></span
      >
      to become a Host. <br />
      <br />
      Host FINGZ can be cashed out and also be converted to
      FINGZ at any ratio. Any Host FINGZ converted to FINGZ
      <span class="ccr fw-7">cannot be cashed</span>
      out again.
    </h5>
  </div>
</div>
<div class="bg-bg2 p-4 m-0">
  <div class="container text-center csw">
    <h3 class="h1">
      <img
        width="40px"
        src="/assets/fingz.png"
        class="mx-2 zfingz"
        alt=""
      />Claimable FINGZ
    </h3>

    <h5 class="m-3">
      Claimable FINGZ are vested FINGZ meant to be cashed
      out at certain milestones or for completing certain
      tasks on Fingaz. <br />
      <br />
      <span class="c0 fw-7">100</span> claimable FINGZ is
      equivalent to <span class="ccg fw-7">$1</span><br />
      <br />

      <span class="ccg fw-7">50,000</span> claimable FINGZ
      will be availabe to the
      <span class="c0 fw-7">First 1000</span> who complete
      these tasks. <br />
      <br />
      <i class="fas fa-check-circle c0"></i> &nbsp; Login to
      <span
        (click)="util.openAuth('in')"
        class="c0 fw-7 poi"
      >
        <u>fingaz.app</u></span
      >, Verify with a Fingaz Rep and set Username <br />
      <br />
      <i class="fas fa-check-circle c0"></i> &nbsp; Join our
      <a
        href="https://discord.gg/yHkFTuPB6q"
        target="_blank"
        ><span class="fw-7 ccp"><u>Discord</u></span>
      </a>
      and link username to Fingaz Profile. <br />
      <br />
      <i class="fas fa-check-circle c0"></i> &nbsp; Follow
      our<a
        href="https://twitter.com/fingaz_gaming"
        target="_blank"
        ><span class="ccb fw-7"> <u>Twitter</u></span></a
      >
      and link username to Fingaz Profile. <br />
      <br />
      <i class="fas fa-check-circle c0"></i> &nbsp; Join 3
      <span routerLink="/parties" class="cck fw-7 poi"
        ><u>Parties</u></span
      >
      <br />
      <br />
      <i class="fas fa-check-circle c0"></i> &nbsp;
      Participate in testing of the Fingaz mobile App <br />
      <br />

      These 100,000 claimable FINGZ can be cashed out or
      converted to FINGZ when there are 1 million hours of
      gaming session played on Fingaz. <br />
      <br />

      More details on Referral Claimable FINGZ COMING SOON
      !!!
    </h5>
  </div>
</div>
