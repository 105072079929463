import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "p-favs",
  templateUrl: "./p-favs.html",
  styleUrls: ["../../widgets/slots/slots.scss"],
})
export class ProfileFavs implements OnInit {
  favs = Object.keys(
    this.util.storey.user_db.favorites ?? {}
  );

  constructor(public util: Utilities) {}

  ngOnInit() {}

  async removeFav(slot) {
    let hid = this.util.hid(slot.host_name);
    this.util.afd
      .object(
        `dbz/gamers/${this.util.storey.user.uid}/favorites`
      )
      .update({
        [hid]: null,
      });
    this.util.wuToast("Removed", "");

    await new Promise((r) => setTimeout(r, 100));

    this.favs = Object.keys(
      this.util.storey.user_db.favorites ?? {}
    );
  }

  oSlot(s) {
    let r = this.util.hid(s);
    this.util.router.navigate([`slots/${r}`]);
  }
}
