import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "search-bar",
  templateUrl: "./search-bar.html",
  styleUrls: ["./search-bar.scss"],
})
export class SearchBar implements OnInit {
  @Input() search_type: any = {};
  @ViewChild("t_dlog") public t_dlog: TemplateRef<any>;
  @ViewChild("t_consoles")
  public t_consoles: TemplateRef<any>;
  @ViewChild("t_categories")
  public t_categories: TemplateRef<any>;
  @ViewChild("t_shop_categories")
  public t_shop_categories: TemplateRef<any>;
  @ViewChild("t_games") public t_games: TemplateRef<any>;
  @ViewChild("t_date") public t_date: TemplateRef<any>;

  scrollx = [0, 0, 4000];
  current_filter_template;
  consoles;
  av_dates = this.util.getAvailableDates();
  search_str = "";
  filexpand = false;
  animationState = "out";

  constructor(public util: Utilities) {}

  ngOnInit() {
    this.search_str =
      this.util.searchy[this.search_type.name];
  }

  setTempFilter(filter_group, index) {
    this.animationState =
      this.animationState === "out" ? "in" : "out";

    this.current_filter_template = this.filexpand
      ? [null, null]
      : [this[filter_group.template], index];
    this.filexpand = !this.filexpand;
  }

  setConsole(co) {
    this.util.tempSearcher(
      "slot",
      this.util.storey.search.slot === co ? "" : co
    );
  }

  checkConsoles(co) {
    if (
      this.search_type.name === "slot" &&
      (co === "ios" ||
        co === "android" ||
        co === "google_stadia")
    ) {
      return false;
    }
    return true;
  }
}
