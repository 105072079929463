<div
  class="position-relative p-1 brad bg-cmain box2 wim text-center"
>
  <span matDialogClose class="h3 b1 poi exiter"
    ><i class="fas fa-times-circle"></i
  ></span>

  <h3 class="b2 fw-6 py-3">CONFIRM PAYMENT</h3>
  <div class="bg-linb2 brad box2 py-2">
    <h4 class="cw my-3">
      This transaction will cost you <br />
      <br />
      <img
        class="mx-2 mb-2"
        width="25px"
        src="/assets/fingz.png"
        alt=""
      />
      <span class="cmain fw-7 h2">{{amount}}</span>
    </h4>
    <h4 class="my-3">
      <button
        matDialogClose
        class="mx-3 bg-ccr b2 px-4 py-3 brad3 fw-5"
      >
        Cancel
      </button>
      <button
        class="mx-3 bg-cmain b2 px-4 py-3 brad3 fw-5"
        (click)="confirm()"
      >
        Confirm
      </button>
    </h4>
  </div>
</div>
