import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "h-dash",
  templateUrl: "./h-dash.html",
  styleUrls: ["../hosts.scss"],
})
export class HostDash implements OnInit {
  navs = [
    {
      name: "Base",
      link: "/host/home",
      icon: "fas fa-landmark",
    },
    {
      name: "Bookings",
      link: "/host/bookings",
      icon: "fas fa-calendar-check",
    },
    // {
    //   name: "Inventory",
    //   link: "/host/inventory",
    //   icon: "fas fa-archive",
    // },
    {
      name: "Finances",
      link: "/host/finances",
      icon: "fas fa-money-bill-wave",
    },
  ];

  constructor(public util: Utilities) {}

  ngOnInit(): void {}
}
