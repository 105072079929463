import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { stat_list } from "./stats";

@Component({
  selector: "ad-stats",
  templateUrl: "./ad-stats.html",
  styleUrls: ["../admin.scss"],
})
export class AdStats implements OnInit {
  searcher;

  stats = stat_list;

  constructor(public util: Utilities) {}

  ngOnInit(): void {
    // console.log(this.stats);
  }
}
