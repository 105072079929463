<div *ngIf="countries" class="h-new p-2 text-center">
  <h4 class="mb-4">Step {{curr_page}} of 3</h4>
  <!-- * PAGE 1 COUNTRY AND CITY  -->
  <div *ngIf="curr_page === 1">
    <p class="h2 fw-6 mb-4">
      Which country and city are you hosting from?
    </p>
    <p class="h2">
      <input
        required
        matInput
        class="bob-bg3 p-3 brad csw bg-bg1 box2"
        [(ngModel)]="country"
        type="text"
        placeholder="Country"
        name="country"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        class="bg-bg2 csw brad mt-2"
        #auto="matAutocomplete"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let co of countries | searchFilter: country "
          class="csw"
          [value]="co.name"
        >
          {{co.emoji}} &nbsp; {{co.name}}
        </mat-option>
      </mat-autocomplete>
    </p>
    <p class="h2">
      <input
        required
        class="bob-bg3 p-3 brad csw bg-bg1 box2"
        [(ngModel)]="city"
        type="text"
        placeholder="City"
        name="city"
      />
    </p>
  </div>
  <!-- * PAGE 2 SELECT CONSOLE  -->
  <div *ngIf="curr_page === 2">
    <p class="h2 fw-6 mb-4">
      Which consoles are you hosting with?
    </p>
    <p class="my-2 fw-6 ccp">Click to Select</p>
    <p class="add-list">
      <ng-container
        *ngFor="let c of util.map2Arr(util.storey.all_db.consoles, 2)"
      >
        <button
          class="coimg bg-bgc csw brad m-2 p-0"
          *ngIf="!(['android', 'ios'].includes(c.id))"
          [ngClass]="consoles[c.id] ? 'bob-ccp opa1' : 'bo-bg4 transp'"
          (click)="selectConsole(c)"
        >
          <img class="im1" [src]="c.image" alt="" /> <br />
          <p class="my-2 fw-6">{{c.name}}</p>
        </button>
      </ng-container>
    </p>
  </div>
  <!-- * PAGE 3 SELECT EMAIL  -->
  <div *ngIf="curr_page === 3">
    <p class="h2 fw-6">What email can we reach you with?</p>
    <p class="my-4 h2">
      <input
        required
        class="bob-bg3 p-3 brad csw bg-bg1 box2"
        [(ngModel)]="email"
        type="email"
        placeholder="Email"
        name="email"
      />
    </p>
  </div>

  <div class="text-center mt-4">
    <button
      *ngIf="curr_page > 1"
      (click)="curr_page = curr_page - 1"
      class="mx-3 brad2 px-3 py-2 bg-bg1 csw bo3-bg4"
    >
      Back
    </button>
    <button
      *ngIf="curr_page < 3"
      (click)="next()"
      class="mx-3 brad2 px-3 py-2 bg-bg1 csw bo3-bg4"
    >
      Next
    </button>
    <button
      *ngIf="curr_page == 3"
      class="mx-3 brad2 px-3 py-2 bg-ccp bg1 bo3-bg4"
      (click)="apply()"
    >
      Apply
    </button>
  </div>
</div>

<div *ngIf="!countries" class="p-5">
  <loader></loader>
</div>
