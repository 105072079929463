<div *ngLet="util.storey.host_db as host_db" class="">
  <div
    *ngIf="host_db === '' || !host_db"
    class="container-xl mt-2 p-2 csw"
  >
    <div class="text-center">
      <p class="display-4 fw-4 mt-5">
        Host your
        <span class="ccp fw-6">Gaming Setup</span> for
        <br />
        <span class="ccp fw-6">1 - 4 players</span> and earn
        up to <br />
        <span class="ccp fw-6"> $ 2,000</span>
        every week.
      </p>
      <button
        (click)="newHost(new_host)"
        class="mt-4 bg-ccp bg1 px-5 fw-6 py-3 h2 brad3"
      >
        START
      </button>
    </div>

    <div class="mx-auto mt-4 wim8">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let faq of host_faq   "
          class="my-3 brad bg-bg1 bol-ccp px-3 py-2 shadow-sm"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="fw-6 h4 ccp">
              {{ faq.question}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="csw h5">{{ faq.answer }}</p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="" *ngIf="host_db">
    <ng-container *ngIf="host_db?.verified !== 'true'">
      <h-verify [host]="host_db"> </h-verify>
    </ng-container>
    <ng-container *ngIf="host_db?.verified === 'true'">
      <h-dash></h-dash>
    </ng-container>
  </div>
</div>

<ng-template #new_host>
  <div
    class="position-relative p-4 brad bg-bg2 bo3-bg4 wim8 csw text-center"
  >
    <span matDialogClose class="h3 ccr poi exiter"
      ><i class="fas fa-times-circle"></i
    ></span>
    <div>
      <h-new></h-new>
    </div>
  </div>
</ng-template>
