import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "p-home",
  templateUrl: "./p-home.html",
  styleUrls: ["./p-home.scss"],
})
export class ProfileHome implements OnInit {
  buy_fingz = [];
  wallet = [];
  is_banner = true;
  currentRate = 3.5;

  constructor(public util: Utilities) {}

  ngOnInit() {}
}
