<div
  class="p-2 text-center"
  *ngLet="util.storey.all_db as db"
>
  <p>
    <input
      class="searcher shadow brad m-4 csw p-3"
      type="text"
      [(ngModel)]="searcher"
      placeholder="Search"
    />
  </p>

  <div class="container-xl">
    <p class="my-3">
      <button
        [ngClass]="{'bob-ccp' : page_mode}"
        (click)="page_mode = true"
        class="mx-2 brad bg-bg1 csw px-3 py-2"
      >
        Current
      </button>
      <button
        [ngClass]="{'bob-ccp' : !page_mode}"
        (click)="page_mode = false"
        class="mx-2 brad bg-bg1 csw px-3 py-2"
      >
        Past
      </button>
    </p>
    <div class="mb-5">
      <!-- * CURRENT CALENDAR  -->
      <div *ngIf="page_mode" class="row">
        <div
          class="col-4 col-lg-2 col-md-3 px-2 py-1"
          *ngFor="let d of util.getAvailableDates() "
          (click)="showD(d)"
        >
          <div
            class="bg-bg1 box2 csw brad poi py-1 my-2"
            [ngClass]="checkD(d) ? 'opa1' : 'transp'"
          >
            <h6
              class="m-2 shadow-sm bg-bg2 brad p-2"
              [ngClass]="{'ccp' : checkD(d)}"
            >
              {{d | dayF : "MMM" }}
            </h6>
            <h3
              class="text-center py-3 csw"
              [ngClass]="{'ccp fw-8' : checkB(d)}"
            >
              {{d | dayF : "dd" }}
            </h3>
            <h6
              class="m-2 shadow-sm bg-bg2 brad p-2"
              [ngClass]="{'ccp' : checkD(d)}"
            >
              {{d | dayF : "iii" }}
            </h6>
          </div>
        </div>
      </div>

      <!-- * PAST CALENDAR  -->
      <div *ngIf="!page_mode" class="row">
        <div
          class="col-4 col-lg-2 col-md-3 px-2"
          *ngFor="let d of mo_arr "
        >
          <div class="transp bg-bg1 my-3 box2 csw brad poi">
            <!-- <h6 class="m-2 shadow-sm bg-bg2 brad p-2">
              {{d | dayF : "MMM" }}
            </h6> -->
            <h3 class="text-center py-3 csw">
              {{d | dayF : "MMM" }}
            </h3>
            <h6 class="m-2 shadow-sm bg-bg2 brad p-2">
              2022
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
