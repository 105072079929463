<div
  *ngLet="util.getId(util.storey.router.url) as tid"
  class="px-4"
>
  <div *ngLet="util.storey.all_db as db">
    <ng-container *ngIf="db">
      <div
        *ngLet="db.parties[tid] as party"
        class="container p-2"
      >
        <div class="row p-0 mt-0 mx-1">
          <div class="col-12 col-md-6 p-0 text-center">
            <div class="">
              <img
                (click)="util.show(party)"
                width="95%"
                class="brad bo-bg4"
                [src]="util.getLargeImage(db.games[party.game].image)"
                alt=""
              />
              <h5 class="py-2">
                <button
                  class="bob-bg1 box2 px-3 py-2 brads mx-3"
                  [ngClass]="so.bg"
                  *ngFor="let so of social"
                >
                  <i [ngClass]="so.icon"></i>
                </button>
              </h5>
            </div>
          </div>
          <div
            class="col-12 col-md-6 p-4 text-center bg-bg1 brad box2"
            *ngLet="util.map2Arr(party.gamers, 1) as plist"
          >
            <ng-container *ngIf="!joining">
              <h3 class="my-3 csw fw-7">
                {{db.games[party.game].full_name}}
              </h3>
              <h3 class="fw-6 cck">
                <i class="fas fa-calendar day"></i> &nbsp;
                {{party.deadline | dayF : "MMM do y" }}
              </h3>
              <h3 class="fw-7 py-2 csw m-2">
                <img
                  class="mb-1 mr-1"
                  [style.width.px]="25"
                  [style.height.px]="25"
                  src="/assets/fingz.png"
                  alt=""
                />
                {{ party.entry_fingz }}
              </h3>
              <h4 class="fw-5 cck mb-4">
                <i class="fas fa-users"></i> &nbsp;
                {{plist.length ?? 0}} / {{party.players}}
                tickets
              </h4>
              <h3>
                <button
                  class="bg-cck bo3-cck bg1 fw-7 brad px-4 py-2 display-5 jbtn"
                  (click)="joinParty(party)"
                  [disabled]="+(plist.length) >= party.players || party.gamers?.[curr_user.uid]"
                >
                  {{party.gamers?.[curr_user.uid]? 'JOINED'
                  : +(plist.length) >= party.players ?
                  'FULL' : 'JOIN PARTY'}}
                </button>
              </h3>
            </ng-container>
            <div *ngIf="joining">
              <div
                class="floader text-center p-5 mb-4 mx-auto"
              >
                <img
                  class="mt-5 mx-5 box2 bradd bo3-cck"
                  width="80px"
                  src="assets/t-fingz.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="totabs bg-bg1 brad box2 p-3 mt-3 mx-1">
          <tabset type="pills" justified="false">
            <tab>
              <ng-template tabHeading
                ><span class="h3 fw-6">
                  <i class="fas fa-file-alt"></i> </span
              ></ng-template>
              <div class="csw p-3">
                <div>
                  <h4>This Party is Online</h4>
                  <h5 class="poi"><u>Meeting Link</u></h5>
                </div>
                <!-- <div class="my-2">
                  <a [href]="party.mlink" target="_blank">
                    MEETING LINK
                  </a>
                </div> -->
              </div>
            </tab>
            <tab *ngIf="party.gamers?.[curr_user.uid]">
              <ng-template tabHeading
                ><span class="h3 fw-6">
                  <i class="fas fa-users"></i> </span
              ></ng-template>
              <div class="csw p-3">
                <p
                  *ngIf="!party.gamers"
                  class="brad my-3 p-3 box2 text-center fw-4 h5"
                >
                  No players have joined the party
                </p>
                <div *ngIf="party.gamers">
                  <p
                    class="text-left bg-bg2 csw bol-cck box2 p-3 my-2"
                    *ngFor="let pl of party.gamers | keyvalue"
                  >
                    <i class="fas fa-user cck"></i> &nbsp;
                    &nbsp;
                    <span class="spa2"
                      >{{util.storey.all_db.gamers[pl.key].username}}
                      &nbsp; &nbsp;
                      <i class="fab fa-discord ccp"></i>
                      {{util.storey.all_db.gamers[pl.key].discord
                      ?? '******'}}</span
                    >
                  </p>
                </div>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </ng-container>
  </div>
</div>
