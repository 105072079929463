import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "privacy",
  templateUrl: "./privacy.html",
  styles: [""],
})
export class Privacy implements OnInit {
  @Input() reader = false;
  constructor() {}

  ngOnInit() {}
}
