import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "ad-parties",
  templateUrl: "./ad-parties.html",
  styleUrls: ["../admin.scss"],
})
export class AdParties implements OnInit {
  parties;
  searcher;

  constructor(public util: Utilities) {}

  ngOnInit(): void {}

  synkP() {
    // let px = this.util.storey.all_db.parties;
    // Object.values(px).forEach((p: any) => {
    //   // console.log(p.player_list);
    //   if (p.player_list) {
    //     p.player_list.forEach((x: any) => {
    //       console.log(
    //         this.util.storey.all_db.gamers[x].parties[p.id]
    //       );
    //       this.util.afd
    //         .object(`dbz/parties/${p.id}/gamers`)
    //         .update({
    //           [x]: this.util.storey.all_db.gamers[x]
    //             .parties[p.id],
    //         });
    //     });
    //   }
    // });
  }

  newParty(f) {
    let to_id = `${f.value.game}-${
      this.util.storey.user.uid
    }-${new Date().getTime()}`;

    let new_f = { ...f.value };
    new_f.id = to_id;
    new_f.deadline = new Date(new_f.deadline).getTime();
    new_f.player_list = [];

    this.util.afd
      .object(`dbz/parties/${to_id}`)
      .update(new_f);
    this.util.dlogx();
  }
}
