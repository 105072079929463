<ng-container *ngLet="util.storey.host_db as host">
  <!-- * LOCATION SELECT PAGE  -->
  <div *ngIf="!loc && util.storey.host_db" class="mt-4">
    <div
      *ngFor="let l of host.locations | keyvalue"
      class="bg-bg1 csw brad box2 mx-auto wim p-4 text-center"
    >
      <h4>Location {{(+l.key + 1)}}</h4>
      <h5>{{l.value.city}} , {{l.value.country}}</h5>
      <button
        class="m-3 px-3 py-2 bg-bgc ccp brad3 fw-6 h4"
      >
        PHOTOS
      </button>
      <button
        class="m-3 px-3 py-2 bg-ccp bgc brad3 fw-6 h4"
        (click)="openLocation(l.key)"
      >
        OPEN
      </button>
    </div>
  </div>
</ng-container>
