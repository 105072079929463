import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

import SwiperCore, {
  Keyboard,
  Pagination,
  Navigation,
  Thumbs,
} from "swiper";

SwiperCore.use([Keyboard, Pagination, Navigation, Thumbs]);

@Component({
  selector: "product",
  templateUrl: "./product.html",
  styleUrls: ["./shop.scss"],
})
export class Product implements OnInit {
  product = "Product";
  thum: any;

  // images: GalleryItem[];
  images;

  constructor(public util: Utilities) {}

  ngOnInit() {
    let pr =
      this.util.storey.all_db.store[
        this.util.getId(this.util.storey.router.url)
      ];

    this.images = [...pr.images];
  }
}
