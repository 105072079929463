import { Component, OnInit } from "@angular/core";
import { shop_filter_menu } from "@models/menu";
import { Utilities } from "@services/utilities";

@Component({
  selector: "shop",
  templateUrl: "./shop.html",
  styleUrls: ["./shop.scss"],
})
export class Shop implements OnInit {
  search_params = {
    name: "shop",
    title: "Shop",
    color: "ccb",
    border: "bot-ccb opa",
    border_l: "bol-ccb",
    profile_icon: "fas fa-shopping-cart",
    profile_bg: "bg-ccb",
    profile_link: "/profile/purchases",
    menu: [...shop_filter_menu],
  };
  constructor(public util: Utilities) {}

  ngOnInit() {}

  fingy(pr) {
    return Math.round((pr + (pr * 0.1) / 2) * 100);
  }

  order(sh) {
    console.log(sh);
  }
}
