<div
  class="p-3 text-center"
  *ngLet="util.storey.all_db as db"
>
  <h5 class="c0" (dblclick)="util.cry.addFingz()">
    FINGZ PURCHASES
  </h5>

  <p
    class="box2 bo-bg2 brad bg-bg1 my-1 csw p-3 text-left fw-5"
    *ngFor="let b of getOrders(db.buyfingz)"
  >
    <span class="fw-7 fs07">{{b[0]}}:</span> &nbsp;
    <span class="c0">
      <img
        class="mb-1"
        [style.width.em]="1"
        [style.height.em]="1"
        src="/assets/fingz.png"
        alt=""
      />1000</span
    >
    &nbsp;
    <span class="ccb">
      <i class="fas fa-gas-pump"></i> 20</span
    >
    &nbsp; <span class="fs07">{{b[1].wallet}}</span> &nbsp;
    &nbsp;<span class="ccp fs07"
      >{{db.user[b[1].user].username}}</span
    >
    &nbsp; &nbsp;
    <span class="fw-8 cck"
      >{{b[1].status | uppercase}}</span
    >
    <button
      (click)="buy(b[1])"
      *ngIf="b[1].status === 'pending'"
      class="bg-c0 bg1 brad px-2 mx-3"
    >
      <i class="fas fa-paper-plane"></i>
    </button>
  </p>
</div>
