<div class="container-xl">
  <div class="csw mt-4 p-4 mx-auto">
    <h1 class="">Fingaz Privacy Policy</h1>
    <p>Last Updated: January 1, 2022</p>
    <h4><a id="sec201910_0"></a>TABLE OF CONTENTS:</h4>
    <ol>
      <li>
        <a [routerLink]='"."' [fragment]='"_1"'
          >INTRODUCTION</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_2'"
          >INFORMATION WE COLLECT</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_3'"
          >HOW WE USE THE INFORMATION WE COLLECT</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_4'"
          >SHARING & DISCLOSURE</a
        >
      </li>
      <li>
        <a [routerLink]='"."' [fragment]='"_5"'
          >OTHER IMPORTANT INFORMATION</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_6'"
          >YOUR RIGHTS</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_7'"
          >OPERATING GLOBALLY & INTERNATIONAL TRANSFERS</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_8'"
          >SECURITY</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_9'"
          >CHANGES TO THIS PRIVACY POLICY</a
        >
      </li>
      <li>
        <a routerLink="./" [fragment]="'sec201910_10'"
          >CONTACT US</a
        >
      </li>
    </ol>
    <h4><a id="_1"></a>1. INTRODUCTION</h4>
    <p>
      Thank you for using Fingaz! Your trust is important to
      us and we’re committed to protecting the privacy and
      security of your personal information. The information
      that’s shared with us helps us to provide a great
      experience with Fingaz. We have a dedicated privacy
      team that’s committed to protecting all the personal
      information we collect and help ensure that personal
      information is handled properly worldwide.
    </p>
    <p>
      This Privacy Policy describes how we collect, use,
      process, and disclose your personal information, in
      conjunction with your access to and use of the Fingaz
      Platform and the Payment Services. This privacy policy
      describes our privacy practices for all websites,
      platforms and services that link to it. Please read
      the privacy policy on the applicable site.
    </p>
    <p>1.1 Definitions</p>
    <p>
      If you see an undefined term in this Privacy Policy
      (such as “Listing” or “Fingaz Platform”), it has the
      same definition as in our
      <a routerLink="/terms-of-service">Terms of Service</a>
      (“Terms”).
    </p>
    <p>1.2 Data Controller</p>
    <p>
      When this policy mentions “Fingaz,” “we,” “us,” or
      “our,” it refers to the Fingaz company that is
      responsible for your information under this Privacy
      Policy (the “Data Controller”).
    </p>
    <ul>
      <li>
        If your country of residence is the United States,
        the Data Controller is Fingaz, Inc.
      </li>
      <li>
        If your country of residence is outside of the
        United States, the People’s Republic of China which
        for purposes of this Privacy Policy does not include
        Hong Kong, Macau and Taiwan (“China”) and Japan, the
        Data Controller is Fingaz Ireland UC (“Fingaz
        Ireland”).
      </li>
      <li>
        If your country of residence is China, and you (a)
        book a Host Service located outside of China or (b)
        create a Listing located outside of China, the Data
        Controller is Fingaz Ireland for that transaction
        and this Privacy Policy will apply.
      </li>
      <li>
        If your country of residence is Japan, the Data
        Controller is Fingaz Global Services Limited
        (“Fingaz GSL”), except where you book a Host Service
        located outside of Japan or create a Listing located
        outside of Japan, in which case the Data Controller
        is Fingaz Ireland for that transaction.
        Additionally, if you reside in Japan, Fingaz Ireland
        will be the Data Controller for all bookings
        confirmed prior to June 13, 2018 at 3PM UTC.
      </li>
    </ul>
    <p>1.3 Applicability to Payments</p>
    <p>
      This Privacy Policy also applies to the Payment
      Services provided to you by Fingaz Payments pursuant
      to the
      <a routerLink="/terms-of-service"
        >Payments Terms of Service</a
      >
      (“Payments Terms”). When using the Payment Services,
      you will be also providing your information, including
      personal information, to one or more Fingaz Payments
      entities, which will also be the Data Controller (the
      "Payments Data Controller") of your information
      related to the Payment Services, generally depending
      on your country of residence.
    </p>
    <ul>
      <li>
        If your country of residence is the United States,
        the Payments Data Controller is Fingaz Payments,
        Inc. (a subsidiary of Fingaz, Inc.).
      </li>
      <li>
        If your country of residence is China, and you (a)
        book a Host Service located outside of China, or (b)
        create a Listing located outside of China, or (c)
        book a Host Service in China with a Host who is not
        a resident of China, the Payments Data Controller is
        Fingaz Payments UK Ltd. (“Fingaz Payments UK”) for
        that transaction and this Privacy Policy will apply.
      </li>
      <li>
        If your country of residence is India, the Payments
        Data Controller is Fingaz Payments India, except in
        the following circumstances: - if you book a Host
        Service located outside of India; - if you create a
        Listing outside of India, in which case, the
        Payments Data Controller is Fingaz Payments UK.
        Notwithstanding anything to the contrary in this
        clause, if you create a Listing in India, and: -
        accept a booking from a Guest who is a resident of
        India, regardless of your country of residence, the
        Payments Data Controller is Fingaz Payments India;
        or - accept a booking from a Guest who is not a
        resident of India, regardless of your country of
        residence, the Payments Data Controller is Fingaz
        Payments UK.
      </li>
      <li>
        If your country of residence is Australia, the
        Payments Data Controller is Fingaz Payments UK,
        except if you book a Host Service located in or
        outside of Australia, which is confirmed after
        January 9, 2019 at 1:00 AM UTC, in which case, the
        Payments Data Controller is Fingaz Payments
        Australia Pty. Ltd. (“Fingaz Payments Australia”)
        for that transaction.
      </li>
      <li>
        If your country of residence is in the European
        Union, the Payment Data Controller is Fingaz
        Payments UK. On or after March 25, 2019, any change
        of the Payment Data Controller to Fingaz Payments
        Luxembourg S.A., if any, will be notified to you at
        the time of checkout or by other appropriate means.
      </li>
      <li>
        If your country of residence is outside of the
        United States, China, India and Australia, the
        Payments Data Controller is Fingaz Payments UK.
      </li>
    </ul>
    <p>
      If you change your country of residence, the Data
      Controller and/or Payments Data Controller will be
      determined by your new country of residence as
      specified above, from the date on which your country
      of residence changes. To this end the Data Controller
      and/or Payment Data Controller that originally
      collected your personal information will need to
      transfer such personal information to the new
      applicable Data Controller and/or Payments Data
      Controller due to the fact that such transfer is
      necessary for the performance of the contractual
      relationship with you.
    </p>
    <p>
      Please see the Contact Us section below for contact
      details of the Data Controllers and Payments Data
      Controllers.
    </p>
    <h4>
      <a id="sec201910_2"></a>2. INFORMATION WE COLLECT
    </h4>
    <p>
      There are three general categories of information we
      collect.
    </p>
    <p>2.1 Information You Give to Us.</p>
    <p>
      <u
        >2.1.1 Information that is necessary for the use of
        the Fingaz Platform.</u
      >
    </p>
    <p>
      We ask for and collect the following personal
      information about you when you use the Fingaz
      Platform. This information is necessary for the
      adequate performance of the contract between you and
      us and to allow us to comply with our legal
      obligations. Without it, we may not be able to provide
      you with all the requested services.
    </p>
    <ul>
      <li>
        Account Information. When you sign up for an Fingaz
        Account, we require certain information such as your
        first name, last name, email address, and date of
        birth.
      </li>
      <li>
        Profile and Listing Information. To use certain
        features of the Fingaz Platform (such as booking or
        creating a Listing), we may ask you to provide
        additional information, which may include your
        address, phone number, and a profile picture.
      </li>
      <li>
        Identity Verification Information. To help create
        and maintain a trusted environment, we may collect
        identity verification information (such as images of
        your government issued ID, passport, national ID
        card, or driving license, as permitted by applicable
        laws) or other authentication information. To learn
        more, see our Help Center article about
        <a>providing identification on Fingaz</a>.
      </li>
      <li>
        Payment Information. To use certain features of the
        Fingaz Platform (such as booking or creating a
        Listing), we may require you to provide certain
        financial information (like your bank account or
        credit card information) in order to facilitate the
        processing of payments (via Fingaz Payments).
      </li>
      <li>
        Communications with Fingaz and other Members. When
        you communicate with Fingaz or use the Fingaz
        Platform to communicate with other Members, we
        collect information about your communication and any
        information you choose to provide.
      </li>
    </ul>
    <p><u>2.1.2 Information you choose to give us.</u></p>
    <p>
      You may choose to provide us with additional personal
      information in order to obtain a better user
      experience when using Fingaz Platform. This additional
      information will be processed based on our legitimate
      interest or when applicable, your consent.
    </p>
    <ul>
      <li>
        Additional Profile Information. You may choose to
        provide additional information as part of your
        Fingaz profile (such as gender, preferred
        language(s), city, and a personal description). Some
        of this information as indicated in your Account
        settings is part of your public profile page, and
        will be publicly visible to others.
      </li>
      <li>
        Address Book Contact Information. You may choose to
        import your address book contacts or enter your
        contacts’ information manually to access certain
        features of the Fingaz Platform, like inviting them
        to use Fingaz.
      </li>
      <li>
        Other Information. You may otherwise choose to
        provide us information when you fill in a form,
        update or add information to your Fingaz Account,
        respond to surveys, post to community forums,
        participate in promotions, communicate with our
        customer care team, share your experience with us
        (such as through Host Stories), or use other
        features of the Fingaz Platform.
      </li>
    </ul>
    <p>
      <u
        >2.1.3 Information that is necessary for the use of
        the Payment Services.</u
      >
    </p>
    <p>
      The Payments Data Controller needs to collect the
      following information necessary for the adequate
      performance of the contract with you and to comply
      with applicable law (such as anti-money laundering
      regulations). Without it, you will not be able to use
      Payment Services:
    </p>
    <ul>
      <li>
        Payment Information. When you use the Payment
        Services, the Payments Data Controller requires
        certain financial information (like your bank
        account or credit card information) in order to
        process payments and comply with applicable law.
      </li>
      <li>
        Identity Verification and Other Information. If you
        are a Host, the Payments Data Controller may require
        identity verification information (such as images of
        your government issued ID, passport, national ID
        card, or driving license) or other authentication
        information, your date of birth, your address, email
        address, phone number and other information in order
        to verify your identity, provide the Payment
        Services to you, and to comply with applicable law.
      </li>
    </ul>
    <p>
      <u
        >2.1.4 Information We Automatically Collect from
        Your Use of the Fingaz Platform and Payment
        Services.</u
      >
    </p>
    <p>
      When you use the Fingaz Platform and the Payment
      Services, we automatically collect personal
      information about the services you use and how you use
      them. This information is necessary for the adequate
      performance of the contract between you and us, to
      enable us to comply with legal obligations and given
      our legitimate interest in being able to provide and
      improve the functionalities of the Fingaz Platform and
      Payment Services.
    </p>
    <ul>
      <li>
        Geo-location Information. When you use certain
        features of the Fingaz Platform, we may collect
        information about your precise or approximate
        location as determined through data such as your IP
        address or mobile device’s GPS to offer you an
        improved user experience. Most mobile devices allow
        you to control or disable the use of location
        services for applications in the device’s settings
        menu. Fingaz may also collect this information even
        when you are not using the app if this connection is
        enabled through your settings or device permissions.
      </li>
      <li>
        Usage Information. We collect information about your
        interactions with the Fingaz Platform such as the
        pages or content you view, your searches for
        Listings, bookings you have made, and other actions
        on the Fingaz Platform.
      </li>
      <li>
        Log Data and Device Information. We automatically
        collect log data and device information when you
        access and use the Fingaz Platform, even if you have
        not created an Fingaz Account or logged in. That
        information includes, among other things: details
        about how you’ve used the Fingaz Platform (including
        if you clicked on links to third party
        applications), IP address, access dates and times,
        hardware and software information, device
        information, device event information, unique
        identifiers, crash data, cookie data, and the pages
        you’ve viewed or engaged with before or after using
        the Fingaz Platform.
      </li>
      <li>
        Cookies and Similar Technologies. We use cookies and
        other similar technologies when you use our
        platform, use our mobile app, or engage with our
        online ads or email communications. We may collect
        certain information by automated means using
        technologies such as cookies, web beacons, pixels,
        browser analysis tools, server logs, and mobile
        identifiers. In many cases the information we
        collect using cookies and other tools is only used
        in a non-identifiable without reference to personal
        information. For example, we may use information we
        collect to better understand website traffic
        patterns and to optimize our website experience. In
        some cases we associate the information we collect
        using cookies and other technology with your
        personal information. Our business partners may also
        use these tracking technologies on the Fingaz
        Platform or engage others to track your behavior on
        our behalf.
      </li>
      <li>
        Pixels and SDKs. Third parties, including Facebook,
        may use cookies, web beacons, and other storage
        technologies to collect or receive information from
        our websites and elsewhere on the internet and use
        that information to provide measurement services and
        target ads. For apps, that third parties, including
        Facebook, may collect or receive information from
        your app and other apps and use that information to
        provide measurement services and targeted ads. Users
        can opt-out of the collection and use of information
        for ad targeting by updating their Facebook account
        ad settings and by contacting opt-out@Fingaz.com
        with a description of your request and validation
        information. Users can access a mechanism for
        exercising such choice by going to
        https://www.aboutads.info/choices and
        <a href="https://www.youronlinechoices.eu/"
          >https://www.youronlinechoices.eu/</a
        >. For more information on our use of these
        technologies, see our
        <a routerLink="/cookie-policy">Cookie Policy</a>.
      </li>
      <li>
        Do Not Track Signals. While you may disable the
        usage of cookies through your browser settings, the
        Fingaz Platform currently does not respond to a “Do
        Not Track” signal in the HTTP header from your
        browser or mobile application due to lack of
        standardization regarding how that signal should be
        interpreted.
      </li>
      <li>
        Payment Transaction Information. Fingaz Payments
        collects information related to your payment
        transactions through the Fingaz Platform, including
        the payment instrument used, date and time, payment
        amount, payment instrument expiration date and
        billing postcode, PayPal email address, IBAN
        information, your address and other related
        transaction details. This information is necessary
        for the adequate performance of the contract between
        you and Fingaz Payments and to allow the provision
        of the Payment Services.
      </li>
    </ul>
    <p>
      <u
        >2.1.5 Information We Collect from Third Parties.</u
      >
    </p>
    <p>
      Fingaz and Fingaz Payments may collect information,
      including personal information, that others provide
      about you when they use the Fingaz Platform and the
      Payment Services, or obtain information from other
      sources and combine that with information we collect
      through the Fingaz Platform and the Payment Services.
      We do not control, supervise or respond for how the
      third parties providing your information process your
      Personal Information, and any information request
      regarding the disclosure of your personal information
      to us should be directed to such third parties.
    </p>
    <ul>
      <li>
        Third Party Services. If you link, connect, or
        signin to your Fingaz Account with a third party
        service (e.g. Google, Facebook, WeChat), the third
        party service may send us information such as your
        registration, friends list, and profile information
        from that service. This information varies and is
        controlled by that service or as authorized by you
        via your privacy settings at that service.
      </li>
      <li>
        Your References. If someone has written a reference
        for you, it will be published on your Fingaz public
        profile page with your consent. To learn more, see
        our Help Center article about
        <a>References</a>.
      </li>
      <li>
        Background Information. For Members in the United
        States, to the extent permitted by applicable laws,
        Fingaz and Fingaz Payments may obtain reports from
        public records of criminal convictions or sex
        offender registrations. For Members outside of the
        United States, to the extent permitted by applicable
        laws and with your consent where required, Fingaz
        and Fingaz Payments may obtain the local version of
        police, background or registered sex offender
        checks. We may use your information, including your
        full name and date of birth, to obtain such reports.
      </li>
      <li>
        Enterprise Product Invitations and Account
        Management. Organizations that use our Enterprise
        products (such a Fingaz for work and programs with
        property managers and owners) may submit personal
        information to facilitate account management and
        invitations to use enterprise products.
      </li>
      <li>
        Referrals. If you are invited to Fingaz, the person
        who invited you may submit personal information
        about you such as your email address or other
        contact information.
      </li>
      <li>
        Other Sources. To the extent permitted by applicable
        law, we may receive additional information about
        you, such as demographic data or information to help
        detect fraud and safety issues, from third party
        service providers and/or partners, and combine it
        with information we have about you. For example, we
        may receive background check results (with your
        consent where required) or fraud warnings from
        service providers like identity verification
        services for our fraud prevention and risk
        assessment efforts. We may receive information about
        you and your activities on and off the Fingaz
        Platform through partnerships, or about your
        experiences and interactions from our partner ad
        networks.
      </li>
    </ul>
    <p>2.2 Children’s Data.</p>
    <p>
      Our websites and applications are not directed to
      children under 16 and we do not knowingly collect any
      personal information directly from children under 16.
      If you believe that we processing the personal
      information pertaining to a child inappropriately, we
      take this very seriously and urge you to contact us
      using the information provided under the “Contact Us”
      section below.
    </p>
    <h4>
      <a id="sec201910_3"></a>3. HOW WE USE INFORMATION WE
      COLLECT
    </h4>
    <p>
      We may use, store, and process personal information to
      (1) provide, understand, improve, and develop the
      Fingaz Platform, (2) create and maintain a trusted and
      safer environment (such as to comply with our legal
      obligations and ensure compliance with Fingaz
      Policies) and (3) provide, personalize, measure, and
      improve our advertising and marketing.
    </p>
    <p>
      3.1 Provide, Improve, and Develop the Fingaz Platform.
      We may use the personal information to provide,
      improve, and develop the Fingaz Platform such as to:
    </p>
    <ul>
      <li>
        enable you to access and use the Fingaz Platform,
      </li>
      <li>enable you to communicate with other Members,</li>
      <li>
        operate, protect, improve, and optimize the Fingaz
        Platform and experience, such as by performing
        analytics and conducting research,
      </li>
      <li>provide customer service,</li>
      <li>
        send you service or support messages, updates,
        security alerts, and account notifications,
      </li>
      <li>
        if you provide us with your contacts’ information,
        we may process this information: (i) to facilitate
        your referral invitations, (ii) send your requests
        for references, (iii) for fraud detection and
        prevention, and (iv) for any purpose you authorize
        at the time of collection,
      </li>
      <li>
        to operate, protect, improve, and optimize the
        Fingaz Platform and experience, and personalize and
        customize your experience (such as making Listing
        suggestions, ranking search results), and facilitate
        claims with our Host Guarantee, Host Protection
        Insurance, Experience Protection Insurance or other
        similar host protection programs, we conduct
        profiling based on your interactions with the Fingaz
        Platform, your search and booking history, your
        profile information and preferences, and other
        content you submit to the Fingaz Platform, and
      </li>
      <li>enable your use of our enterprise products.</li>
    </ul>
    <p>
      We process this personal information for these
      purposes given our legitimate interest in improving
      the Fingaz Platform and our Members’ experience with
      it, and where it is necessary for the adequate
      performance of the contract with you.
    </p>
    <p>
      3.2 Create and Maintain a Trusted and Safer
      Environment. We may use the personal information to
      create and maintain a trusted and safer environment
      such as to:
    </p>
    <ul>
      <li>
        detect and prevent fraud, spam, abuse, security
        incidents, and other harmful activity,
      </li>
      <li>
        conduct security investigations and risk
        assessments,
      </li>
      <li>
        verify or authenticate information or
        identifications provided by you (such as to verify
        your Accommodation address or compare your
        identification photo to another photo you provide),
      </li>
      <li>
        conduct checks against databases and other
        information sources, including background or police
        checks, to the extent permitted by applicable laws
        and with your consent where required,
      </li>
      <li>comply with our legal obligations,</li>
      <li>
        Resolve any disputes with any of our Members and
        enforce our agreements with third parties,
      </li>
      <li>
        enforce our
        <a routerLink="/terms-of-service"
          >Terms of Service</a
        >
        and other policies, and
      </li>
      <li>
        in connection with the activities above, we may
        conduct profiling based on your interactions with
        the Fingaz Platform, your profile information and
        other content you submit to the Fingaz Platform, and
        information obtained from third parties. In limited
        cases, automated processes may restrict or suspend
        access to the Fingaz Platform if such processes
        detect activity that we think poses a safety or
        other risk to the Fingaz Platform, our community, or
        third parties. If you challenge the decisioning
        based on the automated process, please contact us as
        provided in the Contact Us section below.
      </li>
    </ul>
    <p>
      We process this personal information for these
      purposes given our legitimate interest in protecting
      the Fingaz Platform, to measure the adequate
      performance of our contract with you, and to comply
      with applicable laws.
    </p>
    <p>
      3.3 Provide, Personalize, Measure, and Improve our
      Advertising and Marketing. We may use the personal
      information to provide, personalize, measure, and
      improve our advertising and marketing such as to:
    </p>
    <ul>
      <li>
        send you promotional messages, marketing,
        advertising, and other information that may be of
        interest to you based on your preferences (including
        information about Fingaz or partner campaigns and
        services) and social media advertising through
        social media platforms such as Facebook or Google),
      </li>
      <li>
        personalize, measure, and improve our advertising,
      </li>
      <li>
        Administer referral programs, rewards, surveys,
        sweepstakes, contests, or other promotional
        activities or events sponsored or managed by Fingaz
        or its third party partners,
      </li>
      <li>
        conduct profiling on your characteristics and
        preferences (based on the information you provide to
        us, your interactions with the Fingaz Platform,
        information obtained from third parties, and your
        search and booking history) to send you promotional
        messages, marketing, advertising and other
        information that we think may be of interest to you,
      </li>
      <li>
        enrolling in an email subscription will not affect
        the frequency of administrative emails that we may
        send in connection with any Fingaz Account. No fee
        is charged for sending promotional emails to you,
        but third-party data rates may apply. Note that you
        may not be able to take advantage of certain
        promotions if you do not have an Fingaz Account, and
      </li>
      <li>
        invite you to events and relevant opportunities (for
        example, when you share your Host story, we may use
        the information provided to reach out to you to
        invite you to relevant events).
      </li>
    </ul>
    <p>
      We will process your personal information for the
      purposes listed in this section given our legitimate
      interest in undertaking marketing activities to offer
      you products or services that may be of your interest.
    </p>
    <p>
      3.4 How the Payments Data Controller uses the Personal
      Information Collected. We may use the personal
      information as a part of Payment services such as to:
    </p>
    <ul>
      <li>
        Enable you to access and use the Payment Services.
      </li>
      <li>
        Detect and prevent fraud, abuse, security incidents,
        and other harmful activity.
      </li>
      <li>
        Conduct security investigations and risk
        assessments.
      </li>
      <li>
        Conduct checks against databases and other
        information sources.
      </li>
      <li>
        Comply with legal obligations (such as anti-money
        laundering regulations).
      </li>
      <li>
        Enforce the Payment Terms and other payment
        policies.
      </li>
      <li>
        With your consent, send you promotional messages,
        marketing, advertising, and other information that
        may be of interest to you based on your preferences.
      </li>
    </ul>
    <p>
      The Payments Data Controller processes this personal
      information given its legitimate interest in improving
      the Payment Services and its users’ experience with
      it, and where it is necessary for the adequate
      performance of the contract with you and to comply
      with applicable laws.
    </p>
    <p>3.5 SMS Terms for U.S.</p>
    <p>
      For text messaging in the United States, by
      requesting, joining, agreeing to, enrolling in,
      signing up for, acknowledging, or otherwise consenting
      to receive one or more text messages (“Opting In”) or
      using a Fingaz arrangement in which Fingaz sends (or
      indicates that it may send, or receives a request that
      it send) one or more text messages (“Text Message
      Service”), you accept these SMS Terms for U.S. (“SMS
      Terms”), consent to the handling of your personal
      information as described in the Fingaz Privacy Policy,
      and agree to resolve disputes with Fingaz as described
      in our Terms of Service. Message and data rates may
      apply.
    </p>
    <p>
      Fingaz will use reasonable commercial efforts to
      deliver the automated marketing text messages to the
      number you provide through compatible wireless
      carriers. Carriers and Fingaz are not liable for
      delayed or undelivered messages. The short code we use
      for some Text Message Services may not be supported on
      all U.S. carriers.
    </p>
    <p><u>Opting In</u></p>
    <p>By Opting In to a Text Message Service:</p>
    <ul>
      <li>
        You expressly authorize Fingaz to use autodialer or
        non-autodialer technology to send text messages to
        the cell phone number associated with your Opt-In
        (i.e., the number listed on the Opt-In form or
        instructions, or, if none, the number from which you
        send the Opt-In, or, if none, the number on file for
        your account). You also authorize Fingaz to include
        marketing content in any such messages. You do not
        have to Opt In or agree to Opt In as a condition of
        purchase.
      </li>
      <li>
        You consent to the use of an electronic record to
        document your Opt-In. To withdraw that consent,
        request a free paper or email copy of the Opt-In, or
        to update our records with your contact information,
        please contact us via the methods described in the
        Contact Us section. To view and retain an electronic
        copy of these SMS Terms or the rest of your Opt-In,
        you will need (i) a device (such as a computer or
        cell phone) with internet access, and (ii) either a
        printer or storage space on such device. If you
        withdraw your consent, certain features of our
        service may not be available to you. To update
        information on how we would contact you
        electronically, visit your Notification settings in
        your account settings.
      </li>
      <li>
        You confirm that you are the subscriber to the
        relevant phone number or that you are the customary
        user of that number on a family or business plan and
        that you are authorized to Opt In.
      </li>
      <li>
        You consent to the use of an electronic record to
        document your Opt-In. To withdraw that consent,
        update our records with your contact information,
        please visit your Fingaz account Notifications
        settings or contact
        <a routerLink="/contact">customer support</a>.
      </li>
      <li>
        These SMS Terms still will apply if you withdraw the
        consent mentioned above or opt out of the Text
        Message Service.
      </li>
    </ul>
    <p>
      After Opting In, in addition to the main messages the
      service offers, you may receive one or more welcome
      messages or administrative messages, such as (in some
      cases) a request to confirm your Opt-In.
    </p>
    <p>
      <u>About the Text Message Services and Opting Out</u>
    </p>
    <p>
      Message and data rates may apply. Unless otherwise
      noted, Text Message Services send multiple, recurring
      messages. Fingaz may terminate any Text Message
      Service or your participation in it at any time with
      or without notice, including, for example, before you
      have received any or all messages that you otherwise
      would have received, but these SMS Terms still will
      apply. Text STOP to any promotional message to Opt Out
      or if applicable update your “Notification” settings
      on your Fingaz account.
    </p>
    <p>
      For additional help, text HELP in response to a
      marketing message or contact
      <a routerLink="/contact">customer service</a>.
    </p>
    <p>3.6 Your Choices</p>
    <p>
      You have choices on the promotional messages that you
      choose to receive.
    </p>
    <ul>
      <li>
        You can limit the information you provide to Fingaz.
        Participation in promotions and marketing programs
        is voluntary.
      </li>
      <li>
        You can limit the communications that Fingaz sends
        to you.
      </li>
      <ul>
        <li>
          To opt-out of marketing emails, simply click the
          link labeled “unsubscribe” at the bottom of any
          marketing email we send you or access the
          notification settings in your Fingaz Account.
        </li>
        <li>
          To revoke permissions that you may have given to
          send promotional text messages, text STOP in
          response to any message.
        </li>
        <li>
          You can always update your notification settings
          within your Fingaz Account by visiting the
          Notifications section of your Fingaz Account.
          Please note that even if you opt-out of marketing
          communications, we may still need to contact you
          with important transactional information about
          your account. For example, even if you opt-out of
          emails, we may still send you activity
          confirmations or fraud alerts.
        </li>
      </ul>
    </ul>
    <h4><a id="sec201910_4"></a>4. SHARING & DISCLOSURE</h4>
    <p>
      4.1 Advertising and Social Media; Sharing With Your
      Consent.
    </p>
    <p>
      Where you have provided consent, we share your
      information, including personal information, as
      described at the time of consent, such as when you
      authorize a third party application or website to
      access your Fingaz Account or when you participate in
      promotional activities conducted by Fingaz partners or
      third parties.
    </p>
    <p>
      Where permissible according to applicable law we may
      use certain limited personal information about you,
      such as your email address, to hash it and to share it
      with social media platforms, such as Facebook or
      Google, to generate leads, drive traffic to our
      websites or otherwise promote our products and
      services or the Fingaz Platform. These processing
      activities are based on our legitimate interest in
      undertaking marketing activities to offer you products
      or services that may be if your interest.
    </p>
    <p>
      The social media platforms with which we may share
      your personal information are not controlled or
      supervised by Fingaz. Therefore, any questions
      regarding how your social media platform service
      provider processes your personal information should be
      directed to such provider.
    </p>
    <p>
      Please note that you may, at any time ask Fingaz to
      cease processing your data for these direct marketing
      purposes by sending an e-mail to opt-out@Fingaz.com.
    </p>
    <p>4.2 Sharing between Members.</p>
    <p>
      To help facilitate bookings or other interactions
      between Members, we may need to share certain
      information, including personal information, with
      other Members, as it is necessary for the adequate
      performance of the contract between you and us, as
      follows:
    </p>
    <ul>
      <li>
        When you as a Guest submit a booking request,
        certain information about you is shared with the
        Host (and Co-Host, if applicable), including your
        profile, full name, the full name of any additional
        Guests, your cancellation history, and other
        information you agree to share. When your booking is
        confirmed, we will disclose additional information
        to assist with coordinating the trip, like your
        phone number.
      </li>
      <li>
        When you as a Host (or Co-Host, if applicable) have
        a confirmed booking, certain information is shared
        with the Guest (and the additional Guests they may
        invite, if applicable) to coordinate the booking,
        such as your profile, full name, phone number, and
        Accommodation or Experience address.
      </li>
      <li>
        When you as a Host invite another Member to become a
        Co-Host, you authorize the Co-Host to access and
        update your information and Member Content,
        including but not limited to certain information
        like your full name, phone number, Accommodation
        address, calendar, Listing information, Listing
        photos, and email address.
      </li>
      <li>
        When you as a Guest invite additional Guests to a
        booking, your full name, travel dates, Host name,
        Listing details, the Accommodation address, and
        other related information will be shared with each
        additional Guest.
      </li>
      <li>
        When you as a Guest initiate a Group Payment Booking
        Request certain information about each participant
        such as first name, last initial, profile picture as
        well as the booking details is shared among all
        participants of the Group Payment Booking Request.
      </li>
    </ul>
    <p>
      We don’t share your billing and payout information
      with other Members.
    </p>
    <p>
      4.3 Profiles, Listings, and other Public Information.
    </p>
    <p>
      The Fingaz Platform lets you publish information,
      including personal information, that is visible to the
      general public. For example:
    </p>
    <ul>
      <li>
        Parts of your public profile page, such as your
        first name, your description, and city, are publicly
        visible to others.
      </li>
      <li>
        Listing pages are publicly visible and include
        information such as the Accommodation or
        Experience’s approximate location (neighborhood and
        city) or precise location (where you have provided
        your consent), Listing description, calendar
        availability, your public profile photo, aggregated
        demand information (like page views over a period of
        time), and any additional information you choose to
        share.
      </li>
      <li>
        After completing a booking, Guests and Hosts may
        write Reviews and rate each other. Reviews and
        Ratings are a part of your public profile page and
        may also be surfaced elsewhere on the Fingaz
        Platform (such as the Listing page).
      </li>
      <li>
        If you submit content in a community or discussion
        forum, blog or social media post, or use a similar
        feature on the Fingaz Platform, that content is
        publicly visible.
      </li>
    </ul>
    <p>
      Based on our legitimate interest to promote the Fingaz
      Platform we may display parts of the Fingaz Platform
      (e.g., your Listing page) on sites operated by
      Fingaz’s business partners, using technologies such as
      widgets or APIs. If your Listings are displayed on a
      partner’s site, information from your public profile
      page may also be displayed.
    </p>
    <p>
      Information you share publicly on the Fingaz Platform
      may be indexed through third party search engines. In
      some cases, you may opt-out of this feature in your
      Account settings. If you change your settings or your
      public-facing content, these search engines may not
      update their databases. We do not control the
      practices of third party search engines, and they may
      use caches containing your outdated information.
    </p>
    <p>4.4 Additional Services by Hosts.</p>
    <p>
      Hosts may need to use third party services available
      through the Fingaz Platform to assist with managing
      their Accommodation or providing additional services
      requested by you, such as cleaning services or lock
      providers. Hosts may use features on the Fingaz
      Platform to share information about the Guest (like
      check-in and check-out dates, Guest name, Guest phone
      number) with such third party service providers for
      the purposes of coordinating the stay, managing the
      Accommodation, or providing other services. Hosts are
      responsible for third party service providers they use
      and ensuring those service providers process Guest
      information securely and in compliance with applicable
      law including data privacy and data protection laws.
    </p>
    <p>
      4.5 Compliance with Law, Responding to Legal Requests,
      Preventing Harm and Protection of our Rights.
    </p>
    <p>
      Fingaz and Fingaz Payments may disclose your
      information, including personal information, to
      courts, law enforcement, governmental authorities, tax
      authorities, or authorized third parties, if and to
      the extent we are required or permitted to do so by
      law or if such disclosure is reasonably necessary: (i)
      to comply with our legal obligations, (ii) to comply
      with a valid legal request or to respond to claims
      asserted against Fingaz, (iii) to respond to a valid
      legal request relating to a criminal investigation or
      alleged or suspected illegal activity or any other
      activity that may expose us, you, or any other of our
      users to legal liability (See more information on
      Fingaz’s Law Enforcement Guidelines <a>here</a>), (iv)
      to enforce and administer our Terms of Service, the
      Payment Terms or
      <a routerLink="/terms-of-service">other</a>
      agreements with Members, or (v) to protect the rights,
      property or personal safety of Fingaz, its employees,
      its Members, or members of the public. For example, if
      permitted due to the forgoing circumstances, Host tax
      information may be shared with tax authorities or
      other governmental agencies.
    </p>
    <p>
      These disclosures may be necessary to comply with our
      legal obligations, for the protection of your or
      another person's vital interests or for the purposes
      of our or a third party’s legitimate interest in
      keeping the Fingaz Platform secure, preventing harm or
      crime, enforcing or defending legal rights,
      facilitating the collection of taxes and prevention of
      tax fraud or preventing damage.
    </p>
    <p>
      Where appropriate, we may notify Members about legal
      requests unless: (i) providing notice is prohibited by
      the legal process itself, by court order we receive,
      or by applicable law, or (ii) we believe that
      providing notice would be futile, ineffective, create
      a risk of injury or bodily harm to an individual or
      group, or create or increase a risk of fraud upon
      Fingaz’s property, its Members and the Fingaz
      Platform. In instances where we comply with legal
      requests without notice for these reasons, we may
      attempt to notify that Member about the request after
      the fact where appropriate and where we determine in
      good faith that we are no longer prevented from doing
      so.
    </p>
    <p>4.6 Service Providers.</p>
    <p>
      Fingaz and Fingaz Payments uses a variety of
      third-party service providers to help us provide
      services related to the Fingaz Platform and the
      Payment Services. Service providers may be located
      inside or outside of the European Economic Area
      (“EEA”). In particular, our service providers are
      based in Europe, India, Asia Pacific and North and
      South America.
    </p>
    <p>
      For example, service providers may help us: (i) verify
      your identity or authenticate your identification
      documents, (ii) check information against public
      databases, (iii) conduct background or police checks,
      fraud prevention, and risk assessment, (iv) perform
      product development, maintenance and debugging, (v)
      allow the provision of the Fingaz Services through
      third party platforms and software tools (e.g. through
      the integration with our APIs), (vi) provide customer
      service, advertising, or payments services, or (vii)
      process, handle or assess insurance claims or other
      similar claims (such as claims under the Fingaz Host
      Guarantee). These providers have limited access to
      your personal information to perform these tasks on
      our behalf, and are contractually bound to protect the
      personal information and only use the personal
      information in accordance with our instructions .
    </p>
    <p>
      Fingaz and the Fingaz Payments will need to share your
      information, including personal information, in order
      to ensure the adequate performance of our contract
      with you.
    </p>
    <p>4.7 Corporate Affiliates.</p>
    <p>
      To enable or support us in providing the Fingaz
      Platform and the Payment Services, we may share your
      information, including personal information, within
      our corporate family of companies (both financial and
      non-financial entities) that are related by common
      ownership or control.
    </p>
    <ul>
      <li>
        Sharing with Fingaz, Inc. Even if your country of
        residence is not the United States, your information
        may be shared with Fingaz, Inc. which provides the
        technical infrastructure for the Fingaz Platform,
        product development and maintenance, customer
        support, trust and safety and other business
        operation services to other Fingaz entities. The
        data sharing is necessary for the performance of the
        contract between you and us and is based on our
        legitimate interests in providing the Fingaz
        Platform globally.
      </li>
      <li>
        Sharing with Fingaz Payments. In order to facilitate
        payments on or through the Fingaz Platform, certain
        information as described above in “<u
          >Information that is necessary for the use of the
          Payment Services” section above</u
        >, may be shared with the relevant Fingaz Payments
        entity. The data sharing is necessary for the
        performance of the contract between you and us.
      </li>
      <li>
        Sharing with Fingaz Ireland. If your country of
        residence is the United States, some of your
        information may be shared with Fingaz Ireland when
        you create a Listing or when you book a Host Service
        located outside of the United States (other than in
        China). The information shared in these
        circumstances may include: (i) your name, e-mail
        address and phone number, (ii) information relating
        to the Listing or Host Service, including its
        address, (iii) booking information for the Listing
        or Host Service, including but not limited to
        booking dates and payment amounts, and (iv) the
        names, nationalities, and identification details
        (including passport/national ID numbers and expiry
        dates) of any Guests. Additionally, when you send a
        message to a Host or Guest outside of the United
        States (other than China), your first name, profile
        picture and message content sent via the Fingaz
        Platform will be shared with Fingaz Ireland. The
        data sharing is necessary for the performance of the
        contract between you and us.
      </li>
      <li>
        Sharing with Fingaz GSL. Even if your country of
        residence is not Japan, some of your information
        will be shared with Fingaz GSL in the following
        circumstances:
      </li>
      <ul>
        <li>
          Creating a Listing. If you create a Listing in
          Japan: (i) your name, phone number, gender, birth
          date, email address, information relating to where
          you live and work details on your profile page,
          (ii) information relating to the Listing (e.g.,
          address, government notification numbers, permit
          details or other information), (iii) booking
          information relating to the Listing, such as Guest
          details as set out below in Host Services, booking
          dates, and payment amounts, (vi) details (if any)
          of the payout method associated with the Listing,
          and (v) messages between you and prospective and
          confirmed Guests at the Listing.
        </li>
        <li>
          Host Services. If you book a Host Service located
          in Japan: (i) your name, phone number, gender,
          birth date, email address, information relating to
          where you live and your work on your profile page,
          IP address in relation to the transaction, (ii)
          booking dates, (iii) messages between the Host and
          you or other Guests on the same booking, (iv)
          details (if any) of the payment method used by any
          Guests for booking the Host Service, and (v) the
          names and identification detail (if available) of
          any Guests.
        </li>
        <li>
          Sending Messages. If you send a message to a Host
          in relation to that Host’s Listing in Japan, your
          first name, profile picture and message content.
        </li>
      </ul>
    </ul>
    <p>
      The data sharing is necessary for the performance of
      the contract between you and us to enable you to list
      or book Host Services in Japan and communicate with
      Members in Japan.
    </p>
    <ul>
      <li>
        Sharing with Fingaz China. Even if your country of
        residence is not China, some of your information
        will be shared with Fingaz China in the following
        circumstances:
      </li>
      <ul>
        <li>
          Creating a Listing. If you create a Listing in
          China, information shared includes: (i) your name,
          phone number, email address, and passport/ID
          details, (ii) information relating to the Listing
          (e.g., address), (iii) booking information
          relating to the Listing, such as Guest details as
          set out below in Host Services, booking dates, and
          payment amounts, and (iv) messages between you and
          prospective and confirmed Guests at the Listing.
        </li>
        <li>
          Host Services. If you book a Host Service located
          in China, information shared includes: (i) your
          name, phone number, and email address (ii) booking
          dates, (iii) messages between the Host and you or
          other Guests on the same booking, and (iv) the
          names, nationalities, and identification details
          (including passport/national ID numbers and expiry
          dates) of any Guests.
        </li>
        <li>
          Sending Messages. If you send a message to a Host
          in relation to that Host’s Listing in China,
          information shared includes: your first name,
          profile picture and message content.
        </li>
      </ul>
    </ul>
    <p>
      The data sharing is necessary for the performance of
      the contract between you and us to enable you to list
      or book Host Services in China and communicate with
      Members in China. Where required under law and you
      have expressly granted permission, Fingaz China may
      disclose your information to Chinese government
      agencies without further notice to you. We’ll notify
      you in advance in the above situations, and in
      specific situations where we apply any practices that
      differ from what is described in this Privacy Policy
      (including practices pertaining to disclosures to
      government agencies).
    </p>
    <p>
      Additionally, we share your information, including
      personal information, with our corporate affiliates in
      order to support and integrate, promote, and to
      improve the Fingaz Platform and our affiliates’
      services.
    </p>
    <p>4.8 Collection and Remittance of Occupancy Taxes.</p>
    <p>
      In jurisdictions where Fingaz facilitates the
      Collection and Remittance of Occupancy Taxes as
      described in the “Taxes” section of the
      <a routerLink="/terms-of-service">Terms of Service</a
      >, Hosts and Guests, where legally permissible
      according to applicable law, expressly grant us
      permission, without further notice, to disclose Hosts’
      and Guests’ data and other information relating to
      them or to their transactions, bookings,
      Accommodations and Occupancy Taxes to the relevant tax
      authority, including, but not limited to, the Host’s
      or Guest’s name, Listing addresses, transaction dates
      and amounts, tax identification number(s), the amount
      of taxes received (or due) by Hosts from Guests, and
      contact information.
    </p>
    <p>4.9 Government Registration.</p>
    <p>
      In jurisdictions where Fingaz facilitates or requires
      a registration, notification, permit, or license
      application of a Host with a local governmental
      authority through the Fingaz Platform in accordance
      with local law, we may share information of
      participating Hosts with the relevant authority, both
      during the application process and, if applicable,
      periodically thereafter, such as the Host’s full name
      and contact details, Accommodation address, tax
      identification number, Listing details, and number of
      nights booked.
    </p>
    <p>
      4.10 Information Provided to Enterprise Customers.
    </p>
    <p>
      If you have linked your Fingaz Account to the Fingaz
      Account of a company or other organization (an
      “Enterprise”), added your work email address, or have
      a booking facilitated via another party (such as the
      future employer or other entity) or used a coupon in a
      similar capacity in connection with an Enterprise
      (such as using a coupon to pay for an accommodation
      for an enterprise related event like employment
      onboarding, orientation, meetings, etc.) through one
      of our Enterprise products, that Enterprise will have
      access to your name, contact details, permissions and
      roles, and other information as required to enable use
      by you and the Enterprise of such Enterprise products.
    </p>
    <p>
      4.11 Host Information Provided to Fingaz for Work
      Customers.
    </p>
    <p>
      If a booking is designated as being for business
      purposes and made by a Guest affiliated with an
      Enterprise, and the Enterprise is enrolled in our
      Fingaz for Work , such as a booking made for business
      purposes using a coupon provided by an Enterprise, we
      may disclose information related to the booking to the
      Enterprise, such as the name of the Host, the
      Accommodation address, booking dates, pricing, Listing
      details, and other related information, to the extent
      necessary for the adequate performance of Fingaz’s
      contract with the Enterprise and to provide the
      services. . At the request of the Enterprise or the
      Guest, we may also share this information with third
      parties engaged by the Enterprise to provide travel
      management, travel planning, financial reporting,
      personnel management, crisis management, or other
      services.
    </p>
    <p>
      4.12 Guest Information Provided to Fingaz for Work
      Customers.
    </p>
    <p>
      If you have linked your Fingaz Account with an
      Enterprise, and you are included on a booking
      designated as being for business purposes, we may
      disclose information related to the booking to the
      Enterprise to the extent necessary for the adequate
      performance of Fingaz’s contract with the Enterprise.
      Typically, this includes information related to the
      booking, such as your name, dates of the booking,
      number of guests, pricing, Listing details, the
      Accommodation address, and other related information.
      At the request of you or your Enterprise, we may also
      disclose this information to service providers engaged
      by it, such as companies that provide travel
      management, financial reporting, personnel management,
      crisis management, or other services. In the event of
      a dispute, emergency, or similar situation involving a
      booking identified as being for business purposes,
      Fingaz may also share additional relevant information
      it believes is necessary to safely and quickly address
      the situation.
    </p>
    <p>4.13 Programs with Managers and Owners.</p>
    <p>
      We may share personal information and booking
      information with landlords, management companies,
      and/or property owners in order to facilitate programs
      with these partners. For example, guest booking and
      personal information may be shared with owners and
      property managers of the building, complex, or planned
      community where a host lives and/or the listing is to
      facilitate hosting services, security, billing, and
      other services that may be provided.
    </p>
    <p>4.14 Business Transfers.</p>
    <p>
      If Fingaz undertakes or is involved in any merger,
      acquisition, reorganization, sale of assets,
      bankruptcy, or insolvency event, then we may sell,
      transfer or share some or all of our assets, including
      your information in connection with such transaction
      or in contemplation of such transaction (e.g., due
      diligence). In this event, we will notify you before
      your personal information is transferred and becomes
      subject to a different privacy policy.
    </p>
    <p>4.15 Aggregated Data.</p>
    <p>
      We may also share aggregated information (information
      about our users that we combine together so that it no
      longer identifies or references an individual user)
      and other anonymized information for regulatory
      compliance, industry and market analysis, research,
      demographic profiling, marketing and advertising, and
      other business purposes.
    </p>
    <h4 [attr.id]='"_5"'>
      <a id="_5"></a>5. OTHER IMPORTANT INFORMATION
    </h4>
    <p>5.1 Analyzing your Communications.</p>
    <p>
      We may review, scan, or analyze your communications on
      the Fingaz Platform for fraud prevention, risk
      assessment, regulatory compliance, investigation,
      product development, research, analytics, and customer
      support purposes. For example, as part of our fraud
      prevention efforts, we scan and analyze messages to
      mask contact information and references to other
      websites. In some cases, we may also scan, review, or
      analyze messages to debug, improve, and expand product
      offerings. We use automated methods where reasonably
      possible. However, occasionally we may need to
      manually review some communications, such as for fraud
      investigations and customer support, or to assess and
      improve the functionality of these automated tools. We
      will not review, scan, or analyze your messaging
      communications to send third party marketing messages
      to you, and we will not sell reviews or analyses of
      these communications.
    </p>
    <p>
      These activities are carried out based on Fingaz's
      legitimate interest in ensuring compliance with
      applicable laws and our Terms, preventing fraud,
      promoting safety, and improving and ensuring the
      adequate performance of our services.
    </p>
    <p>5.2 Linking Third Party Accounts.</p>
    <p>
      You may link your Fingaz Account with your account at
      a third party social networking service. Your contacts
      on these third party services are referred to as
      “Friends.” When you create this link:
    </p>
    <ul>
      <li>
        some of the information you provide to us from the
        linking of your accounts may be published on your
        Fingaz Account profile;
      </li>
      <li>
        your activities on the Fingaz Platform may be
        displayed to your Friends on the Fingaz Platform
        and/or that third party site;
      </li>
      <li>
        a link to your public profile on that third party
        social networking service may be included in your
        Fingaz public profile;
      </li>
      <li>
        other Fingaz users may be able to see any common
        Friends that you may have with them, or that you are
        a Friend of their Friend if applicable;
      </li>
      <li>
        other Fingaz users may be able to see any schools,
        hometowns or other groups you have in common with
        them as listed on your linked social networking
        service;
      </li>
      <li>
        the information you provide to us from the linking
        of your accounts may be stored, processed and
        transmitted for fraud prevention and risk assessment
        purposes; and
      </li>
      <li>
        the publication and display of information that you
        provide to Fingaz through this linkage is subject to
        your settings and authorizations on the Fingaz
        Platform and the third party site.
      </li>
    </ul>
    <p>
      We only collect your information from linked third
      party accounts to the extent necessary to ensure the
      adequate performance of our contract with you, or to
      ensure that we comply with applicable laws, or with
      your consent.
    </p>
    <p>5.3 Third Party Partners & Integrations</p>
    <p>
      The Fingaz Platform may contain links to third party
      websites or services, such as third party
      integrations, co-branded services, or third
      party-branded services (“Third Party Partners”).
      Fingaz doesn’t own or control these Third Party
      Partners and when you interact with them, you may be
      providing information directly to the Third Party
      Partner, Fingaz, or both. These Third Party Partners
      will have their own rules about the collection, use,
      and disclosure of information. We encourage you to
      review the privacy policies of the other websites you
      visit.
    </p>
    <p>
      Parts of the Fingaz Platform may use third party
      services such as Google Maps/Earth services, including
      the Google Maps API(s), and Citibank for Fingaz
      Payments. Use of these respective services is subject
      to their privacy policies such as
      <a
        href="https://www.google.com/intl/en_us/help/terms_maps.html"
        >Google Maps/Earth Additional Terms of Use</a
      >, and the
      <a href="https://www.google.com/privacy.html"
        >Google Privacy Policy</a
      >,
      <a
        href="https://www.citibank.com/tts/sa/tts-privacy-statements/index.html"
        >Citi Privacy Policy</a
      >.
    </p>
    <h4><a id="sec201910_6"></a>6. YOUR RIGHTS</h4>
    <p>
      Consistent with applicable law, you may exercise any
      of the rights described in this section before your
      applicable Fingaz Data Controller and Payments Data
      Controller. See <a>here </a>for information on data
      subject rights requests and how to submit a request. .
      Please note that we may ask you to verify your
      identity and request before taking further action on
      your request.
    </p>
    <p>6.1 Managing Your Information.</p>
    <p>
      You may access and update some of your information
      through your Account settings. If you have chosen to
      connect your Fingaz Account to a third-party
      application, like Facebook or Google, you can change
      your settings and remove permission for the app by
      changing your Account settings. You are responsible
      for keeping your personal information up-to-date.
    </p>
    <p>
      6.2 Rectification of Inaccurate or Incomplete
      Information.
    </p>
    <p>
      You have the right to ask us to correct inaccurate or
      incomplete personal information about you (and which
      you cannot update yourself within your Fingaz
      Account).
    </p>
    <p>6.3 Data Access and Portability.</p>
    <p>
      In some jurisdictions, applicable law may entitle you
      to request certain copies of your personal information
      held by us. You may also be entitled to request copies
      of personal information that you have provided to us
      in a structured, commonly used, and machine-readable
      format and/or request us to transmit this information
      to another service provider (where technically
      feasible).
    </p>
    <p>6.4 Data Retention and Erasure.</p>
    <p>
      We generally retain your personal information for as
      long as is necessary for the performance of the
      contract between you and us and to comply with our
      legal obligations. In certain jurisdictions, you can
      request to have all your personal information deleted
      entirely." Please note that if you request the erasure
      of your personal information:
    </p>
    <ul>
      <li>
        We may retain some of your personal information as
        necessary for our legitimate business interests,
        such as fraud detection and prevention and enhancing
        safety. For example, if we suspend an Fingaz Account
        for fraud or safety reasons, we may retain certain
        information from that Fingaz Account to prevent that
        Member from opening a new Fingaz Account in the
        future.
      </li>
      <li>
        We may retain and use your personal information to
        the extent necessary to comply with our legal
        obligations. For example, Fingaz and Fingaz Payments
        may keep some of your information for tax, legal
        reporting and auditing obligations.
      </li>
      <li>
        Information you have shared with others (e.g.,
        Reviews, forum postings) may continue to be publicly
        visible on the Fingaz Platform, even after your
        Fingaz Account is cancelled. However, attribution of
        such information to you will be removed.
        Additionally, some copies of your information (e.g.,
        log records) may remain in our database, but are
        disassociated from personal identifiers.
      </li>
      <li>
        Because we maintain the Fingaz Platform to protect
        from accidental or malicious loss and destruction,
        residual copies of your personal information may not
        be removed from our backup systems for a limited
        period of time.
      </li>
    </ul>
    <p>
      6.5 Withdrawing Consent and Restriction of Processing.
    </p>
    <p>
      If we are processing your personal information based
      on your consent you may withdraw your consent at any
      time by changing your Account settings or by sending a
      communication to Fingaz specifying which consent you
      are withdrawing. Please note that the withdrawal of
      your consent does not affect the lawfulness of any
      processing activities based on such consent before its
      withdrawal. Additionally, in some jurisdictions,
      applicable law may give you the right to limit the
      ways in which we use your personal information, in
      particular where (i) you contest the accuracy of your
      personal information; (ii) the processing is unlawful
      and you oppose the erasure of your personal
      information; (iii) we no longer need your personal
      information for the purposes of the processing, but
      you require the information for the establishment,
      exercise or defence of legal claims; or (iv) you have
      objected to the processing pursuant to Section 6.6 and
      pending the verification whether the legitimate
      grounds of Fingaz override your own.
    </p>
    <p>6.6 Objection to Processing.</p>
    <p>
      In some jurisdictions, applicable law may entitle you
      to require Fingaz and Fingaz Payments not to process
      your personal information for certain specific
      purposes (including profiling) where such processing
      is based on legitimate interest. If you object to such
      processing Fingaz and/or Fingaz Payments will no
      longer process your personal information for these
      purposes unless we can demonstrate compelling
      legitimate grounds for such processing or such
      processing is required for the establishment, exercise
      or defence of legal claims.
    </p>
    <p>
      Where your personal information is processed for
      direct marketing purposes, you may, at any time ask
      Fingaz to cease processing your data for these direct
      marketing purposes by sending an e-mail to
      opt-out@Fingaz.com.
    </p>
    <p>6.7 Lodging Complaints.</p>
    <p>
      You have the right to lodge complaints about our data
      processing activities by filing a complaint with our
      Data Protection Officer who can be reached by the
      “Contact Us” section below or with a supervisory
      authority.
    </p>
    <h4>
      <a id="sec201910_7"></a>7. OPERATING GLOBALLY &
      INTERNATIONAL TRANSFERS
    </h4>
    <p>
      To facilitate our global operations Fingaz and Fingaz
      Payments may transfer, store, and process your
      information within our family of companies, partners,
      and service providers based in Europe, India, Asia
      Pacific and North and South America. Laws in these
      countries may differ from the laws applicable to your
      country of residence. For example, information
      collected within the EEA may be transferred, stored,
      and processed outside of the EEA for the purposes
      described in this Privacy Policy. Where we transfer
      store, and process your personal information outside
      of the EEA we have ensured that appropriate safeguards
      are in place to ensure an adequate level of data
      protection.
    </p>
    <p>7.1 EU-US & Swiss-US Privacy Shield.</p>
    <p>
      Fingaz and Fingaz Payments comply with the EU-US
      Privacy Shield Framework and the Swiss-US Privacy
      Shield Framework as set forth by the U.S. Department
      of Commerce regarding the collection, use, and
      retention of personal information transferred from the
      European Union, United Kingdom and Switzerland to the
      United States, respectively. Fingaz has certified to
      the Department of Commerce that it adheres to the
      Privacy Shield Principles. If there is any conflict
      between the terms in this Privacy Policy and the
      Privacy Shield Principles, the Privacy Shield
      Principles shall govern. To learn more about the
      Privacy Shield program, and to view our certification,
      please visit
      <a href="https://www.privacyshield.gov/"
        >https://www.privacyshield.gov/</a
      >.
    </p>
    <p>
      Under the Privacy Shield Framework, Fingaz and Fingaz
      Payments are subject to the authority of the Federal
      Trade Commission. If you have any questions or
      concerns relating to our Privacy Shield certification,
      contact us at Fingaz, Inc., Legal Department, 888
      Brannan Street, San Francisco, CA 94103 or via email.
      If we are not able to resolve your concern, you may
      also contact your European Data Protection Authority
      or Commission or seek assistance from our designated
      Privacy Shield independent recourse mechanism,
      <a href="https://www.jamsadr.com/">JAMS</a>. In
      certain circumstances, you may also have the right to
      pursue binding arbitration through the Privacy Shield
      Framework, as described in
      <a
        href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction"
        >Annex I to the Privacy Shield Principles</a
      >.
    </p>
    <p>
      If we have received your personal information under
      the Privacy Shield and subsequently transfer it to a
      third party service provider for processing as
      described in this Privacy Policy, we will remain
      responsible if these providers process your personal
      information in a manner inconsistent with the Privacy
      Shield Principles, except where we can establish that
      Fingaz or Fingaz Payments was not responsible for the
      violation.
    </p>
    <p>
      7.2 Other Means to Ensure an Adequate Level of Data
      Protection.
    </p>
    <p>
      If Fingaz Ireland is the Data Controller and your
      information is shared with corporate affiliates or
      third party service providers outside the EEA, we have
      (prior to sharing your information with such corporate
      affiliate or third party service provider) established
      the necessary means to ensure an adequate level of
      data protection. This may be an adequacy decision of
      the European Commission confirming an adequate level
      of data protection in the respective non-EEA country
      or an agreement on the basis of the EU Model Clauses
      (a set of clauses issued by the European Commission).
      We will provide further information on the means to
      ensure an adequate level of data protection on
      request.
    </p>
    <p>7.3 California & Vermont Residents.</p>
    <p>
      Fingaz Payments will not share information it collects
      about you with its affiliates or third parties (both
      financial and non-financial), except as required or
      permitted by your state’s law.
    </p>
    <p>7.4 California Privacy Rights.</p>
    <p>
      California law permits Members who are California
      residents to request and obtain from us once a year,
      free of charge, a list of the third parties to whom we
      have disclosed their personal information (if any) for
      their direct marketing purposes in the prior calendar
      year, as well as the type of personal information
      disclosed to those third parties. See the “Contact Us”
      section for where to send such requests. Fingaz and
      Fingaz Payments do not share personal information with
      third parties for their own direct marketing purposes
      without your prior consent. Accordingly, you can
      prevent disclosure of your personal information to
      third parties for their direct marketing purposes by
      withholding consent.
    </p>
    <p>
      Effective January 1, 2020, the California Consumer
      Privacy Act (CCPA) allows California residents, upon a
      verifiable consumer request, to request that a
      business that collects consumers’ personal information
      to give consumers access, in a portable and (if
      technically feasible) readily usable form, to the
      specific pieces and categories of personal information
      that the business has collected about the consumer,
      the categories of sources for that information, the
      business or commercial purposes for collecting the
      information, and the categories of third parties with
      which the information was shared. California residents
      also have the right to submit a request for deletion
      of information under certain circumstances. Consistent
      with California law, if you choose to exercise your
      rights, we won’t charge you different prices or
      provide different quality of services unless those
      differences are related to your information. See
      <a>here</a> for information on data subject rights
      requests and how to submit a request. Please note that
      you must verify your identity and request before
      further action is taken. As a part of this process,
      government identification may be required. Consistent
      with California law, you may designate an authorized
      agent to make a request on your behalf. In order to
      designate an authorized agent to make a request on
      your behalf, you must provide a valid power of
      attorney, the requester’s valid government government
      issued identification, and the authorized agent’s
      valid government issued identification.
    </p>
    <p>
      We do not sell personal information to third parties.
      We do allow third parties to collect personal
      information through our Service and share personal
      information with third parties for the business
      purposes described in this Privacy Policy, including
      without limitation advertising and marketing on our
      Service and elsewhere based on users’ online
      activities over time and across different sites,
      services, and devices.
    </p>
    <h4><a id="sec201910_8"></a>8. SECURITY</h4>
    <p>
      We are continuously implementing and updating
      administrative, technical, and physical security
      measures to help protect your information against
      unauthorized access, loss, destruction, or alteration.
      Some of the safeguards we use to protect your
      information are firewalls and data encryption, and
      information access controls. If you know or have
      reason to believe that your Fingaz Account credentials
      have been lost, stolen, misappropriated, or otherwise
      compromised or in case of any actual or suspected
      unauthorized use of your Fingaz Account, please
      contact us following the instructions in the Contact
      Us section below.
    </p>
    <h4>
      <a id="sec201910_9"></a>9. CHANGES TO THIS PRIVACY
      POLICY
    </h4>
    <p>
      Fingaz reserves the right to modify this Privacy
      Policy at any time in accordance with this provision.
      If we make changes to this Privacy Policy, we will
      post the revised Privacy Policy on the Fingaz Platform
      and update the “Last Updated” date at the top of this
      Privacy Policy. We will also provide you with notice
      of the modification by email at least thirty (30) days
      before the date they become effective. If you disagree
      with the revised Privacy Policy, you may cancel your
      Account. If you do not cancel your Account before the
      date the revised Privacy Policy becomes effective,
      your continued access to or use of the Fingaz Platform
      will be subject to the revised Privacy Policy.
    </p>
    <h4><a id="sec201910_10"></a>10. CONTACT US</h4>
    <p>
      If you have any questions or complaints about this
      Privacy Policy or Fingaz’s information handling
      practices, you may email us at the email addresses
      provided in the relevant sections above or contact us
      via mail at:
    </p>
    <ul>
      <li>
        For persons whose country of residence is the United
        States: Fingaz, Inc., Legal Department, 888 Brannan
        Street, San Francisco, CA 94103 USA. For
        payments-related matters prior to April 30, 2018:
        Fingaz Payments, Inc., 999 Brannan Street, 4th
        Floor, San Francisco, CA 94103, USA. For
        payments-related matters after April 30, 2018 at 8AM
        UTC: Fingaz Payments, Inc. 650 7th Street, San
        Francisco, CA 94103, USA.
      </li>
      <li>
        For persons whose country of residence is Japan:
        Fingaz Global Services Limited, Legal Department, 8
        Hanover Quay, Dublin 2. Ireland.
      </li>
      <li>
        For persons whose country of residence is India for
        payments-related matters: Fingaz Payments India Pvt.
        or Fingaz Payments UK Ltd, depending on which entity
        is the applicable Payments Data Controller. Fingaz
        Payments India Pvt. Ltd., Level 9, Spaze i-Tech
        Park, A1 Tower, Sector-49, Sohna Road, Gurugram
        India 122018. Fingaz Payments UK Ltd: Data
        Protection Officer, Suite 1, 3rd Floor, 11-12 St.
        James’s Square, London SW1Y 4LB United Kingdom.
      </li>
      <li>
        For persons whose country of residence is Australia
        for payments-related matters: Fingaz Payments UK Ltd
        or Fingaz Payments Australia Pty. Ltd., depending on
        which entity is the applicable Payments Data
        Controller. Fingaz Payments UK Ltd: Data Protection
        Officer, Suite 1, 3rd Floor, 11-12 St. James’s
        Square, London SW1Y 4LB United Kingdom. Fingaz
        Payments Australia Pty. Ltd: 58 Gipps Street,
        Collingwood VIC 3066, Australia.
      </li>
      <li>
        For persons whose country of residence is outside of
        the United States, China and Japan: Fingaz Ireland
        UC, Data Protection Officer, 8 Hanover Quay, Dublin
        2, Ireland and for payments-related matters (except
        for persons whose country of residence is India):
        Fingaz Payments UK Ltd., Data Protection Officer,
        Suite 1, 3rd Floor, 11-12 St. James’s Square, London
        SW1Y 4LB United Kingdom.
      </li>
      <li>
        For persons whose country of residence is in the
        European Union for payments-related matters and if
        the data controller is Fingaz Payments Luxembourg
        S.A.: Fingaz Payments Luxembourg S.A., 4, rue Henri
        Schnadt, L-2530 Luxembourg.
      </li>
    </ul>
    <br /><br /><br />
  </div>
</div>
