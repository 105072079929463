import { Component } from "@angular/core";

@Component({
  selector: "loader",
  templateUrl: "./loader.html",
  styles: [""],
})
export class Loader {
  constructor() {}
}
