import { Component, OnInit } from "@angular/core";

@Component({
  selector: "cookies",
  templateUrl: "./cookies.html",
  styles: [""],
})
export class Cookies implements OnInit {
  constructor() {}

  ngOnInit() {}
}
