import { TitleCasePipe } from "@angular/common";
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { Utilities } from "@services/utilities";

@Component({
  selector: "h-slots",
  templateUrl: "./h-slots.html",
  styleUrls: ["../hosts.scss"],
  providers: [TitleCasePipe],
})
export class HostSlots implements OnInit {
  @ViewChild("scheduler") public sc_temp: TemplateRef<any>;
  @ViewChild("prices") public pr_temp: TemplateRef<any>;
  @ViewChild("add_stuff") public add_temp: TemplateRef<any>;
  @ViewChild("co_games")
  public games_temp: TemplateRef<any>;
  @ViewChild("co_gadgets")
  public gadgets_temp: TemplateRef<any>;

  curr_loc;
  slots;
  curr_events;
  curr_console;
  curr_keys = [];

  loc;
  filtered_games;
  filtered_gadgets;
  add_list;
  added_list = {};
  add_db;
  curr_consoler = [];
  searcher = "";

  addref;

  con_ops = [
    {
      icon: "fas fa-calendar",
      name: "Open Schedules",
      open: (keys, c) => {
        this.curr_events = [
          ...Object.values(c.schedules ?? {}),
        ];
        this.curr_keys = keys;
        this.curr_console = c;
        this.util.dlogtr(this.sc_temp);
      },
    },
    {
      icon: "fas fa-coins",
      name: "Set Prices",
      open: (keys, c) => {
        if (c.games) {
          this.curr_keys = keys;
          this.curr_console = c;
          this.util.dlogtr(this.pr_temp);
        } else {
          this.util.wuToast(
            "Add Games",
            "Add Games to Set Prices"
          );
        }
      },
    },
    {
      icon: "fas fa-gamepad",
      name: "Open/Edit Games",
      open: (keys, c) => {
        this.curr_keys = keys;

        this.curr_console = c;
        this.util.dlogtr(this.games_temp);
      },
    },
    {
      icon: "fas fa-tv",
      name: "Open/Edit Gadgets",
      open: (keys, c) => {
        this.curr_keys = keys;
        this.curr_console = c;
        this.util.dlogtr(this.gadgets_temp);
      },
    },
  ];

  constructor(
    public util: Utilities,
    public dref: MatDialog
  ) {}

  ngOnInit(): void {
    this.curr_loc = this.util.router.url.split("/").pop();
    this.loc =
      this.util.storey.host_db.locations[this.curr_loc];
  }

  // * FILTER GAMES
  async filterDB(id, db) {
    if (db === "consoles") {
      this[`filtered_${db}`] = Object.values(
        this.util.storey.all_db.consoles
      ).filter(
        (x: any) => !["android", "ios"].includes(x.id)
      );
    } else {
      this[`filtered_${db}`] = [
        ...Object.values(this.util.storey.all_db[db]),
      ].filter((x) => {
        let pass = true;
        if (db === "games") {
          pass = !(
            this.curr_console.games?.[x["id"]] === 1
          );
        }
        return id in x["consoles"] && pass;
      });
    }
  }

  // * OPEN ADD DIALOG
  addStuff(temp, db) {
    this.filterDB(this.curr_keys[1] ?? "", db);
    this.add_db = db;
    this.searcher = "";
    this.added_list = {};
    this.add_list = this[`filtered_${db}`];

    this.addref = this.util.dlogtr(temp);
  }
  // * UPDATE ITEM
  selecter(a, t) {
    if (t === 4) {
      if (this.added_list[a.id] === 1) {
        delete this.added_list[a.id];
      } else {
        this.added_list[a.id] = 1;
      }
    } else if (t === 1) {
      this.added_list[a.id] =
        (this.added_list[a.id] || 0) + 1;
    } else {
      if (+this.added_list[a.id] > 0) {
        this.added_list[a.id]--;
      } else {
        delete this.added_list[a.id];
      }
    }
  }

  // * SYNC ADDED UPDATES
  async addUpdate() {
    // * CONSOLES UPDATE
    if (this.add_db === "consoles") {
      let new_pop = {
        ...(this.loc[this.add_db] || {}),
      };
      for (let a in this.added_list) {
        if (!new_pop[a]) {
          new_pop[a] = [];
        }
        let new_a = Array.from(
          Array(this.added_list[a]).keys()
        ).map((x) => {
          let ad = {
            cid: x + new_pop[a].length + 1,
          };
          return ad;
        });
        new_pop[a] = [...new_pop[a], ...new_a];
      }

      let pathy = `locations/${this.curr_loc}/consoles`;
      this.updateHostDB(pathy, new_pop);
    }
    // * GAMES UPDATES
    else if (this.add_db === "games") {
      let this_games = {
        ...this.curr_console.games,
        ...this.added_list,
      };

      let pathy = `locations/${this.curr_loc}/consoles/${
        this.curr_keys[1]
      }/${this.curr_console.cid - 1}/games`;

      await this.updateHostDB(pathy, this_games);
    }
    // * GADGETS UPDATE
    else if (this.add_db === "gadgets") {
      let this_gadgets = {
        ...this.curr_console.gadgets,
        ...this.added_list,
      };

      let pathy = `locations/${this.curr_loc}/consoles/${
        this.curr_keys[1]
      }/${this.curr_console.cid - 1}/gadgets`;

      await this.updateHostDB(pathy, this_gadgets);
    }
  }

  async updateHostDB(pathy, upd) {
    await this.util.afd
      .object(
        `dbz/hosts/${this.util.storey.host_db.id}/${pathy}`
      )
      .update(upd);
    this.addref.close();
  }

  // * ADD PHOTOS
}
