import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "ad-slots",
  templateUrl: "./ad-slots.html",
  styleUrls: ["../admin.scss"],
})
export class AdSlots implements OnInit {
  searcher;
  eds;
  indx;
  slots;
  swx = [0, 0, 0];

  constructor(public util: Utilities) {}

  ngOnInit(): void {}

  zi() {}

  chi(i) {
    return i.slice(0, 5) === "data:";
  }

  gSlots() {}

  async synk(s) {
    // if (this.swx[0] !== 0 && this.swx[1] !== 0) {
    //   this.util.afd
    //     .object(`dbz/dummy/slots/${this.swx[0][0]}`)
    //     .set(this.swx[1][1]);
    //   this.util.afd
    //     .object(`dbz/dummy/slots/${this.swx[1][0]}`)
    //     .set(this.swx[0][1]);
    //   await new Promise((r) => setTimeout(r, 1000));
    //   this.gSlots();
    // }
    // await new Promise((r) => setTimeout(r, 2000));
    // this.swx = [0, 0, 0];
  }

  edy(s, i) {
    console.log(s, i);

    this.eds = this.util.lo_cloneDeep(s);
    this.indx = i;
  }

  sel(s, i) {
    this.swx[i] = this.util.lo_cloneDeep(s);
  }

  async upD() {
    // this.util.afd
    //   .object(`dbz/dummy/slots/${this.indx}`)
    //   .set(this.eds);
    // this.util.dlogx();
    // await new Promise((r) => setTimeout(r, 1000));
    // this.gSlots();
  }

  newSlot(f) {
    let to_id = `${f.value.game}-${
      this.util.storey.user.uid
    }-${new Date().getTime()}`;

    let new_f = { ...f.value };
    new_f.id = to_id;
    new_f.deadline = new Date(new_f.deadline).getTime();
    new_f.player_list = [];

    // this.util.afd
    //   .object(`dbz/parties/${to_id}`)
    //   .update(new_f);
    // this.util.dlogx();
  }
}
