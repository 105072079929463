export const hour_map = {
  "0th": "12 AM",
  "1st": "1 AM",
  "2nd": "2 AM",
  "3rd": "3 AM",
  "4th": "4 AM",
  "5th": "5 AM",
  "6th": "6 AM",
  "7th": "7 AM",
  "8th": "8 AM",
  "9th": "9 AM",
  "10th": "10 AM",
  "11th": "11 AM",
  "12th": "12 PM",
  "13th": "1 PM",
  "14th": "2 PM",
  "15th": "3 PM",
  "16th": "4 PM",
  "17th": "5 PM",
  "18th": "6 PM",
  "19th": "7 PM",
  "20th": "8 PM",
  "21st": "9 PM",
  "22nd": "10 PM",
  "23rd": "11 PM",
};
