<div
  class="pt-3"
  *ngLet="util.storey.all_db.dummy.events as evx"
>
  <p class="justify-content-center d-flex">
    <input
      class="searcher shadow brad mx-2 csw p-3"
      type="text"
      [(ngModel)]="searcher"
      placeholder="Search"
    />
    &nbsp;
    <button
      (click)="util.dlogtr(new_party)"
      class="bg-bg1 cck fs2 li0 px-3 py-4 brad"
    >
      +
    </button>
    &nbsp;
    <button
      (click)="synkP()"
      class="bg-bg1 cck fs2 li0 px-3 py-4 brad"
    >
      ()
    </button>
  </p>

  <div class="agridy">
    <div
      *ngFor="let ev of evx | searchFilter: searcher"
      class="zitem brads shadow-sm bg-bg1 csw"
    >
      <img
        width="100%"
        class="brads"
        [src]="ev.img"
        alt=""
      />
      <p class="text-truncate fs07">{{ev.name}}</p>
      <p class="text-truncate fs07">{{ev.date}}</p>
      <p class="text-truncate fs07">{{ev.location}}</p>
      <p class="text-truncate fs07">{{ev.price}}</p>
    </div>
  </div>
</div>

<ng-template #new_party>
  <div
    class="position-relative p-4 brad bg-bg1 bo3-bg4 wim text-center"
  >
    <span matDialogClose class="h3 ccr poi exiter"
      ><i class="fas fa-times-circle"></i
    ></span>
    <div>
      <form class="" #new_form="ngForm">
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            name="players"
            type="number"
            placeholder="Player Limit"
            ngModel
          />
        </p>
        <p>
          <input
            class="bg-bg1 p-3 csw shadow my-2 brad"
            name="entry_fingz"
            type="number"
            placeholder="Entry Fee"
            ngModel
          />
        </p>
        <p>
          <mat-form-field
            appearance="fill"
            class="bg-bg1 csw"
          >
            <mat-label>Game</mat-label>
            <mat-select name="game" ngModel>
              <mat-option
                *ngFor="let ga of util.map2Arr(util.storey.all_db.games, 2)"
                [value]="ga.id"
                >{{ga.name}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field
            appearance="fill"
            class="bg-bg1 csw"
          >
            <mat-label>Consoles</mat-label>
            <mat-select
              [disabled]="!new_form.value.game"
              name="consoles"
              ngModel
              multiple
            >
              <mat-option
                *ngFor="let c of util.storey.all_db.games[new_form.value.game]?.consoles"
                [value]="c"
                >{{util.storey.all_db.consoles[c].name}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </p>
        <mat-form-field appearance="fill">
          <mat-label>Deadline</mat-label>
          <input
            name="deadline"
            ngModel
            matInput
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </form>
      <p>
        <button
          (click)="newParty(new_form)"
          class="px-3 py-2 bg-csw bg1"
        >
          ADD
        </button>
      </p>
    </div>
  </div>
</ng-template>
