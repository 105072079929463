<div class="p-2 text-center">
  <p>
    <input
      class="searcher shadow brad m-4 csw p-3"
      type="text"
      [(ngModel)]="searcher"
      placeholder="Search"
    />
    <button
      class="brad bg-ccp bg1 p-3 shadow-sm"
      (dblclick)="repSk()"
    >
      REPX
    </button>
  </p>

  <ng-container
    *ngFor="let h of util.map2Arr(util.storey.all_db.hosts, 2) | searchFilter: searcher"
  >
    <div class="itemy brad shadow-sm bg-bg1 csw px-2">
      <img
        width="80%"
        class="mt-2"
        [src]="h.image || '/assets/house.png'"
        alt=""
        (dblclick)="util.show(h.locations[0].consoles)"
      />
      <p class="text-truncate fs07 mt-2 mb-1">{{h.id}}</p>
      <p class="text-truncate fs07 mt-2 mb-1">
        {{h.email}}
      </p>
    </div>
  </ng-container>
</div>
