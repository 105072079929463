import { Component, OnInit } from "@angular/core";
import { slot_filter_menu } from "@models/menu";
import { Utilities } from "@services/utilities";
import { firstValueFrom } from "rxjs";
import { dummy_slots } from "./slot_dummy";
import SwiperCore, {
  Keyboard,
  Pagination,
  Navigation,
  Thumbs,
  EffectFade,
} from "swiper";

SwiperCore.use([
  Keyboard,
  Pagination,
  Navigation,
  Thumbs,
  EffectFade,
]);

@Component({
  selector: "slots",
  templateUrl: "./slots.html",
  styleUrls: ["./slots.scss"],
})
export class Slots implements OnInit {
  search_params = {
    name: "slot",
    title: "Game",
    color: "c0",
    border: "bot-c0 opa",
    border_l: "bol-c0",
    profile_icon: "fas fa-calendar-check",
    profile_bg: "bg-c0",
    profile_link: "/profile/booking",
    menu: [...slot_filter_menu],
  };
  curr_slot;
  search;
  filters;
  a_slots = Array(15).fill(dummy_slots).flat();

  constructor(public util: Utilities) {}

  ngOnInit() {}

  selectSlot(s, temp) {
    this.util.analog("play_game");
    this.curr_slot = { ...s };
    this.util.dlogtr(temp);
  }

  oSlot(s) {
    let r = this.util.hid(s);
    this.util.router.navigate([`slots/${r}`]);
  }
}
