import { Component } from "@angular/core";

@Component({
  selector: "not-found",
  templateUrl: "./not-found.html",
  styleUrls: ["./not-found.scss"]
})
export class NotFound {
  constructor() {}
}
